import React, { useEffect, useState } from "react";
import styles from "./../crms.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { editPrivacyNotice } from "../../../../api/adminPanel/privacyNoticeApi";

export default function ViewPrivacyNotice() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [description, setDescription] = useState("");
  const handleGetPrivacyNoticeDetail = async (id) => {
    const response = await editPrivacyNotice(id);
   
    if (response.remote === "success") {
      setDescription(response.data.data.text);

    } else {
    }
  };
  useEffect(() => {
    handleGetPrivacyNoticeDetail(id);
  }, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>View Privacy Notice</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                  {/* <p>this is good new for done</p> */}
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
