import axiosInstance from "./../api";
const token = localStorage.getItem("token");

export const addPrivacyNotice = async (privacyNoticeData) => {

  const data = new FormData();

  data.append("text", privacyNoticeData.discription);
  data.append("meta_title", privacyNoticeData.metaTitle);
  data.append("meta_description", privacyNoticeData.metaDescription);

  const response = await axiosInstance.request({
    url: "addprivacy",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showPrivacyNoticeData = async () => {
  const response = await axiosInstance.request({
    url: "showprivacy",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const editPrivacyNotice = async (id) => {
 
  const response = await axiosInstance.request({
    url: `privacydataid/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updatePrivacyNotice = async (privacyNoticeData, id) => {
  const data = new FormData();
  data.append("text", privacyNoticeData.discription);
  data.append("id", id);
  data.append("meta_title", privacyNoticeData.metaTitle);
  data.append("meta_description", privacyNoticeData.metaDescription);

  const response = await axiosInstance.request({
    url: "updateprivacy",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
