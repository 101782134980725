import React, { useEffect, useState } from "react";
import styles from "./../retreats.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon } from "../../../../utils/svg.file";

import { Featured_URL } from "../../../../serverUrl";
import DeleteModal from "../../../component/modal/deleteModal";
import swal from "sweetalert";
import { deleteretreatecategory, showRestaurantName, showRetreatList } from "../../../../api/adminPanel/giftCardApi";

export default function ManageRetreatsCategory() {
  const navigate = useNavigate();
  const [restaurantName, setRestaurantName] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleRetreatList = async () => {
    const response = await showRetreatList();
    if (response.remote === "success") {
      setRestaurantName(response.data.data, "name data");
    } else {
    }
  };

  const handleDelete = async (id) => {
    const response = await deleteretreatecategory(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
      handleRetreatList()
    } else {
    }
  };
  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    { field: "location", headerName: "Location", width: 200 },

    { field: "restaurantName", headerName: "Retreat Name", width: 500 },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-retreats-category/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleRetreatList();
  }, []);

  // let rows = [
  //   { id: 1, location: "dsd", restaurantName: "fdsdfggs" },
  //   { id: 2, location: "dsds", restaurantName: "fdsdfggs" },
  // ];
  let rows = [];

  restaurantName?.map((item, index) => {
    rows.push({
      id: item.id,
      location: Number(item.other) === 1 ? "Malasiya" : "Singapore",
      restaurantName: item.title,
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Retreats</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-retreats-category")}
              >
                Add Retreat Category
              </Button>
            </div>
            {/* <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select className="form-control ms-1 me-1">
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>

                  <option value="50"> 50</option>

                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
              />
            </div> */}
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
