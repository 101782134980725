import React, { useEffect, useState } from "react";
import styles from "./../celebrationEvent.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import {
  addCelebrationBannerImage,
  deleteImage,
  showRetreatListArray,
} from "../../../../api/adminPanel/giftCardApi";
import { showRetreatList } from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import { UploadIcon, crossRed } from "../../../../utils/svg.file";
import {
  AddEventBlock,
  showFindCelebrationList,
  UpdateEventBlockData,
} from "../../../../api/adminPanel/celebrationAPI";
import { BlockTwoTone } from "@mui/icons-material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { showRestaurantList } from "../../../../api/adminPanel/exclusiveOfferApi";
export default function EditEventAndBlock() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [firstTitle, setFirstTitle] = useState("");

  const [secondTitle, setSecondTitle] = useState("");

  const [cardImage, setCardImage] = useState("");
  const [blockOneImg, setBlockOneImg] = useState("");
  const [blockTwoImg, setBlockTwoImg] = useState("");
  const [imageBannerArray, setImageBannerArray] = useState([]);
  const [location, setLocation] = useState("1");
  const [description, setDescription] = useState("");
  const [firstDescription, setFirstDescription] = useState("");
  const [secondDescription, setSecondDescription] = useState("");

  const [offersValue, setOffersValue] = useState("");
  const [offerType, setOfferType] = useState("restaurants");
  const [retraurantList, setRetraurantList] = useState([]);
  const [finalList, setFinalList] = useState([]);
  const [retreatList, setRetreatList] = useState([]);
  const locationList = [
    { id: 1, location: "Malaysia" },
    // { id: 2, location: "Singapore" },
  ];
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const handleRetraurantList = async () => {
    const response = await showRestaurantList();

    if (response.remote === "success") {
      setRetraurantList(response.data.data);
      setFinalList(response.data.data);
    } else {
    }
  };
  const handleRetreatList = async () => {
    const response = await showRetreatListArray();

    if (response.remote === "success") {
      setRetreatList(response.data.data);
    } else {
    }
  };

  const onChange = async ({ fileList }) => {
    // setFileList(fileList);
    setCardImage(fileList[0].originFileObj);
  };
  const thirdOnChange = async ({ fileList }) => {
    // setFileList(fileList);
    setBlockTwoImg(fileList[0].originFileObj);
  };
  const twoOnChange = async ({ fileList }) => {
    // setFileList(fileList);
    setBlockOneImg(fileList[0].originFileObj);
  };

  const BannerOnChange = async ({ fileList }) => {
    setFileList(fileList);
    // setImageBanner(fileList[0].originFileObj);
    const addCelebrationImagePayload = {
      image: fileList[0].originFileObj,
    };
    const response = await addCelebrationBannerImage(
      addCelebrationImagePayload
    );

    if (response.remote === "success") {
      swal("Image Successfully Added");
      setImageBannerArray([...imageBannerArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };

  const handleUpdateEventAndBlock = async () => {
    const addEventBlock = {
      id: id,
      cardimage: cardImage,
      first_image: blockOneImg,
      second_image: blockTwoImg,
      location: location,
      title: title,
      image: imageBannerArray,
      description: description,
      first_title: firstTitle,
      first_description: firstDescription,
      second_title: secondTitle,
      second_description: secondDescription,
      locationId: offersValue,
      offerType: offerType,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    };
    const response = await UpdateEventBlockData(addEventBlock);

    if (response.remote === "success") {
      swal("Event And Block Successfully Added");
      navigate("/manage-celebration-event-and-block");
    } else {
    }
  };
  const handleFindEventAndBlock = async (id) => {
    const response = await showFindCelebrationList(id);

    if (response.remote === "success") {
      setLocation(response.data.data[0].location);
      setTitle(response.data.data[0].title);
      setFirstTitle(response.data.data[0].first_title);
      setSecondTitle(response.data.data[0].second_title);
      setDescription(response.data.data[0].description);
      setFirstDescription(response.data.data[0].first_description);
      setSecondDescription(response.data.data[0].second_description);
      setCardImage(response.data.data[0].cardimage);
      setImageBannerArray(JSON.parse(response.data.data[0].image));
      setOffersValue(response.data.data[0].locationId);
      setOfferType(response.data.data[0].offerType);
      setBlockOneImg(response.data.data[0].first_image);
      setBlockTwoImg(response.data.data[0].second_image);
      setMetaTitle(response.data.data[0].meta_title);
      setMetaDescription(response.data.data[0].meta_description);
    } else {
    }
  };
  useEffect(() => {
    handleFindEventAndBlock(id);
  }, [id]);

  const handelDeleteImgae = async (image) => {
    const newImageArray = imageBannerArray.filter(
      (imageBannerArray) => imageBannerArray !== image
    );
    setImageBannerArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };
  const handlechangeValue = (value) => {
    setOfferType(value);
    if (value === "retreats") {
      setFinalList(retreatList);
    } else {
      setFinalList(retraurantList);
    }
  };
  useEffect(() => {
    handleRetraurantList();
    handleRetreatList();
  }, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Update Event & Block</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <label className="fw-bold mb-3"> Event</label>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Country</label>
                  <Form.Select
                    className={styles.form_input}
                    onChange={(e) => setLocation(e.target.value)}
                    value={location}
                  >
                    <option selected>Select Country </option>
                    {locationList.map((item, index) => {
                      return (
                        <>
                          <option key={index} value={item.id}>
                            {item.location}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Title</label>

                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />
                </Col>
                <Col md={6}>
                  {" "}
                  <label className="fw-bold">
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 818 X 818 px)
                    </small>
                  </label>
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={818 / 818}>
                      <Upload
                        maxCount={1}
                        onChange={onChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        {cardImage.length >= 1 ? (
                          <>
                            <img
                              src={`${Offers_URL}${cardImage}`}
                              alt=""
                              // className={styles.preview_img}
                            />
                          </>
                        ) : (
                          <>
                            <div className="file_upload_div">
                              <span>{UploadIcon}</span>
                              <p>Click Here to Upload</p>
                            </div>
                          </>
                        )}
                      </Upload>
                    </ImgCrop>
                  </div>
                  <label className="fw-bold mb-2">
                    Banner Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <div className="multi-images d-flex">
                    <ImgCrop grid aspect={1219 / 757}>
                      <Upload
                        onChange={BannerOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        fileList={fileList}
                      >
                        {" "}
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                    <ul className={styles.multi_image_preview}>
                      {imageBannerArray.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => handelDeleteImgae(item)}
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="restaurants"
                      control={<Radio />}
                      label="Restaurant"
                      onChange={(e) => handlechangeValue(e.target.value)}
                    />
                    <FormControlLabel
                      value="retreats"
                      control={<Radio />}
                      label="Retreats"
                      onChange={(e) => handlechangeValue(e.target.value)}
                    />
                  </RadioGroup>
                  <label className="fw-bold">
                    Select Restaurant / Retreats
                  </label>
                  <Form.Select
                    className={styles.form_input}
                    onChange={(e) => setOffersValue(e.target.value)}
                    value={offersValue}
                  >
                    <option selected>Select Restaurant / Retreats</option>

                    {finalList?.map((item, index) => {
                      return (
                        <>
                          <option value={item.value}>
                            {item.label}, {item.other}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              {/* <hr />
              <label className="fw-bold mb-3"> Block 1</label>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <label className="fw-bold">Title</label>

                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setFirstTitle(e.target.value)}
                    value={firstTitle}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">
                    Block Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 818 X 818 px)
                    </small>
                  </label>
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={818 / 818}>
                      <Upload
                        maxCount={1}
                        onChange={twoOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        {blockOneImg.length >= 1 ? (
                          <>
                            <img
                              src={`${Offers_URL}${blockOneImg}`}
                              alt=""
                              // className={styles.preview_img}
                            />
                          </>
                        ) : (
                          <>
                            <div className="file_upload_div">
                              <span>{UploadIcon}</span>
                              <p>Click Here to Upload</p>
                            </div>
                          </>
                        )}
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setFirstDescription(e.target.value)}
                    value={firstDescription}
                  />
                </Col>
              </Row>
              <hr />
              <label className="fw-bold mb-3"> Block 2</label>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <label className="fw-bold">Title</label>

                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setSecondTitle(e.target.value)}
                    value={secondTitle}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">
                    Block Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 818 X 818px)
                    </small>
                  </label>
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={818 / 818}>
                      <Upload
                        maxCount={1}
                        onChange={thirdOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        {blockTwoImg.length >= 1 ? (
                          <>
                            <img
                              src={`${Offers_URL}${blockTwoImg}`}
                              alt=""
                              // className={styles.preview_img}
                            />
                          </>
                        ) : (
                          <>
                            <div className="file_upload_div">
                              <span>{UploadIcon}</span>
                              <p>Click Here to Upload</p>
                            </div>
                          </>
                        )}
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setSecondDescription(e.target.value)}
                    value={secondDescription}
                  />
                </Col>
              </Row> */}
              <label className="fw-bold">Enter Meta</label>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      className={styles.form_input}
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      className={styles.form_input}
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>
              <Row gutter={[24, 0]}>
                <Col md={12} className="text-center mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleUpdateEventAndBlock}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
