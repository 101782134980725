import React from "react";
import styles from "../enquiry.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";

export default function StayEnquiryList() {
  const navigate = useNavigate();
  const columns = [
    { field: "id", headerName: "SNo.", width: 70 },
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", width: 160 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phoneNumber", headerName: "Phone Number", width: 160 },
    { field: "pax", headerName: "Pax", width: 80 },
    { field: "eventType", headerName: "Type Of Event", width: 160 },
    { field: "venue", headerName: "Venue", width: 150 },
    { field: "eventDate", headerName: "Date of Event", width: 150 },
    { field: "description", headerName: "Description", width: 250 },
    {
      field: "Action",
      renderCell: (cellValues) => {
        return (
          <Button
            className={styles.small_button}
            // onClick={(event) => {
            //   handleClick(event, cellValues);
            // }}
          >
            View
          </Button>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      firstName: "Rahul",
      lastName: "Singh ",
      email: "user@gmail.com",
      phoneNumber: "5475897354",
      pax: "5",
      eventType: "eventOne",
      venue: "Gwalior",
      eventDate: "28/12/22",
      description: "this is check",
    },
    {
      id: 2,
      firstName: "Harsh",
      lastName: "Singh ",
      email: "user@gmail.com",
      phoneNumber: "5475897354",
      pax: "5",
      eventType: "eventOne",
      venue: "Gwalior",
      eventDate: "28/12/22",
      description: "this is check",
    },
  ];
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="m-3 p-3">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Stay Enquiry List</h3>
              </div>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
    </>
  );
}
