import React, { useEffect, useState } from "react";
import styles from "./../celebrationEvent.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon } from "../../../../utils/svg.file";

import { Featured_URL, Offers_URL } from "../../../../serverUrl";
import DeleteModal from "../../../component/modal/deleteModal";
import swal from "sweetalert";
import { celebrationlandingList, deleteCelebrationimages } from "../../../../api/adminPanel/giftCardApi";

export default function ManageCelebrationLandingPageBanner() {
  const navigate = useNavigate();
  const [featuredList, setFeaturedList] = useState([]);
  const [rows, setRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  
  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    {
      field: "image",
      height: 150,
      width: 700,
      renderCell: (cellValues) => {
        return (
          <>
            <img
              src={`${Offers_URL}${cellValues.row.image}`}
              className={styles.table_img}
            />
          </>
        );
      },
    },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            {/* <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(
                  `/edit-celebration-landing-page-banner/${cellValues.row.id}`
                );
              }}
            >
              {editIcon}
            </span> */}
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];

  // useEffect(() => {
  //   handleFeaturedList();
  // }, []);

  // let rows = [
  //   { id: 1, image: "dsd" },
  //   { id: 2, image: "dsds" },
  // ];
  // let rows = [];

  // featuredList?.map((item, index) => {
  //   rows.push({
  //     id: item.id,
  //     heading: item.heading,
  //     description: item.discription,
  //     image: item.image,
  //   });
  // });

  const handlelandingCelebration = async () =>{
    const response = await celebrationlandingList();

    if (response.remote === "success") {
      setRows(response.data.data);

    } else {
      swal("Something Went Wrong!");
    }
  }
  useEffect(() => {
    handlelandingCelebration()
  }, [])
  const handleDelete = async (id) => {
    const response = await deleteCelebrationimages(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
      handlelandingCelebration()
    } else {
    }
  };
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Landing Page Banner</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() =>
                  navigate("/add-celebration-landing-page-banner")
                }
              >
                Add Banner
              </Button>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
