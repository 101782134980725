import React, { useEffect, useState } from "react";
import styles from "./../celebrationEvent.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon } from "../../../../utils/svg.file";

import { Featured_URL, Offers_URL } from "../../../../serverUrl";
import DeleteModal from "../../../component/modal/deleteModal";
import swal from "sweetalert";
import {
  deleteCelebrationList,
  deleteCelebrationType,
  showCelebrationList,
  showCelebrationType,
} from "../../../../api/adminPanel/celebrationAPI";
import { showCelebartionList } from "../../../../api/adminPanel/giftCardApi";

export default function ManageBlock() {
  const navigate = useNavigate();
  const [CelebrationList, setCelebrationList] = useState([]);
  const [celebrationNameList, setCelebrationNameList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [showValue, setShowValue] = useState("");
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleCelebrationList = async () => {
    const response = await showCelebrationType();
    if (response.remote === "success") {
      setCelebrationList(response.data.data);
    } else {
    }
  };

  const handleDelete = async (id) => {
    const response = await deleteCelebrationType(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
      handleCelebrationList();
    } else {
    }
  };
  const columns = [
    { field: "sno", headerName: "SNo.", width: 100 },
    { field: "title", headerName: "title.", width: 100 },
    { field: "celebration", headerName: "celebration.", width: 300 },
    { field: "description", headerName: "description.", width: 350 },

    {
      field: "Action",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-celebration-block/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];
  const handleRetreatList = async () => {
    const response = await showCelebartionList();

    if (response.remote === "success") {
      setCelebrationNameList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleCelebrationList();
    handleRetreatList();
  }, []);

  const handleSearch = (data) => {

    if (data) {
      let searchData = CelebrationList.filter(
        (entry) =>
           entry.celebration?.includes(data) || entry.title?.includes(data)
      );
      setSearchedList(searchData);
    }
  };

  

  const filteredList = () => {
    if (searchedData) {
      return searchedList;
    } else {
      return CelebrationList;
    }
  };
  let rows = [];

  filteredList().map((item, index) => {
    rows.push({
      sno: index + 1,
      id: item.id,
      title: item.title,
      description: item.discription,
      celebration: item.celebrationName,
    });
  });

  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Celebration Block</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-celebration-block")}
              >
                Add Block
              </Button>
            </div>
            <div className="table-search d-flex justify-content-between">
              <div
                className="d-flex align-items-center"
                style={{ width: "500px" }}
              >
                <div className="show_entries d-flex align-items-center">
                  Show
                  <select
                    style={{ width: "60px" }}
                    className="form-control ms-1 me-1"
                    onChange={(e) => setShowValue(e.target.value)}
                  >
                    <option value="50"> 50</option>
                    <option value="10"> 10</option>
                    <option value="25"> 25</option>

                    <option value="100"> 100</option>
                  </select>
                  Entries
                </div>
                <select
                  className="form-control ms-1 ms-3"
                  style={{ width: "300px", height: "38px" }}
                  onChange={(e) =>{ handleSearch(e.target.value)
                    setSearchedData(e.target.value);
                  }}
                >
                  <option selected disabled>
                    Select Celebration
                  </option>
                  {celebrationNameList.map((item) => (
                    <option value={item.id}>{item.title}</option>
                  ))}
                </select>
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchedData(e.target.value);
                }}
              />
            </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
