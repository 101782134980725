import axiosInstance from "../api";
const token = localStorage.getItem("token");

export const addAccolades = async (accoladesData) => {
 
  const data = new FormData();
  data.append("location", accoladesData.location);
  data.append("category", accoladesData.category);
  data.append("text", JSON.stringify(accoladesData.contactField));

  const response = await axiosInstance.request({
    url: "addaccolades",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const updateAccolades = async (accoladesData) => {
 
  const data = new FormData();
  data.append("id", accoladesData.id);
  data.append("location", accoladesData.location);
  data.append("category", accoladesData.category);
  data.append("text", JSON.stringify(accoladesData.contactField));

  const response = await axiosInstance.request({
    url: "updateaccolades",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showAccoladesList = async () => {
  const response = await axiosInstance.request({
    url: "showaccolades",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const viewAccolades = async (id) => {
  
  const response = await axiosInstance.request({
    url: `showaccoladesid/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updatePrivacyNotice = async (privacyNoticeData, id) => {
  const data = new FormData();
  data.append("text", privacyNoticeData.discription);
  data.append("id", id);

  const response = await axiosInstance.request({
    url: "updateprivacy",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteAccolades = async (id) => {
  const response = await axiosInstance.request({
    url: `deleteaccolades/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const addAbout = async (aboutData) => {
 
  const data = new FormData();
  data.append("banner", aboutData.bannerPic);
  data.append("principal", aboutData.corePrincipalPic);
  data.append("mind", aboutData.stateMindPic);
  data.append("people", aboutData.sensePeopleSecond);
  data.append("people_sec", aboutData.sensePeopleFirst);
  data.append("experience", aboutData.extendingExperiencePic);
  data.append("privacy", aboutData.aboutPrivacyFirstPic);
  data.append("privacy_sec", aboutData.aboutPrivacySecondPic);
  data.append("environmentally", aboutData.environmentallyConsciousPic);
  data.append("community", aboutData.communityPic);
  data.append("about_text", aboutData.bannerDescription);
  data.append("principal_text", aboutData.principleDescription);
  data.append("mind_text", aboutData.mindDescription);
  data.append("place_text", aboutData.peopleDescription);
  data.append("experience_text", aboutData.experienceDescription);
  data.append("privacy_text", aboutData.privacyDescription);
  data.append("discovery_text", aboutData.discoveryDescription);
  data.append("eco_text", aboutData.ecoDescription);
  data.append("environmentally_text", aboutData.environmentallyDescription);
  data.append("community_text", aboutData.communityDescription);
  data.append("meta_title", aboutData.metaTitle);
  data.append("meta_description", aboutData.metaDescription);

  const response = await axiosInstance.request({
    url: "add_about",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const updateAbout = async (aboutData) => {
 
  const data = new FormData();
  data.append("id", aboutData.id);
  data.append("banner", aboutData.bannerPic);
  data.append("principal", aboutData.corePrincipalPic);
  data.append("mind", aboutData.stateMindPic);
  data.append("people", aboutData.sensePeopleSecond);
  data.append("people_sec", aboutData.sensePeopleFirst);
  data.append("experience", aboutData.extendingExperiencePic);
  data.append("privacy", aboutData.aboutPrivacyFirstPic);
  data.append("privacy_sec", aboutData.aboutPrivacySecondPic);
  data.append("environmentally", aboutData.environmentallyConsciousPic);
  data.append("community", aboutData.communityPic);
  data.append("about_text", aboutData.bannerDescription);
  data.append("principal_text", aboutData.principleDescription);
  data.append("mind_text", aboutData.mindDescription);
  data.append("place_text", aboutData.peopleDescription);
  data.append("experience_text", aboutData.experienceDescription);
  data.append("privacy_text", aboutData.privacyDescription);
  data.append("discovery_text", aboutData.discoveryDescription);
  data.append("eco_text", aboutData.ecoDescription);
  data.append("environmentally_text", aboutData.environmentallyDescription);
  data.append("community_text", aboutData.communityDescription);
  data.append("meta_title", aboutData.metaTitle);
  data.append("meta_description", aboutData.metaDescription);

  const response = await axiosInstance.request({
    url: "update_about",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showAboutContent = async () => {
  const response = await axiosInstance.request({
    url: "all_about",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const findAboutContent = async (id) => {
  const response = await axiosInstance.request({
    url: `about_id/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};