import axiosInstance from "../api";
const token = localStorage.getItem("token");

export const addExclusiveOffer = async (exclusiveOfferData) => {
 
  const data = new FormData();
  data.append("image", exclusiveOfferData.image);
  data.append("bannerImage", exclusiveOfferData.bannerImage);
  data.append("title", exclusiveOfferData.name);
  data.append("country", exclusiveOfferData.country);
  data.append("category", exclusiveOfferData.category);
  data.append("subcategory", exclusiveOfferData.subcategory);
  data.append("terms", exclusiveOfferData.terms);
  data.append("package_duration", exclusiveOfferData.package_duration);
  data.append("shortDescription", exclusiveOfferData.shortDescription);
  data.append("packageDescription", exclusiveOfferData.packageDescription);
  data.append("offerType", exclusiveOfferData.offerType);
  data.append("locationId", exclusiveOfferData.locationId);
  data.append("page", JSON.stringify(exclusiveOfferData.listData));
  data.append("meta_title", exclusiveOfferData.metaTitle);
  data.append("meta_description", exclusiveOfferData.metaDescription);
  data.append("link", exclusiveOfferData.enquiryLink);
  const response = await axiosInstance.request({
    url: "addExclusiveOffers",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const addExclusivecategory = async (exclusiveOfferData) => {
 
  const data = new FormData();
  data.append("title", exclusiveOfferData.title);
  data.append("location", exclusiveOfferData.location);

  const response = await axiosInstance.request({
    url: "addexclusiveCategory",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const updateExclusivecategory = async (exclusiveOfferData) => {
 
  const data = new FormData();
  data.append("id", exclusiveOfferData.id);
  data.append("title", exclusiveOfferData.title);
  data.append("location", exclusiveOfferData.location);

  const response = await axiosInstance.request({
    url: "updateexclusiveCategory",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showExclusiveOfferList = async () => {
  const response = await axiosInstance.request({
    url: "showExclusiveOffer",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const listManageTicket = async () => {
  const response = await axiosInstance.request({
    url: "listManageticket",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showCruiseEnquiryList = async () => {
  const response = await axiosInstance.request({
    url: "customerEnquiryCruise",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const FindExclusiveCategoryList = async (id) => {
  const response = await axiosInstance.request({
    url: `FindexclusiveCategory/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showExclusiveCategoryList = async () => {
  const response = await axiosInstance.request({
    url: "showexclusiveCategory",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showRestaurantList = async () => {
  const response = await axiosInstance.request({
    url: "showrestaurantList",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showRetreatList = async () => {
  const response = await axiosInstance.request({
    url: "showretreatList",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const deleteExclusiveOffer = async (id) => {
  const response = await axiosInstance.request({
    url: `deleteExclusiveOffer/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteExclusiveCategory = async (id) => {
  const response = await axiosInstance.request({
    url: `deleteExclusiveCategory/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const editExclusiveOfferApi = async (id) => {
  const response = await axiosInstance.request({
    url: `showExclusiveOfferid/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updateExclusiveOffer = async (exclusiveOfferData, id) => {
  const data = new FormData();
  data.append("image", exclusiveOfferData.image);
  data.append("title", exclusiveOfferData.title);
  data.append("country", exclusiveOfferData.country);
  data.append("category", exclusiveOfferData.category);
  data.append("subcategory", exclusiveOfferData.subcategory);
  data.append("terms", exclusiveOfferData.terms);
  data.append("package_duration", exclusiveOfferData.package_duration);
  data.append("shortDescription", exclusiveOfferData.shortDescription);
  data.append("packageDescription", exclusiveOfferData.packageDescription);
  data.append("offerType", exclusiveOfferData.offerType);
  data.append("locationId", exclusiveOfferData.locationId);
  data.append("bannerImage", exclusiveOfferData.bannerImage);
  data.append("page", JSON.stringify(exclusiveOfferData.listData));
  data.append("meta_title", exclusiveOfferData.metaTitle);
  data.append("meta_description", exclusiveOfferData.metaDescription);
  data.append("link", exclusiveOfferData.enquiryLink);
  data.append("id", id);

  const response = await axiosInstance.request({
    url: "updateExclusiveOffer",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const updateExclusiveStatuss = async (exclusiveOfferStatus) => {
  const data = new FormData();
  data.append("status", exclusiveOfferStatus.status);
  data.append("id", exclusiveOfferStatus.id);

  const response = await axiosInstance.request({
    url: "updateExclusiveStatus",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteManageTicket = async (id) => {
  const response = await axiosInstance.request({
    url: `deleteManageticket/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const editMangegeTicketData = async (id) => {
  const response = await axiosInstance.request({
    url: `findmanageticket/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const listManagePayment = async () => {
  const response = await axiosInstance.request({
    url: "paymentData",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const editMangegePaymentData = async (id) => {
  const response = await axiosInstance.request({
    url: `FindpaymentData/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};