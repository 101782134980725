import React, { useEffect, useState } from "react";
import styles from "./sidemenu.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../component/table";
import { deleteIcon, editIcon } from "../../../utils/svg.file";
import {
  deleteFeaturedStory,
  showFeaturedStoryList,
  showSidebarList,
} from "../../../api/adminPanel/featuredStory";
import { Featured_URL, Offers_URL } from "../../../serverUrl";
import DeleteModal from "../../component/modal/deleteModal";
import swal from "sweetalert";

export default function ManageSideMenu() {
  const navigate = useNavigate();
  const [featuredList, setFeaturedList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const [showValue, setShowValue] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleFeaturedList = async () => {
    const response = await showSidebarList();
    if (response.remote === "success") {
      setFeaturedList(response.data.data);
    } else {
    }
  };

  const handleDelete = async (id) => {
    const response = await deleteFeaturedStory(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
    } else {
    }
  };
  const columns = [
    { field: "sno", headerName: "SNo.", width: 100 },
    { field: "heading", headerName: "Heading", height: 150, width: 150 },

    {
      field: "description",
      headerName: "Description",
      height: 300,
      width: 400,
    },
    {
      field: "image",
      height: 300,
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <img
              src={`${Offers_URL}${cellValues.row.image}`}
              className={styles.table_img}
            />
          </>
        );
      },
    },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-sidemenu/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            {/* <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleFeaturedList();
  }, []);

  const filteredList = () => {
    if (searchedData) {
      return searchedList;
    } else {
      return featuredList;
    }
  };
  let rows = [];

  filteredList().map((item, index) => {
    rows.push({
      id: item.id,
      sno: index+1,
      heading: item.type,
      description: item.discription,
      image: item.image,
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Side Menu</h3>
              </div>
              {/* <Button
                className={styles.main_button}
                onClick={() => navigate("/add-sidemenu")}
              >
                Add Side Menu
              </Button> */}
            </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>

      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
