import React, { useEffect, useState } from "react";
import styles from "./../retreats.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import {
  AddRestaurantDetails,
  AddRetreatRoom,
  deleteImage,
  findRetreatroom,
  showRestaurantName,
  showRetreatDetailName,
  UpdateRetreatRoom,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import {
  UploadIcon,
  addInputIcon,
  crossRed,
  deleteIcon,
} from "../../../../utils/svg.file";
import { addGallerySliderImage } from "../../../../api/adminPanel/restaurantApi";
export default function EditRoom() {
  const navigate = useNavigate();
  const [longDescription, setlongDescription] = useState("");
  const { id } = useParams();
  const [titledata, setTitledata] = useState("");
  const [categorydata, setCategorydata] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [sequencedata, setSequencedata] = useState("");

  const [imageGalleryArray, setImageGalleryArray] = useState([]);
  const [restaurantName, setRestaurantName] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [roomsize, setRoomsize] = useState("");
  const [masterRoomtitle, setMasterRoomtitle] = useState("");
  const [guestRoomtitle, setGuestRoomtitle] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");

  const [guestRoomFeatureArray, setGuestRoomFeatureArray] = useState([
    {
      guestRoomFeature: "",
      guestOrder: "",
    },
  ]);
  const handleGuestRoomFeatureChange = (index, event) => {
    let data = [...guestRoomFeatureArray];
    data[index][event.target.name] = event.target.value;
    setGuestRoomFeatureArray(data);
  };
  const addGuestRoomFeature = () => {
    let newGuestRoomFeature = {
      guestRoomFeature: "",
      guestOrder: "",
    };

    setGuestRoomFeatureArray([...guestRoomFeatureArray, newGuestRoomFeature]);
  };
  const removeGuestRoomFeature = (index) => {
    let data = [...guestRoomFeatureArray];
    data.splice(index, 1);
    setGuestRoomFeatureArray(data);
  };

  const [roomFeatureArray, setRoomFeatureArray] = useState([
    {
      roomFeature: "",
      order: "",
    },
  ]);
  const handleRoomFeatureChange = (index, event) => {
    let data = [...roomFeatureArray];
    data[index][event.target.name] = event.target.value;
    setRoomFeatureArray(data);
  };
  const addRoomFeature = () => {
    let newRoomFeature = {
      roomFeature: "",
      order: "",
    };

    setRoomFeatureArray([...roomFeatureArray, newRoomFeature]);
  };
  const removeRoomFeature = (index) => {
    let data = [...roomFeatureArray];
    data.splice(index, 1);
    setRoomFeatureArray(data);
  };
  const categoryList = [];
  restaurantName?.map((item, index) => {
    categoryList.push({
      id: item.id,
      category: item.title,
      location: item.location,
    });
  });

  const onChange = async ({ fileList }) => {
    setCardImage(fileList[0].originFileObj);
  };
  const BanneronChange = async ({ fileList }) => {
    setBannerImage(fileList[0].originFileObj);
  };

  const GalleryOnChange = async ({ fileList }) => {
    setFileList(fileList);
    const addGalleryPayload = {
      galleryImage: fileList[0].originFileObj,
    };
    const response = await addGallerySliderImage(addGalleryPayload);

    if (response.remote === "success") {
      swal("Image Successfully Added");
      setImageGalleryArray([...imageGalleryArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };

  const handlefindretreatRoom = async () => {
    const response = await findRetreatroom(id);

    if (response.remote === "success") {
      setCategorydata(response.data.data[0].retreatId);
      setTitledata(response.data.data[0].title);
      setRoomsize(response.data.data[0].description);
      setCardImage(response.data.data[0].cardImage);
      setShortDescription(response.data.data[0].shortDescription);
      setlongDescription(response.data.data[0].longdescription);
      setImageGalleryArray(JSON.parse(response.data.data[0].gallery));
      setRoomFeatureArray(JSON.parse(response.data.data[0].room_features));
      setGuestRoomFeatureArray(JSON.parse(response.data.data[0].guest_features));
      setBannerImage(response.data.data[0].banner);
      setSequencedata(response.data.data[0].sequence);
      setGuestRoomtitle(response.data.data[0].guesttitle);
      setMasterRoomtitle(response.data.data[0].mastertitle);
      setMetaTitle(response.data.data[0].meta_title);
      setMetaDescription(response.data.data[0].meta_description);
      
    } else {
    }
  };

  const handleRestaurantName = async () => {
    const response = await showRetreatDetailName();

    if (response.remote === "success") {
      setRestaurantName(response.data.data);
    } else {
    }
  };

  const handleUpdateRetreatRoom = async () => {
    const restaurantData = {
      id: id,
      category: categorydata,
      roomFeatures: roomFeatureArray,
      guestFeatures: guestRoomFeatureArray,
      description: roomsize,
      cardImage: cardImage,
      imageGallery: imageGalleryArray,
      shortDescription: shortdescription,
      longDescription: longDescription,
      title: titledata,
      sequencedata: sequencedata,
      masterRoomtitle: masterRoomtitle,
      guestRoomtitle: guestRoomtitle,
      bannerImage: bannerImage,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    };
    const response = await UpdateRetreatRoom(restaurantData);

    if (response.remote === "success") {
      swal("Room Added Successfully");
      navigate("/manage-room");
    } else {
    }
  };

  const handelDeleteImgae = async (image) => {
    const newImageArray = imageGalleryArray.filter(
      (imageGalleryArray) => imageGalleryArray !== image
    );
    setImageGalleryArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };

  useEffect(() => {
    handleRestaurantName();
    handlefindretreatRoom();
  }, []);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Update Room Details</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={9}>
                  <label className="fw-bold">Select Retreats</label>
                  <Form.Group className="">
                    <Form.Select
                      className={styles.form_input}
                      value={categorydata}
                      onChange={(e) => setCategorydata(e.target.value)}
                    >
                      <option selected>Choose Retreats </option>
                      {categoryList.map((item, index) => {
                        return (
                          <>
                            <option key={index} value={item.id}>
                              {item.category}, {item.location}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <label className="fw-bold">Room Size</label>
                  <Form.Control
                    type="text"
                    placeholder="51 sqm / 550 sqft"
                    className={styles.form_input}
                    onChange={(e) => setRoomsize(e.target.value)}
                    value={roomsize}
                  />
                </Col>
                <Col md={3}>
                  {" "}
                  <label className="fw-bold">
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 818 X 814 px)
                    </small>
                  </label>{" "}
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={818 / 814}>
                      <Upload
                        maxCount={1}
                        onChange={onChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        {cardImage.length >= 1 ? (
                          <>
                            <img src={`${Offers_URL}${cardImage}`} alt="" />
                          </>
                        ) : (
                          <>
                            <div className="file_upload_div">
                              <span>{UploadIcon}</span>
                              <p>Click Here to Upload</p>
                            </div>
                          </>
                        )}
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={9}>
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setTitledata(e.target.value)}
                    value={titledata}
                  />
                </Col>
                <Col md={3}>
                  {" "}
                  <label className="fw-bold">
                    Banner Image
                    <small className="text-success small-msg">
                      (Max. Size 788 X 480 px)
                    </small>
                  </label>{" "}
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={788 / 480}>
                      <Upload
                        maxCount={1}
                        onChange={BanneronChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        {bannerImage?.length >= 1 ? (
                          <>
                            <img src={`${Offers_URL}${bannerImage}`} alt="" />
                          </>
                        ) : (
                          <>
                            <div className="file_upload_div">
                              <span>{UploadIcon}</span>
                              <p>Click Here to Upload</p>
                            </div>
                          </>
                        )}
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={12} className="multi-images">
                  <label className="fw-bold">
                    Slider Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <div className="d-flex align-items-center">
                    <ImgCrop
                      grid
                      aspect={1219 / 757}
                      styles={{ width: "120px" }}
                    >
                      <Upload
                        maxCount={1}
                        onChange={GalleryOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                    <ul className={styles.multi_image_preview}>
                      {imageGalleryArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => handelDeleteImgae(item)}
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  {" "}
                  <label className="fw-bold">Description</label>
                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setShortDescription(e.target.value)}
                    value={shortdescription}
                  />
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Long Description</label>
                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Long Description"
                    className={styles.form_input}
                    onChange={(e) => setlongDescription(e.target.value)}
                    value={longDescription}
                  />
                </Col>
                <Col md={9}>
                  <label className="fw-bold">Sequence</label>
                  <Form.Control
                    type="text"
                    placeholder="Sequence"
                    className={styles.form_input}
                    onChange={(e) => setSequencedata(e.target.value)}
                    value={sequencedata}
                  />
                </Col>
                <Col md={12}>
                <label className="fw-bold">Master Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Master Room Features"
                    className={styles.form_input}
                    onChange={(e) => setMasterRoomtitle(e.target.value)}
                    value={masterRoomtitle}
                  />
                  {roomFeatureArray.map((item, index) => {
                    return (
                      <>
                        <Row gutter={[24, 0]} key={index}>
                          <label className="fw-bold">Master Room Feature</label>
                          <Col md={8}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="roomFeature"
                              placeholder="Master Room Feature"
                              className={styles.form_input}
                              defaultValue={item.roomFeature}
                              onChange={(event) =>
                                handleRoomFeatureChange(index, event)
                              }
                            />
                          </Col>
                          <Col md={2}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="order"
                              placeholder="Order"
                              className={styles.form_input}
                              defaultValue={item.order}
                              onChange={(event) =>
                                handleRoomFeatureChange(index, event)
                              }
                            />
                          </Col>

                          <Col md={2} className="text-end">
                            <span
                              className={styles.edit_icon_btn}
                              onClick={() => addRoomFeature(index)}
                            >
                              {addInputIcon}
                            </span>
                            {roomFeatureArray.length > 1 ? (
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() => removeRoomFeature(index)}
                              >
                                {deleteIcon}
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>

                        <hr />
                      </>
                    );
                  })}
                </Col>  <Col md={12}>
                <label className="fw-bold">Guest Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Guest Room Features"
                    className={styles.form_input}
                    onChange={(e) => setGuestRoomtitle(e.target.value)}
                    value={guestRoomtitle}
                  />
                  {guestRoomFeatureArray?.map((item, index) => {
                    return (
                      <>
                        <Row gutter={[24, 0]} key={index}>
                          <label className="fw-bold">Guest Room Feature</label>
                          <Col md={8}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="guestRoomFeature"
                              placeholder="Guest Room Feature"
                              className={styles.form_input}
                              defaultValue={item.guestRoomFeature}
                              onChange={(event) =>
                                handleGuestRoomFeatureChange(index, event)
                              }
                            />
                          </Col>
                          <Col md={2}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="guestOrder"
                              placeholder=" Order"
                              className={styles.form_input}
                              defaultValue={item.guestOrder}
                              onChange={(event) =>
                                handleGuestRoomFeatureChange(index, event)
                              }
                            />
                          </Col>

                          <Col md={2} className="text-end">
                            <span
                              className={styles.edit_icon_btn}
                              onClick={() => addGuestRoomFeature(index)}
                            >
                              {addInputIcon}
                            </span>
                            {guestRoomFeatureArray.length > 1 ? (
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() => removeGuestRoomFeature(index)}
                              >
                                {deleteIcon}
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>

                        <hr />
                      </>
                    );
                  })}
                </Col>
                <label className="fw-bold">Enter Meta</label>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      className={styles.form_input}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      className={styles.form_input}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>

                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleUpdateRetreatRoom}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
