import React, { useEffect, useState } from "react";
import styles from "./job.module.css";
import Table from "../../component/table";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteIcon, editIcon, viewIcon } from "../../../utils/svg.file";
import { allJobs } from "../../../api/user";
import DeleteModal from "../../component/modal/deleteModal";
import { deleteJob } from "../../../api/adminPanel/jobapi";
import swal from "sweetalert";

export default function ManageJobList() {
  const navigate = useNavigate();
  const [joblist, setjobList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [showValue, setShowValue] = useState("");
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState('')
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const columns = [
    { field: "id", headerName: "SNo.", width: 70 },
    { field: "propertyManager", headerName: "Designation", width: 150 },
    { field: "location", headerName: "Location", width: 160 },
    { field: "category", headerName: "Category", width: 150 },
    { field: "city", headerName: "Country", width: 250 },
    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              onClick={() => {
                navigate(`/view-job-detail/${cellValues.row.id}`);
              }}
            >
              {viewIcon}
            </span>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-job-detail/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];

  const handleShowJob = async () => {
    const response = await allJobs();

    if (response.remote === "success") {
      setjobList(response.data.data);
    } else {
    }
  };

  const handleDelete = async (id) => {
    const response = await deleteJob(id);
    if (response.remote === "success") {
      hideDeleteModal();
      swal("Job Deleted Successfully!");
    } else {
    }
  };

  useEffect(() => {
    handleShowJob();
  }, []);
  const handleSearch = (data) => {
    if (data) {
      let searchData = joblist.filter((entry) =>
        entry.location?.toLowerCase().includes(data.toLowerCase())|| entry.designation?.toLowerCase().includes(data.toLowerCase())
      );
      setSearchedList(searchData);
    }
  };

  const filteredList = () => {
    if(searchedData) {
      return searchedList
    } else {
      return joblist
    }
  }
  
  let rows = [];
  let newstate = "";
  filteredList().map((item) => {
    // if (Number(item.state) === 2) {
    //   newstate = "Singapore";
    // } else {
      newstate = "Malaysia";
    // }
    rows.push({
      id: item.id,
      propertyManager: item.designation,
      category: item.category,
      city: newstate,
      location: item.location,
      action: (
        <>
          <Button>View</Button>
        </>
      ),
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Job List</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-job-detail")}
              >
                Add Job
              </Button>
            </div>
            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select className="form-control ms-1 me-1" onChange={(e)=>setShowValue(e.target.value)}>
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>


                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchedData(e.target.value);
                }}
              />
            </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
