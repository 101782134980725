import React, { useEffect, useState } from "react";
import styles from "../ticket.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { viewIcon } from "../../../../utils/svg.file";
import { Offers_URL } from "../../../../serverUrl";
import DeleteModal from "../../../component/modal/deleteModal";
import IMAGES from "../../../assets/images";
import { listManagePayment } from "../../../../api/adminPanel/exclusiveOfferApi";
import moment from "moment";

export default function ManagePaymentHistory() {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [showValue, setShowValue] = useState("");
  const [paymentData, setPaymentData] = useState([]);
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const columns = [
    // { field: "id", headerName: "SNo.", width: 100 },
    { field: "sn", headerName: "SNo.", width: 100 },
    { field: "name", headerName: "Name", height: 200, width: 250 },
    { field: "mobile", headerName: "Mobile No.", height: 300, width: 200 },
    { field: "email", headerName: "Email", height: 300, width: 200 },
    { field: "date", headerName: "Date", height: 300, width: 250 },
    { field: "paymentStatus", headerName: "Payment Status", height: 300, width: 250 },

    {
      field: "amount",
      headerName: "Total Amount",
      height: 300,
      width: 100,
    },

    {
      field: "ticket",
      headerName: "Total Ticket (quantity)",
      height: 300,
      width: 160,
    },
    {
      field: "Action",
      width: 80,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/order-detail/${cellValues.row.id}`);
              }}
            >
              {viewIcon}
            </span>
          </>
        );
      },
    },
  ];
  const handlePaymentList = async () => {
    const response = await listManagePayment();
    if (response.remote === "success") {
      // const parseData = response.data.data.quatity

      setPaymentData(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handlePaymentList();
  }, []);

  const handleDelete = async (id) => {};

  let rows = [];

  paymentData?.map((item, index) => {
    const quantityData = JSON.parse(item.quatity);
    let totalQuantity = 0;
    quantityData?.map((item, index) => {
      const quantity = parseInt(item.quanitty.quantity, 10); // Parse quantity as an integer

      // Add the quantity to the total
      totalQuantity += quantity;
    });
    rows.push({
      id: item.id,
      sn: index + 1,
      name: item.name,
      date: item.paid ? moment(item.paid).format("MMM Do YYYY, h:mm:ss") : "",
      mobile: item.mobile,
      email: item.email,
      amount: item.amount/100,
      paymentStatus: item.paymentStatus,
      ticket: totalQuantity,
    });
  });

  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Payment History</h3>
              </div>
            </div>
            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select
                  className="form-control ms-1 me-1"
                  onChange={(e) => setShowValue(e.target.value)}
                >
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>

                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
              />
            </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>

      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
