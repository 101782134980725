import axiosInstance from "./../api";
const token = localStorage.getItem("token");

export const showAdminSettingData = async (adminData) => {
  const response = await axiosInstance.request({
    url: "setting",
    method: "GET",
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const submitAdminSetting = async (adminData) => {
  const data = new FormData();
  data.append("instagram", adminData.instagram);
  data.append(" facebook", adminData.facebook);
  data.append("youtube", adminData.youtube);
  data.append("host", adminData.host);
  data.append("mailer", adminData.mailer);
  data.append("username", adminData.username);

  data.append("password", adminData.password);
  data.append("port", adminData.port);
  data.append("copywrite", adminData.copywrite);

  const response = await axiosInstance.request({
    url: "updatesetting",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
