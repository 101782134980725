import React, { useEffect, useState } from "react";
import styles from "./../restaurant.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { imageListClasses } from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import {
  addCelebrationBannerImage,
  addCelebrationData,
  AddRestaurantDetails,
  deleteImage,
  showRestaurantName,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import { addInputIcon, crossRed, deleteIcon } from "../../../../utils/svg.file";
import {
  SendToMobile,
  SettingsRemoteOutlined,
  UploadOutlined,
} from "@mui/icons-material";
import Base from "antd/es/typography/Base";
import { addGallerySliderImage } from "../../../../api/adminPanel/restaurantApi";
export default function ViewRestaurantPackageAndDetail() {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [restaurant, setRestaurant] = useState("");
  const [titledata, setTitledata] = useState("");
  const [descriptiontitledata, setDescriptionTitledata] = useState("");
  const [categorydata, setCategorydata] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [middledescription, setMiddleDescription] = useState("");
  const [detaileddescription, setDetailedDescription] = useState("");
  const [url, seturl] = useState("");
  const [cardImage, setCardImage] = useState("");

  const [imageBanner, setImageBanner] = useState("");
  const [imageGalleryArray, setImageGalleryArray] = useState([]);
  const [restaurantName, setRestaurantName] = useState([]);

  const [mobile, setMobile] = useState("");
  const [imgHome, setImgBanner] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileListCard, setFileListCard] = useState([]);
  const [fileListBanner, setFileListBanner] = useState([]);
  const [imageGallery, setImageGallery] = useState("");

  const [imageArray, setImageArray] = useState([]);

  const categoryList = [];
  restaurantName?.map((item, index) => {
    categoryList.push({
      id: item.id,
      category: item.name,
    });
  });
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const onChange = async ({ fileList }) => {
    // setFileListCard(fileListCard);

    setCardImage(fileList[0].originFileObj);
  };
  const BannerOnChange = async ({ fileList }) => {
    // setFileList(fileList);
    setImageBanner(fileList[0].originFileObj);
  };
  const GalleryOnChange = async ({ fileList }) => {
    setFileList(fileList);
    setImageGallery(fileList[0].originFileObj);
    const addGalleryPayload = {
      galleryImage: fileList[0].originFileObj,
    };
    const response = await addGallerySliderImage(addGalleryPayload);

    if (response.remote === "success") {
      swal("Image Successfully Added");
      setImageGalleryArray([...imageGalleryArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };
  const handleRestaurantName = async () => {
    const response = await showRestaurantName();

    if (response.remote === "success") {
      setRestaurantName(response.data.data);
    } else {
    }
  };
  const handleRestaurantDetails = async () => {
    const restaurantData = {
      restaurant: restaurant,
      cardImage: cardImage,
      banerImage: imageBanner,
      category: categorydata,
      descriptionTitle: descriptiontitledata,
      galleryImage: imageGalleryArray,
      title: titledata,
      short_description: shortdescription,
      middle_description: middledescription,
      description: detaileddescription,
      phone: mobile,
      url: url,
    };
    const response = await AddRestaurantDetails(restaurantData);

    if (response.remote === "success") {
    } else {
    }
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  // const handelDeleteImgae = async (image) => {
  //   const newImageArray = imageArray.filter(
  //     (imageArray) => imageArray !== image
  //   );
  //   setImageArray(newImageArray);
  //   const deleteimages = {
  //     image: image,
  //   };

  //   const response = await deleteImage(deleteimages);

  //   if (response.remote === "success") {
  //     swal("Image Delete succesfully!");
  //   } else {
  //     swal("Something Went Wrong!");
  //   }
  // };

  const firstUpload = {
    action: { Base_URL },
    listType: "picture-card",
    defaultFileList: [...fileList],
    maxCount: "1",
  };
  const BannerUpload = {
    action: { Base_URL },
    listType: "picture-card",
    defaultFileList: [...fileList],
  };
  const GalleryUpload = {
    action: { Base_URL },
    listType: "picture-card",
    defaultFileList: [...fileList],
  };

  useEffect(() => {
    handleRestaurantName();
  }, []);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>View Package & Detail</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold"> Restaurant Name</label>
                  <p>fsdsffds</p>
                  <label className="fw-bold">Title</label>
                  <p>this</p>
                  <label className="fw-bold">Category</label>
                  <p>category</p>
                  <label className="fw-bold">Description Title</label>
                  <p>Shprt fdkfddsf sfddkfdsk</p>
                </Col>
                <Col md={6}>
                  {" "}
                  <label className="fw-bold">Card Image</label>
                  <ImgCrop grid aspect={2 / 3}>
                    <Upload
                      maxCount={1}
                      onChange={onChange}
                      action={Base_URL}
                      listType="picture-card"
                      defaultFileList={[...fileList]}
                    >
                      {cardImage.length < 1 && "+ Upload"}
                    </Upload>
                  </ImgCrop>
                  <label className="fw-bold">Banner Image</label>
                  <ImgCrop grid aspect={3 / 2}>
                    <Upload
                      maxCount={1}
                      onChange={BannerOnChange}
                      action={Base_URL}
                      listType="picture-card"
                      defaultFileList={[...fileList]}
                    >
                      {imageBanner.length < 1 && "+ Upload"}
                    </Upload>
                  </ImgCrop>
                </Col>
                {/* <Col md={3} className="multi-images">
                  <label className="fw-bold">Banner Image</label>
                  <ImgCrop grid aspect={1 / 3}>
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture"
                      defaultFileList={[...fileList]}
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </ImgCrop>
                </Col> */}
                <Col md={6}>
                  <label className="fw-bold">Short Description</label>

                  <p>Shprt fdkfddsf sfddkfdsk</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Detailed Description</label>

                  <p>detail fdkfddsf sfddkfdsk</p>
                </Col>
                <Col md={12} className="multi-images">
                  <label className="fw-bold">Gallery Slider Images</label>
                  <div className="d-flex align-items-center">
                    <ImgCrop grid aspect={3 / 2} styles={{ width: "120px" }}>
                      <Upload
                        maxCount={1}
                        onChange={GalleryOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        {"+ Upload"}
                      </Upload>
                    </ImgCrop>
                    <ul className={styles.multi_image_preview}>
                      {imageGalleryArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  // onClick={() => handelDeleteImgae(item)}
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Middle Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Detailed Description"
                    className={styles.form_input}
                    onChange={(e) => setMiddleDescription(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Phone Number</label>
                  <Form.Control
                    type="text"
                    placeholder="434435355"
                    className={styles.form_input}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </Col>{" "}
                <Col md={6}>
                  <label className="fw-bold"> Direction URL</label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={styles.form_input}
                    onChange={(e) => seturl(e.target.value)}
                  />
                </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleRestaurantDetails}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
