import React, { useEffect, useState } from "react";
import styles from "./../experience.module.css";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon } from "../../../../utils/svg.file";

import { Featured_URL } from "../../../../serverUrl";
import DeleteModal from "../../../component/modal/deleteModal";
import swal from "sweetalert";
import {
  deleteexperiencecategory,
  deleteretreatecategory,
  showExperienceList,
  showRestaurantName,
  showRetreatList,
} from "../../../../api/adminPanel/giftCardApi";
import { Box } from "@mui/material";

export default function ManageExperienceCategory() {
  const navigate = useNavigate();
  const [retreatName, setRetreatName] = useState([]);
  const [experienceList, setExperienceList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [showListModal, setShowListModal] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleShowListModal = (id) => {
    setShowListModal(true);
    setShowDeleteModal(false);
  };
  const hideListModal = () => {
    setShowListModal(false);
  };

  const handleRetreatList = async () => {
    const response = await showExperienceList();
    if (response.remote === "success") {
      setRetreatName(response.data.data);
    } else {
    }
  };

  const handleDelete = async (id) => {
    const response = await deleteexperiencecategory(id);

    if (response.remote === "success") {
      if(response.data.errors){
        setExperienceList(response.data.data)
        handleShowListModal()
      }else{

        hideDeleteModal();
        swal("Deleted Successfully!");
        handleRetreatList();
      }
    } else {
    }
  };
  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    { field: "categoryName", headerName: "Category Name", width: 700 },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(
                  `/edit-experience-category/${cellValues.row.id}`
                );
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleRetreatList();
  }, []);
  let rows = [];

  retreatName?.map((item, index) => {
    rows.push({
      id: item.id,
      categoryName: item.title,
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Stories Category</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-experience-category")}
              >
                Add Category
              </Button>
            </div>
            {/* <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select className="form-control ms-1 me-1">
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>

                  <option value="50"> 50</option>

                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
              />
            </div> */}
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
      <Modal show={showDeleteModal} onHide={hideDeleteModal}>
        <Modal.Body className="text-center">
          <h4> Are You Sure You want to Deletes ?</h4>
          <div className="w-100 mt-3">
            {" "}
            <Button variant="secondary" onClick={hideDeleteModal}>
              Close
            </Button>
            <Button
              className="ms-4"
              variant="danger"
              onClick={() => handleDelete(deleteId)}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* -==-=-=--=-=-=-=-List show modal-=-=-=-=-=- */}
      <Modal show={showListModal} onHide={hideListModal}>
        <Modal.Body className="text-center">
          <Box>
            <h4>
              This category has been added bleow stories move given stories in
              other categories then you''ll be able to delete category.
            </h4>
            <Box sx={{ textAlign: "left" }} className="modal_list">
              <ul className={styles.border_list}>
                {experienceList?.map((item) => (
                  <li className={styles.list_item}>
                    {item.title}
                  </li>
                ))}
                
              </ul>
            </Box>
            <div className="w-100 mt-3 text-center">
              <Button variant="secondary" onClick={hideListModal}>
                Close
              </Button>
            </div>
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
}
