import React, { useEffect, useState } from "react";
import styles from "./../restaurant.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormControlLabel, Switch, imageListClasses } from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import {
  addCelebrationBannerImage,
  addCelebrationData,
  deleteImage,
  ShowRestaurantDetailsId,
  showRestaurantName,
  UpdateRestaurantDetails,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import {
  UploadIcon,
  addInputIcon,
  crossRed,
  deleteIcon,
} from "../../../../utils/svg.file";
import { addGallerySliderImage } from "../../../../api/adminPanel/restaurantApi";
export default function EditRestaurantPackageAndDetail() {
  const navigate = useNavigate();

  const [file, setFile] = useState("");
  const [location, setLocation] = useState([]);
  const [titledata, setTitledata] = useState("");
  const [description, setDescription] = useState("");
  const [imageBannerArray, setImageBannerArray] = useState([]);
  const [imageGalleryArray, setImageGalleryArray] = useState([]);

  const [imgHome, setImgBanner] = useState("");
  const [fileList, setFileList] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [restaurantFullDetails, setRestaurantFullDetails] = useState([]);
  const [hightlight, setHighLight] = useState("");
  const [priceSection, setPriceSection] = useState("");
  const [guidedTour, setGuidedTour] = useState("");
  const [termCondition, setTermCondition] = useState("");
  const [descriptiontitledata, setDescriptionTitledata] = useState("");
  const [categorydata, setCategorydata] = useState("");
  const [imageBanner, setImageBanner] = useState("");
  const [imageGallery, setImageGallery] = useState("");
  const [url, seturl] = useState("");
  const [mobile, setMobile] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [middledescription, setMiddleDescription] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [booking, setBooking] = useState("");
  const [restaurantName, setRestaurantName] = useState([]);
  const [state, setState] = useState("");
  const [indoor, setIndoor] = useState("");
  const [outdoor, setOutdoor] = useState("");
  const [reception, setReception] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");

  let { id } = useParams();
  const handleRestaurantName = async () => {
    const response = await showRestaurantName();

    if (response.remote === "success") {
      setRestaurantName(response.data.data);
    } else {
    }
  };
  const categoryList = [];
  restaurantName?.map((item, index) => {
    categoryList.push({
      id: item.id,
      category: item.name,
    });
  });
  useEffect(() => {
    handleRestaurantName();
  }, []);

  const handleRestaurantData = async (id) => {
    const response = await ShowRestaurantDetailsId(id);

    if (response.remote === "success") {
      setRestaurantFullDetails(response.data.data);
      setCardImage(response.data.data[0].cardImage);
      setImageBanner(response.data.data[0].banerImage);
      setImageGalleryArray(JSON.parse(response.data.data[0].galleryImage));
    } else {
    }
  };
  useEffect(() => {
    handleRestaurantData(id);
  }, [id]);

  const onChange = async ({ fileList }) => {
    setFileList(fileList);
    setCardImage(fileList[0].originFileObj);
    const addCelebrationImagePayload = {
      image: fileList[0].originFileObj,
    };
  };
  const BannerOnChange = async ({ fileList }) => {
    setFileList(fileList);
    setImageBanner(fileList[0].originFileObj);
    const addCelebrationImagePayload = {
      image: fileList[0].originFileObj,
    };
  };
  const GalleryOnChange = async ({ fileList }) => {
    setFileList(fileList);
    setImageGallery(fileList[0].originFileObj);
    const addGalleryPayload = {
      galleryImage: fileList[0].originFileObj,
    };
    const response = await addGallerySliderImage(addGalleryPayload);

    if (response.remote === "success") {
      swal("Image Successfully Added");
      setImageGalleryArray([...imageGalleryArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };

  const handelDeleteImgae = async (image) => {
    const newImageArray = imageGalleryArray.filter(
      (imageGalleryArray) => imageGalleryArray !== image
    );
    setImageGalleryArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };

  const handleupdateRestaurantDetails = async () => {
    const restaurantData = {
      id: id,
      restaurant: location,
      cardImage: cardImage,
      banerImage: imageBanner,
      category: categorydata,
      descriptionTitle: descriptiontitledata,
      galleryImage: imageGalleryArray,
      title: titledata,
      short_description: shortdescription,
      middle_description: middledescription,
      description: description,
      phone: mobile,
      url: url,
      other: state,
      booking: booking,
      indoor: indoor,
      outdoor: outdoor,
      reception: reception,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    };
    const response = await UpdateRestaurantDetails(restaurantData);

    if (response.remote === "success") {
      swal("Data Updated");
      navigate("/manage-restaurant-package-and-detail");
    } else {
    }
  };
  useEffect(() => {
    setLocation(restaurantFullDetails[0]?.restaurant);
    setBooking(restaurantFullDetails[0]?.booking);
    setIndoor(restaurantFullDetails[0]?.indoor);
    setOutdoor(restaurantFullDetails[0]?.outdoor);
    setReception(restaurantFullDetails[0]?.reception);
    setTitledata(restaurantFullDetails[0]?.title);
    setCategorydata(restaurantFullDetails[0]?.category);
    setDescriptionTitledata(restaurantFullDetails[0]?.descriptionTitle);
    setShortDescription(restaurantFullDetails[0]?.short_description);
    setDescription(restaurantFullDetails[0]?.description);
    setMobile(restaurantFullDetails[0]?.phone);
    seturl(restaurantFullDetails[0]?.url);
    setMiddleDescription(restaurantFullDetails[0]?.middle_description);
    setState(restaurantFullDetails[0]?.other);
    setMetaTitle(restaurantFullDetails[0]?.meta_title);
    setMetaDescription(restaurantFullDetails[0]?.meta_description);
  }, [restaurantFullDetails]);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Package & Detail s</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Select Country</label>
                  <Form.Group className="">
                    <Form.Select
                      className={styles.form_input}
                      onChange={(e) => setState(e.target.value)}
                      value={state}
                    >
                      <option selected>Choose Country </option>
                      <option value="malaysia">Malaysia</option>
                      <option value="singapore">Singapore</option>
                    </Form.Select>
                  </Form.Group>
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    value={titledata}
                    className={styles.form_input}
                    onChange={(e) => setTitledata(e.target.value)}
                  />
                  <label className="fw-bold">Location</label>
                  <Form.Control
                    type="text"
                    placeholder="location"
                    value={location}
                    className={styles.form_input}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <label className="fw-bold">Category</label>
                  <Form.Control
                    type="text"
                    placeholder="Category"
                    value={categorydata}
                    className={styles.form_input}
                    onChange={(e) => setCategorydata(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  {" "}
                  <label className="fw-bold">Card Image</label>
                  <ImgCrop grid aspect={818 / 814}>
                    <Upload
                      maxCount={1}
                      onChange={onChange}
                      action={Base_URL}
                      listType="picture-card"
                      defaultFileList={[...fileList]}
                    >
                      {cardImage.length >= 1 ? (
                        <>
                          <img
                            src={`${Offers_URL}${cardImage}`}
                            alt=""
                            className={styles.preview_img}
                          />
                        </>
                      ) : (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                  <label className="fw-bold">Banner Image</label>
                  <ImgCrop grid aspect={1219 / 757}>
                    <Upload
                      maxCount={1}
                      onChange={BannerOnChange}
                      action={Base_URL}
                      listType="picture-card"
                      defaultFileList={[...fileList]}
                      className="banner_single"
                    >
                      {imageBanner.length >= 1 ? (
                        <>
                          <img
                            src={`${Offers_URL}${imageBanner}`}
                            alt=""
                            className={styles.preview_img}
                          />
                        </>
                      ) : (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>{" "}
                  <label className="fw-bold">Description Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Description Title"
                    className={styles.form_input}
                    value={descriptiontitledata}
                    onChange={(e) => setDescriptionTitledata(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Short Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={shortdescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Detailed Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Detailed Description"
                    className={styles.form_input}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
                <Col md={12} className="multi-images">
                  <label className="fw-bold">
                    Gallery Slider Images{" "}
                    <small className="text-success small-msg">
                      (Max. Size 818 X 818 px)
                    </small>
                  </label>
                  <div className="d-flex align-items-center">
                    {/* <ImgCrop
                      grid
                      aspect={818 / 818}
                      styles={{ width: "120px" }}
                    > */}
                    <Upload
                      maxCount={1}
                      onChange={GalleryOnChange}
                      action={Base_URL}
                      listType="picture-card"
                      defaultFileList={[...fileList]}
                    >
                      <>
                        <div className="file_upload_div">
                          <span>{UploadIcon}</span>
                          <p>Click Here to Upload</p>
                        </div>
                      </>
                    </Upload>
                    {/* </ImgCrop> */}
                    <ul className={`${styles.multi_image_preview} flex-wrap`}>
                      {imageGalleryArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => handelDeleteImgae(item)}
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <label className="fw-bold"> Middle Description</label>
                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={middledescription}
                    onChange={setMiddleDescription}
                  />
                </Col>
                {/* <Col md={12}>
                  <label className="fw-bold">Middle Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Detailed Description"
                    className={styles.form_input}
                    value={middledescription}
                    onChange={(e) => setMiddleDescription(e.target.value)}
                  />
                </Col> */}
                <Col md={6}>
                  <label className="fw-bold">Phone Number</label>
                  <Form.Control
                    type="text"
                    placeholder="434435355"
                    className={styles.form_input}
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </Col>{" "}
                <Col md={6}>
                  <label className="fw-bold"> Direction URL</label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={styles.form_input}
                    value={url}
                    onChange={(e) => seturl(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Reservation Booking Link </label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={styles.form_input}
                    value={booking}
                    onChange={(e) => setBooking(e.target.value)}
                  />
                </Col>
                <hr /> <label className="fw-bold">Enter Venue</label>
                <Row gutter={[24, 0]}>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      name="indoor"
                      placeholder="indoor guest"
                      className={styles.form_input}
                      value={indoor}
                      onChange={(e) => setIndoor(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      name="outdoor"
                      placeholder="outdoor guest"
                      className={styles.form_input}
                      value={outdoor}
                      onChange={(e) => setOutdoor(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Form.Control
                      type="text"
                      name="reception"
                      placeholder="reception guest"
                      className={styles.form_input}
                      value={reception}
                      onChange={(e) => setReception(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      value={metaTitle}
                      className={styles.form_input}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      value={metaDescription}
                      placeholder="Meta Description"
                      className={styles.form_input}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    {" "}
                    <FormControlLabel
                      className="switch_admin"
                      control={<Switch />}
                      label="Show On Homepage"
                    />
                  </Col>
                </Row>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleupdateRestaurantDetails}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
