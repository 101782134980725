import React, { useEffect, useState } from "react";
import styles from "./../experience.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";

import { Offers_URL } from "../../../../serverUrl";
import { editIcon } from "../../../../utils/svg.file";
import { ShowExperienceBannerList } from "../../../../api/adminPanel/giftCardApi";

export default function ManageExperienceLandingPage() {
  const navigate = useNavigate();
  const [bannerList,setbannerList] = useState([])

  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    {
      field: "image",
      height: 150,
      width: 700,
      renderCell: (cellValues) => {
        return (
          <>
            <img
              src={`${Offers_URL}${cellValues.row.image}`}
              className={styles.table_img}
            />
          </>
        );
      },
    },
    // { field: "description", headerName: "Description", width: 400 },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(
                  `/edit-experience-landing-page/${cellValues.row.id}`
                );
              }}
            >
              {editIcon}
            </span>
          </>
        );
      },
    },
  ];
  const handelExperienceBanner = async () => {
    const response = await ShowExperienceBannerList();
    if (response.remote === "success") {
      setbannerList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handelExperienceBanner()
  }, []);
  let rows = [];

  bannerList?.map((item, index) => {
    rows.push({
      id: item.id,
      image: item.banner,
      // description: item.description,
      // title: item.title,
    });
  });
  // let rows = [{ id: 1, image: "dsd", description: "precheck" }];
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Stories Page Banner</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-experience-landing-page")}
              >
                Add
              </Button>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
    </>
  );
}
