import React, { useEffect, useState } from "react";
import styles from "./../cruise.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon } from "../../../../utils/svg.file";

import { Featured_URL, Offers_URL } from "../../../../serverUrl";
import DeleteModal from "../../../component/modal/deleteModal";
import swal from "sweetalert";
import {
  showCruiseLandingBanner,
  showRestaurantBanner,
  showRetreatBanner,
} from "../../../../api/adminPanel/giftCardApi";

export default function ManageCruiseLandingPage() {
  const navigate = useNavigate();
  const [bannerList, setbannerList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handelCruiseBanner = async () => {
    const response = await showCruiseLandingBanner();
    if (response.remote === "success") {
      setbannerList(response.data.data);
    } else {
    }
  };

  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    {
      field: "image",
      height: 150,
      width: 300,
      renderCell: (cellValues) => {
        return (
          <>
            <img
              src={`${Offers_URL}${cellValues.row.image}`}
              className={styles.table_img}
            />
          </>
        );
      },
    },
    { field: "title", headerName: "Title", width: 400 },
    { field: "shortDescription", headerName: "Description", width: 400 },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(
                  `/edit-cruise-landing-page/${cellValues.row.id}`
                );
              }}
            >
              {editIcon}
            </span>
           
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handelCruiseBanner();
  }, []);


  let rows = [];

  bannerList?.map((item, index) => {
    rows.push({
      id: item.id,
      image: item.banner,
      shortDescription: item.deckPlan,
      title: item.title,
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Landing Page Banner</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-cruise-landing-page")}
              >
                Add
              </Button>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
      {/* <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      /> */}
    </>
  );
}
