import React, { useEffect } from "react";
import styles from "../enquiry.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { showCruiseEnquiryList } from "../../../../api/adminPanel/exclusiveOfferApi";
import { useState } from "react";
import moment from "moment";

export default function CruiseEnquiryList() {
  const navigate = useNavigate();
  const [enquiryList,setEnquiryList] = useState()
  const columns = [
    { field: "id", headerName: "Date", width: 200 },
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", width: 160 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phoneNumber", headerName: "Phone Number", width: 160 },

    { field: "eventDate", headerName: "Date of Event", width: 150 },
    { field: "description", headerName: "Message", width: 250 },

  ];
  const handlecruiseEnquiryList = async () => {
    const response = await showCruiseEnquiryList();
    if (response.remote === "success") {
      setEnquiryList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handlecruiseEnquiryList()
  }, [])
  
  const rows = [];

  enquiryList?.forEach((item)=>{
    rows.push({
        id:moment(item.created_at).format('MMMM Do YYYY, h:mm:ss'),
        firstName:item.firstName,
        lastName:item.lastName,
        email:item.email,
        description:item.description,
        eventDate:item.date,
        phoneNumber:item.phone,
    })
  })
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Cruise Enquiry List</h3>
              </div>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
    </>
  );
}
