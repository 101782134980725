import React from "react";
import styles from "../job.module.css";
import Table from "../../../component/table";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteIcon, editIcon, viewIcon } from "../../../../utils/svg.file";
import { getJobApplierList } from "../../../../api/adminPanel/jobapi";
import { useEffect } from "react";
import { useState } from "react";
import { Block } from "@mui/icons-material";
import moment from "moment";
import DeleteModal from "../../../component/modal/deleteModal";
import swal from "sweetalert";
import { deleteFeaturedStory, deletejobList } from "../../../../api/adminPanel/featuredStory";

export default function ManageJobApplier() {
  const [jobApplierList, setJobApplierList] = useState([]);
  const [showValue, setShowValue] = useState("");
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");

  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = async (id) => {
    const response = await deletejobList(id);

    if (response.remote === "success") {
      hideDeleteModal();
      handleShowApplierList()
      swal("Deleted Successfully!");
    } else {
    }
  };
  const handleShowApplierList = async () => {
    const response = await getJobApplierList();

    if (response.remote === "success") {
      setJobApplierList(response.data.data);
    } else {
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "SNo.",
      width: 70,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/view-job-applier-detail/${cellValues.row.id}`);
              }}
            >
              {cellValues.row.id}
            </span>
          </>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/view-job-applier-detail/${cellValues.row.id}`);
              }}
            >
              {cellValues.row.date}
            </span>
          </>
        );
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/view-job-applier-detail/${cellValues.row.id}`);
              }}
            >
              {cellValues.row.firstName}
            </span>
          </>
        );
      },
    },
    {
      field: "lastName",
      headerName: "Lat Name",
      width: 160,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/view-job-applier-detail/${cellValues.row.id}`);
              }}
            >
              {cellValues.row.lastName}
            </span>
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 350,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/view-job-applier-detail/${cellValues.row.id}`);
              }}
            >
              {cellValues.row.email}
            </span>
          </>
        );
      },
    },
    {
      field: "address1",
      headerName: "Address1",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/view-job-applier-detail/${cellValues.row.id}`);
              }}
            >
              {cellValues.row.address1}
            </span>
          </>
        );
      },
    },
    {
      field: "address2",
      headerName: "Address2",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/view-job-applier-detail/${cellValues.row.id}`);
              }}
            >
              {cellValues.row.address2}
            </span>
          </>
        );
      },
    },
    {
      field: "postCode",
      headerName: "Post Code",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/view-job-applier-detail/${cellValues.row.id}`);
              }}
            >
              {cellValues.row.postCode}
            </span>
          </>
        );
      },
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/view-job-applier-detail/${cellValues.row.id}`);
              }}
            >
              {cellValues.row.country}
            </span>
          </>
        );
      },
    },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              onClick={() => {
                navigate(`/view-job-applier-detail/${cellValues.row.id}`);
              }}
            >
              {viewIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleShowApplierList();
  }, []);
  const handleSearch = (data) => {
    if (data) {
      let searchData = jobApplierList.filter(
        (entry) =>
          entry.first_name?.toLoweCase().includes(data.toLoweCase()) ||
          entry.email?.toLoweCase().includes(data.toLoweCase()) ||
          entry.last_name?.toLoweCase().includes(data.toLoweCase()) ||
          entry.location?.toLoweCase().includes(data.toLoweCase())
      );
      setSearchedList(searchData);
    }
  };

  const filteredList = () => {
    if (searchedData) {
      return searchedList;
    } else {
      return jobApplierList;
    }
  };
  let rows = [];
  let newstate = "";
  filteredList().map((item) => {
    if (Number(item.state) === 2) {
      newstate = "Singapore";
    } else {
      newstate = "Malasia";
    }
    const date = moment(item.created_at).format("Do MMMM YYYY");
    rows.push({
      id: item.id,
      date: date,
      address1: item.address_1,
      address2: item.address_2,
      country: item.country,
      location: item.location,
      email: item.email,
      firstName: item.first_name,
      lastName: item.last_name,
      postCode: item.postcode,
      country: item.country,

      action: (
        <>
          <Button>View</Button>
        </>
      ),
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Job Applier List</h3>
              </div>
            </div>
            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select
                  className="form-control ms-1 me-1"
                  onChange={(e) => setShowValue(e.target.value)}
                >
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>

                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchedData(e.target.value);
                }}
              />
            </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
