import React, { useState } from "react";
import styles from "./../crms.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { Divider } from "@mui/material";
import { deleteIcon } from "../../../../utils/svg.file";
import { addContactDetail } from "../../../../api/adminPanel/contactUs";
import swal from "sweetalert";
export default function AddContactUs() {
  const navigate = useNavigate();
  const [contactField, setContactField] = useState([
    {
      category: "",
      location: "",
      firstMob: "",
      secondMob: "",
      thirdMob: "",
      address: "",
      email: "",
      f_status: "",
      s_status: "",
      t_status: "",
      order: "",
    },
  ]);
  const handleFormChange = (index, event) => {
    let data = [...contactField];
    if (event.target.name === "s_status") {
      data[index][event.target.name] = event.target.checked;
    } else if (event.target.name === "f_status") {
      data[index][event.target.name] = event.target.checked;
    } else if (event.target.name === "t_status") {
      data[index][event.target.name] = event.target.checked;
    } else {
      data[index][event.target.name] = event.target.value;
    }
    setContactField(data);
  };

  const addFields = () => {
    let newfield = {
      category: "",
      location: "",
      firstMob: "",
      secondMob: "",
      thirdMob: "",
      address: "",
      email: "",
      f_status: "",
      s_status: "",
      t_status: "",
      order: "",
    };

    setContactField([...contactField, newfield]);
  };
  const removeFields = (index) => {
    let data = [...contactField];
    data.splice(index, 1);
    setContactField(data);
  };

  const handleSubmitContactDetail = async () => {
    const contactDetailPayload = {
      // category: category,
      contactField: contactField,
    };
    const response = await addContactDetail(contactDetailPayload);

    if (response.remote === "success") {
      swal("Contact detail succesfully Added!");
      navigate("/manage-contact-us");
    } else {
    }
  };
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Contact Us</h3>
              </div>
              <div className="">
                <Button
                  onClick={() => navigate(-1)}
                  className={`me-2 ${styles.main_button}`}
                >
                  Back
                </Button>
                <Button className={styles.main_button} onClick={addFields}>
                  Add Another Details
                </Button>
              </div>
            </div>
            <Form>
              <div className="contact_form">
                {contactField.map((item, index) => {
                  return (
                    <>
                      <Row>
                        <Col md={11}>
                          <Row gutter={[24, 0]} key={index}>
                            <Col md={6}>
                              {" "}
                              <Form.Group className="">
                                <Form.Select
                                  name="category"
                                  className={styles.form_input}
                                  defaultValue={item.category}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                >
                                  <option selected>Select Category</option>

                                  <option value="2">Hotel & Resorts</option>
                                  <option value="3">
                                    Tamarind Restaurants
                                  </option>
                                  <option value="1">Headquarters</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              {" "}
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Location"
                                className={styles.form_input}
                                defaultValue={item.location}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              />
                            </Col>
                            <Col md={6}>
                              {" "}
                              <Form.Control
                                type="text"
                                name="address"
                                placeholder="Address"
                                className={styles.form_input}
                                defaultValue={item.address}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              />
                            </Col>
                            <Col md={4}>
                              {" "}
                              <Form.Control
                                type="text"
                                name="firstMob"
                                placeholder="First Mobile Number"
                                className={styles.form_input}
                                defaultValue={item.firstMob}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              />
                            </Col>
                            <Col md={2}>
                              <div className="w-100 justify-content-end align-items-baseline d-flex">
                                <p
                                  className="mb-0 me-2"
                                  style={{
                                    fontSize: "11px",
                                    lineHeight: "10px",
                                  }}
                                >
                                  Whatsapp Enable
                                </p>
                                <input
                                  type="checkbox"
                                  name="f_status"
                                  placeholder=""
                                  className={styles.check_input}
                                  defaultValue={item.f_status}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              {" "}
                              <Form.Control
                                type="text"
                                name="secondMob"
                                placeholder="Second Mobile Number"
                                className={styles.form_input}
                                defaultValue={item.secondMob}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              />
                            </Col>
                            <Col md={2}>
                              <div className="w-100 justify-content-end align-items-baseline d-flex">
                                <p
                                  className="mb-0 me-2"
                                  style={{
                                    fontSize: "11px",
                                    lineHeight: "10px",
                                  }}
                                >
                                  Whatsapp Enable
                                </p>
                                <input
                                  type="checkbox"
                                  name="s_status"
                                  placeholder=""
                                  className={styles.check_input}
                                  defaultValue={item.s_status}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              {" "}
                              <Form.Control
                                type="text"
                                name="thirdMob"
                                placeholder="Third Mobile Number"
                                className={styles.form_input}
                                defaultValue={item.thirdMob}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              />
                            </Col>
                            <Col md={2}>
                              <div className="w-100 justify-content-end align-items-baseline d-flex">
                                <p
                                  className="mb-0 me-2"
                                  style={{
                                    fontSize: "11px",
                                    lineHeight: "10px",
                                  }}
                                >
                                  Whatsapp Enable
                                </p>
                                <input
                                  type="checkbox"
                                  name="t_status"
                                  placeholder=""
                                  className={styles.check_input}
                                  defaultValue={item.t_status}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              {" "}
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder="Email"
                                className={styles.form_input}
                                defaultValue={item.email}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              />
                            </Col>
                            <Col md={6}>
                              {" "}
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder="Order no."
                                className={styles.form_input}
                                defaultValue={item.order}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={1}>
                          {" "}
                          <span
                            className={styles.delete_icon_btn}
                            onClick={() => removeFields(index)}
                          >
                            {deleteIcon}
                          </span>
                        </Col>
                      </Row>

                      <hr />
                    </>
                  );
                })}
                <Row>
                  <Col md={12} className="text-center  mt-5">
                    <Button
                      className={styles.main_button}
                      onClick={() => {
                        // navigate("/manage-contact-us");
                        handleSubmitContactDetail();
                      }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
