import React, { useEffect, useState } from "react";
import styles from "./giftCard.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { addJobDetail, showCategory } from "../../../api/user";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { editJobDetail, updateJobDetail } from "../../../api/adminPanel/jobapi";
import swal from "sweetalert";
import { viewApplierDetail } from "../../../api/adminPanel/giftCardApi";

export default function ViewGiftCardApplierList() {
  const [applierList, setApplierList] = useState([]);

  const stateList = [
    { id: 1, name: "Malaysia" },
    { id: 2, name: "Singapore" },
  ];
  let { id } = useParams();
  const navigate = useNavigate();
  const handleGetGiftApplierDetail = async (id) => {
    const response = await viewApplierDetail(id);
    if (response.remote === "success") {
      setApplierList(response.data.data);

    } else {
    }
  };

  useEffect(() => {
    handleGetGiftApplierDetail(id);
  }, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>View Gift Applier Detail</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">First Name</label>

                  <p>{applierList.f_name}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Last Name</label>

                  <p>{applierList.l_name}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Location</label>

                  <p>{applierList.location}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Gift Id</label>

                  <p>{applierList.giftId}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Type</label>

                  <p>{applierList.type}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Quantity</label>

                  <p>{applierList.quantity}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Receiver Email</label>

                  <p>{applierList.reciver_email}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Receiver Phone</label>

                  <p>{applierList.reciver_phone}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Sender Email</label>

                  <p>{applierList.sender_email}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Sender Name</label>
                  <p>{applierList.sender_name}</p>
                </Col>

                <Col md={6}>
                  <label className="fw-bold">Sender Phone</label>
                  <p>{applierList.sender_phone}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Message</label>

                  <p>{applierList.message}</p>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
