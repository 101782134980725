import React, { useEffect } from "react";
import styles from "./giftCard.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../component/table";
import swal from "sweetalert";
import { deleteIcon, editIcon, viewIcon } from "../../../utils/svg.file";
import {
  showContactList,
  deleteContactDetail,
} from "../../../api/adminPanel/contactUs";
import { useState } from "react";
import DeleteModal from "../../component/modal/deleteModal";
import {
  deleteAccolades,
  showAccoladesList,
} from "../../../api/adminPanel/accoladesApi";
import {
  deleteGiftCard,
  showGiftList,
} from "../../../api/adminPanel/giftCardApi";
export default function ManageGiftCard() {
  const navigate = useNavigate();
  const [giftCardList, setGiftCardList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = async (id) => {
    const response = await deleteGiftCard(id);
    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
    } else {
    }
  };
  const handleShowGiftList = async (id) => {
    const response = await showGiftList(id);
    if (response.remote === "success") {
      setGiftCardList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleShowGiftList();
  }, []);
  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    { field: "heading", headerName: "Heading", width: 200 },
    { field: "description", headerName: "Description", width: 200 },
    {
      field: "category",
      width: 300,
      renderCell: (cellValues) => {
        return (
          <>
            <ul className={styles.accolades_details}>
              {/* {cellValues.row.type} */}
              {cellValues.row.type.map((item, index) => {
                return (
                  <>
                    <li key={index}>{item.category}</li>
                  </>
                );
              })}
            </ul>
          </>
        );
      },
    },
    {
      field: "location",
      width: 300,
      renderCell: (cellValues) => {
        return (
          <>
            <ul className={styles.accolades_details}>
              {/* {cellValues.row.location} */}
              {cellValues.row.location.map((item, index) => {
                return (
                  <>
                    <li key={index}>{item.location}</li>
                  </>
                );
              })}
            </ul>
          </>
        );
      },
    },
    {
      field: "value",
      width: 300,
      renderCell: (cellValues) => {
        return (
          <>
            <ul className={styles.accolades_details}>
              {/* {cellValues.row.value} */}
              {cellValues.row.value.map((item, index) => {
                return (
                  <>
                    <li key={index}>{item.text}</li>
                  </>
                );
              })}
            </ul>
          </>
        );
      },
    },
    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            {/* <span
              className={styles.view_icon_btn}
              onClick={() =>
                navigate(`/view-accolades/${cellValues.row.id}`)
              }
            >
              {viewIcon}
            </span> */}
            <span
              className={styles.edit_icon_btn}
              onClick={() =>
                navigate(`/edit-gift-card/${cellValues.row.id}`)
              }
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];

  let rows = [];

  giftCardList?.map((item, index) => {
    rows.push({
      id: item.id,
      heading: item.heading,
      description: item.discription,
      type: JSON.parse(item.type),
      location: JSON.parse(item.location),
      value: JSON.parse(item.value),

      // category: JSON.parse(item.category),
      // location: item.location,
      // detail: JSON.parse(item.text),
    });
  });

  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Gift Cards</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-gift-card")}
              >
                Add Gift Card
              </Button>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>

      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
