import React, { useEffect, useState } from "react";
import styles from "./../retreats.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormControlLabel, Switch, imageListClasses } from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import {
  AddRestaurantDetails,
  AddRetreatData,
  CelebrationsList,
  deleteImage,
  OffersList,
  showRestaurantName,
  showRetreatName,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import {
  UploadIcon,
  addInputIcon,
  crossRed,
  deleteIcon,
} from "../../../../utils/svg.file";
import {
  SendToMobile,
  SettingsRemoteOutlined,
  UploadOutlined,
} from "@mui/icons-material";
import Base from "antd/es/typography/Base";
import { addGallerySliderImage } from "../../../../api/adminPanel/restaurantApi";
import { MultiSelect } from "react-multi-select-component";
export default function AddRetreats() {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [restaurant, setRestaurant] = useState("");
  const [titledata, setTitledata] = useState("");
  const [descriptiontitledata, setDescriptionTitledata] = useState("");
  const [categorydata, setCategorydata] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [middledescription, setMiddleDescription] = useState("");
  const [detaileddescription, setDetailedDescription] = useState("");
  const [diningTitle, setDiningTitle] = useState("");
  const [accomodationDescription, setAccomodationDescription] = useState("");
  const [diningDescription, setDiningDescription] = useState("");
  const [wellnessDescription, setWellnessDescription] = useState("");
  const [url, seturl] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [imageBanner, setImageBanner] = useState("");
  const [imageGalleryArray, setImageGalleryArray] = useState([]);
  const [imageDiningGalleryArray, setImageDiningGalleryArray] = useState([]);
  const [celebrationListArray, setCelebrationListArray] = useState([]);
  const [imageGalleryAccomodationArray, setImageGalleryAccomodationArray] =
    useState([]);
  const [restaurantName, setRestaurantName] = useState([]);
  const [celebrationList, setCelebrationList] = useState([]);
  const [manageoffersList, setOffersList] = useState([]);
  const [mobile, setMobile] = useState("");
  const [imgHome, setImgBanner] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileListCard, setFileListCard] = useState([]);
  const [fileListBanner, setFileListBanner] = useState([]);
  const [imageGallery, setImageGallery] = useState("");
  const [retreatCategory, setRetreatCategory] = useState("");
  const [mapDescription, setMapDescription] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [categoryPlace, setCategoryPlace] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [exclusiveOfferArray, setExclusiveOfferArray] = useState([]);
  const [location, setLocation] = useState("");
  const [maPIframe, setMaPIframe] = useState("");
  const [state, setState] = useState("");
  const [booking, setBooking] = useState("");
  const [imageWellnessArray, setImageWellnessArray] = useState([]);
  const [indoor, setIndoor] = useState("");
  const [outdoor, setOutdoor] = useState("");
  const [reception, setReception] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [innerTitle, setInnerTitle] = useState("");
  const [wellnessBlocksField, setWellnessBlocksField] = useState([
    {
      wellnessTitle: "",
      wellnessDescription: "",
    },
  ]);
  const handleWellnessBlock = (index, event) => {
    let data = [...wellnessBlocksField];
    data[index][event.target.name] = event.target.value;
    setWellnessBlocksField(data);
  };
  const addWellnessBlock = () => {
    let newWellnessBlockField = {
      wellnessTitle: "",
      wellnessDescription: "",
    };

    setWellnessBlocksField([...wellnessBlocksField, newWellnessBlockField]);
  };
  const removeWellnessBlockField = (index) => {
    let data = [...wellnessBlocksField];
    data.splice(index, 1);
    setWellnessBlocksField(data);
  };
  const options = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry", disabled: true },
  ];
  const categoryList = [];
  restaurantName?.map((item, index) => {
    categoryList.push({
      id: item.id,
      category: item.title,
    });
  });
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const onChange = async ({ fileList }) => {
    setCardImage(fileList[0].originFileObj);
  };
  const BannerOnChange = async ({ fileList }) => {
    setImageBanner(fileList[0].originFileObj);
  };
  const GalleryOnChange = async ({ fileList }) => {
    setFileList(fileList);
    // setImageGallery(fileList[0].originFileObj);
    const addGalleryPayload = {
      galleryImage: fileList[0].originFileObj,
    };
    const response = await addGallerySliderImage(addGalleryPayload);

    if (response.remote === "success") {
      swal("Galary Image Successfully Added");
      setImageGalleryArray([...imageGalleryArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };

  const WellnessOnChange = async ({ fileList }) => {
    setFileList(fileList);
    // setImageGallery(fileList[0].originFileObj);
    const addGalleryPayload = {
      galleryImage: fileList[0].originFileObj,
    };
    const response = await addGallerySliderImage(addGalleryPayload);

    if (response.remote === "success") {
      swal("Galary Image Successfully Added");
      setImageWellnessArray([...imageWellnessArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };
  const GalleryAccomodationOnChange = async ({ fileList }) => {
    setFileList(fileList);

    // setImageGalleryAccomodationArray(fileList[0].originFileObj);
    const addGalleryPayload = {
      galleryImage: fileList[0].originFileObj,
    };
    const response = await addGallerySliderImage(addGalleryPayload);

    if (response.remote === "success") {
      swal("Image Successfully Added");
      setImageGalleryAccomodationArray([
        ...imageGalleryAccomodationArray,
        response.data.data,
      ]);
      setFileList([]);
    } else {
    }
  };

  const GalleryDiningOnChange = async ({ fileList }) => {
    setFileList(fileList);
    // setImageDiningGalleryArray(fileList[0].originFileObj);
    const addGalleryPayload = {
      galleryImage: fileList[0].originFileObj,
    };
    const response = await addGallerySliderImage(addGalleryPayload);

    if (response.remote === "success") {
      swal("Image Successfully Added");
      setImageDiningGalleryArray([
        ...imageDiningGalleryArray,
        response.data.data,
      ]);
      setFileList([]);
    } else {
    }
  };

  const handleRestaurantName = async () => {
    const response = await showRetreatName();

    if (response.remote === "success") {
      setRestaurantName(response.data.data);
    } else {
    }
  };
  const handleCelebrationList = async () => {
    const response = await CelebrationsList();

    if (response.remote === "success") {
      setCelebrationList(response.data.data);
    } else {
    }
  };
  const handleOffersList = async () => {
    const response = await OffersList();

    if (response.remote === "success") {
      setOffersList(response.data.data);
    } else {
    }
  };
  const handleRetreatsDetails = async () => {
    if(mapDescription){

      const retreatData = {
        retreatCategory: state,
        location: location,
        title: titledata,
        shortdescription: shortdescription,
        detaileddescription: detaileddescription,
        cardImage: cardImage,
        imageBanner: imageBanner,
        imageGallery: imageGalleryArray,
        longitude: longitude,
        latitude: latitude,
        mapDescription: mapDescription,
        imageGalleryAccomodationArray: imageGalleryAccomodationArray,
        imageWellnessArray: imageWellnessArray,
        wellnessDescription: wellnessBlocksField,
        accomodationDescription: accomodationDescription,
        imageDiningGalleryArray: imageDiningGalleryArray,
        diningDescription: diningDescription,
        celebrationListArray: celebrationListArray,
        exclusiveOfferArray: exclusiveOfferArray,
        categoryPlace: categoryPlace,
        maPIframe: maPIframe,
        booking: booking,
        innerTitle: innerTitle,
        diningTitle: diningTitle,
        indoor: indoor,
        outdoor: outdoor,
        reception: reception,
        metaTitle: metaTitle,
        metaDescription: metaDescription,
      };
      const response = await AddRetreatData(retreatData);
  
      if (response.remote === "success") {
       
        swal("Data Added Successfully");
        navigate("/manage-retreats");
      } else {
      }
    }else{
      swal("Please Fill Required Fields");
    }
  };

  const handelDeleteImgae = async (image) => {
    const newImageArray = imageGalleryArray.filter(
      (imageGalleryArray) => imageGalleryArray !== image
    );
    setImageGalleryArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };
  const handelAccomodationDeleteImgae = async (image) => {
    const newImageArray = imageGalleryAccomodationArray.filter(
      (imageGalleryAccomodationArray) => imageGalleryAccomodationArray !== image
    );
    setImageGalleryAccomodationArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };
  const handelDiningDeleteImgae = async (image) => {
    const newImageArray = imageDiningGalleryArray.filter(
      (imageDiningGalleryArray) => imageDiningGalleryArray !== image
    );
    setImageDiningGalleryArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };
  const handelwellnessDeleteImgae = async (image) => {
    const newImageArray = imageWellnessArray.filter(
      (imageWellnessArray) => imageWellnessArray !== image
    );
    setImageWellnessArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };

  useEffect(() => {
    handleRestaurantName();
    handleCelebrationList();
    handleOffersList();
  }, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Retreats</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Select Country</label>
                  <Form.Group className="">
                    <Form.Select
                      className={styles.form_input}
                      onChange={(e) => setState(e.target.value)}
                    >
                      <option selected>Choose Country </option>

                      <option value="malaysia">Malaysia</option>
                      <option value="singapore">Singapore</option>
                    </Form.Select>
                  </Form.Group>
                  {/* <label className="fw-bold">Select Retreats Category</label>
                  <Form.Group className="">
                    <Form.Select className={styles.form_input} onChange={(e)=>setRetreatCategory(e.target.value)}>
                      <option selected>Choose Category </option>
                      {categoryList.map((item, index) => {
                        return (
                          <>
                            <option key={index} value={item.id}>
                              {item.category}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                  </Form.Group> */}
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setTitledata(e.target.value)}
                  />
                  <label className="fw-bold">Location</label>
                  <Form.Control
                    type="text"
                    placeholder="Location"
                    className={styles.form_input}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <label className="fw-bold">Category</label>
                  <Form.Control
                    type="text"
                    placeholder="Category"
                    className={styles.form_input}
                    onChange={(e) => setCategoryPlace(e.target.value)}
                  />
                  <label className="fw-bold">Intro Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Intro Title"
                    className={styles.form_input}
                    onChange={(e)=>setInnerTitle(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  {" "}
                  <label className="fw-bold mb-2">
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 818 X 818 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={818 / 818}>
                    <Upload
                      maxCount={1}
                      onChange={onChange}
                      action={Base_URL}
                      listType="picture-card"
                      defaultFileList={[...fileList]}
                    >
                      {cardImage.length < 1 && (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                  <label className="fw-bold mb-2">
                    Banner Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={1219 / 757}>
                    <Upload
                      maxCount={1}
                      onChange={BannerOnChange}
                      action={Base_URL}
                      listType="picture-card"
                      defaultFileList={[...fileList]}
                      className="banner_single"
                    >
                      {imageBanner.length < 1 && (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                  <label className="fw-bold">Short Description</label>
                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setShortDescription(e.target.value)}
                  />
                </Col>
                {/* <Col md={3} className="multi-images">
                  <label className="fw-bold">Banner Image</label>
                  <ImgCrop grid aspect={1 / 3}>
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture"
                      defaultFileList={[...fileList]}
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </ImgCrop>
                </Col> */}
                <Col md={12}>
                  <label className="fw-bold">Detailed Description</label>

                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder="Detailed Description"
                    className={styles.form_input}
                    onChange={setDetailedDescription}
                  />
                </Col>
                <Col
                  md={12}
                  className={`multi-images gallery-images mb-2 ${styles.gallery_multiple}`}
                >
                  <label className="fw-bold">Gallery Images </label>
                  <div className="d-flex">
                    {/* <ImgCrop aspect={533 / 666} styles={{ width: "120px" }}> */}
                    <div>
                      {" "}
                      <Upload
                        maxCount={1}
                        onChange={GalleryOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                      <Button className={styles.main_button}>Delete</Button>
                    </div>
                    {/* </ImgCrop> */}
                    <ul className={`${styles.multi_image_preview} flex-wrap`}>
                      {imageGalleryArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                {/* <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => handelDeleteImgae(item)}
                                >
                                  {crossRed}
                                </span> */}
                                <input
                                  type="checkbox"
                                  className={styles.select_gallery}
                                />
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <hr></hr>
                <h4 className="mb-3 ">Map Section</h4>
                <Col md={12}>
                  <label className="fw-bold">Map</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Map I-Frame"
                    className={styles.form_input}
                    onChange={(e) => setMaPIframe(e.target.value)}
                  />
                </Col>
                {/* <Col md={6}>
                  <label className="fw-bold">Latitude</label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={styles.form_input}
                    onChange={(e) => setLatitude(e.target.value)}
                  />
                </Col>{" "}
                <Col md={6}>
                  <label className="fw-bold">Longitude</label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={styles.form_input}
                    onChange={(e) => setLongitude(e.target.value)}
                  />
                </Col> */}
                <Col md={12}>
                  <label className="fw-bold">Map Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Map Description"
                    className={styles.form_input}
                    onChange={(e) => setMapDescription(e.target.value)}
                  />
                </Col>
                <hr></hr>
                <h4>Accomodation Section</h4>
                <Col md={12} className="multi-images">
                  <label className="fw-bold">
                    Banner Slider Images{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <div className="d-flex align-items-center">
                    <ImgCrop
                      grid
                      aspect={1219 / 757}
                      styles={{ width: "120px" }}
                    >
                      <Upload
                        maxCount={1}
                        onChange={GalleryAccomodationOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                    <ul className={styles.multi_image_preview}>
                      {imageGalleryAccomodationArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() =>
                                    handelAccomodationDeleteImgae(item)
                                  }
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <label className="fw-bold"> Description</label>

                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={setAccomodationDescription}
                  />
                </Col>
                <hr></hr>
                <h4>Dining Section</h4>
                <Col md={12}>
                  <label className="fw-bold">Title </label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={styles.form_input}
                    onChange={(e) => setDiningTitle(e.target.value)}
                  />
                </Col>
                <Col md={12} className="multi-images">
                  <label className="fw-bold">
                    Banner Slider Images{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <div className="d-flex align-items-center">
                    <ImgCrop
                      grid
                      aspect={1219 / 757}
                      styles={{ width: "120px" }}
                    >
                      <Upload
                        maxCount={1}
                        onChange={GalleryDiningOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                    <ul className={styles.multi_image_preview}>
                      {imageDiningGalleryArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => handelDiningDeleteImgae(item)}
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <label className="fw-bold"> Description</label>

                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder=" Description"
                    className={styles.form_input}
                    onChange={setDiningDescription}
                  />
                </Col>
                {/* <Col md={6}>
                  <label className="fw-bold">Celebration & Event List</label>
                  <MultiSelect
                    options={celebrationList}
                    value={celebrationListArray}
                    onChange={setCelebrationListArray}
                    labelledBy="Select"
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Offers List</label>
                  <MultiSelect
                    options={manageoffersList}
                    value={exclusiveOfferArray}
                    onChange={setExclusiveOfferArray}
                    labelledBy="Select"
                  />
                </Col> */}
                <hr></hr>
                <h4>Wellness Section</h4>
                {/* <Col md={12}>
                  <label className="fw-bold">Title </label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={styles.form_input}
                  />
                </Col> */}
                <Col md={12} className="multi-images">
                  <label className="fw-bold">
                    Banner Slider Images{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <div className="d-flex align-items-center">
                    <ImgCrop
                      grid
                      aspect={1219 / 757}
                      styles={{ width: "120px" }}
                    >
                      <Upload
                        maxCount={1}
                        onChange={WellnessOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                    <ul className={styles.multi_image_preview}>
                      {imageWellnessArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() =>
                                    handelwellnessDeleteImgae(item)
                                  }
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                {/* <Col md={12}>
                  <label className="fw-bold"> Description</label>

                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder=" Description"
                    className={styles.form_input}
                    onChange={setWellnessDescription}
                  />
                </Col> */}
                <Col md={12}>
                  <label className="fw-bold">Wellness Block</label>
                  {wellnessBlocksField.map((item, index) => {
                    return (
                      <>
                        <Row gutter={[24, 0]} key={index}>
                          <Col md={10}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="wellnessTitle"
                              placeholder=" Title"
                              className={styles.form_input}
                              defaultValue={item.wellnessTitle}
                              onChange={(event) =>
                                handleWellnessBlock(index, event)
                              }
                            />
                          </Col>

                          <Col md={2} className="text-end">
                            <span
                              className={styles.edit_icon_btn}
                              onClick={() => addWellnessBlock(index)}
                            >
                              {addInputIcon}
                            </span>
                            {wellnessBlocksField.length > 1 ? (
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() => removeWellnessBlockField(index)}
                              >
                                {deleteIcon}
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md={12}>
                            <textarea
                              placeholder="Description"
                              name="wellnessDescription"
                              defaultValue={item.wellnessDescription}
                              onChange={(event) =>
                                handleWellnessBlock(index, event)
                              }
                              className="form-control"
                            ></textarea>
                          </Col>
                        </Row>

                        <hr />
                      </>
                    );
                  })}
                </Col>
                <hr></hr>
                <Col md={12}>
                  <label className="fw-bold">Reservation Booking Link </label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={styles.form_input}
                    onChange={(e) => setBooking(e.target.value)}
                  />
                </Col>
                <hr /> <label className="fw-bold">Enter Venue</label>
                <Row gutter={[24, 0]}>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      name="indoor"
                      placeholder="indoor guest"
                      className={styles.form_input}
                      onChange={(e) => setIndoor(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      name="outdoor"
                      placeholder="outdoor guest"
                      className={styles.form_input}
                      onChange={(e) => setOutdoor(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Form.Control
                      type="text"
                      name="reception"
                      placeholder="reception guest"
                      className={styles.form_input}
                      onChange={(e) => setReception(e.target.value)}
                    />
                  </Col>
                  <hr /> <label className="fw-bold">Enter Meta</label>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      className={styles.form_input}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      className={styles.form_input}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    {" "}
                    <FormControlLabel
                      className="switch_admin"
                      control={<Switch defaultChecked />}
                      label="Show On Homepage"
                    />
                  </Col>
                </Row>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleRetreatsDetails}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
