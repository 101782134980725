import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function DeleteModal(props) {
  return (
    <>
      <Modal show={props.showDeleteModal} onHide={props.hideDeleteModal}>
        <Modal.Body className="text-center">
          <h4> Are You Sure You want to Delete ?</h4>
          <div className="w-100 mt-3">
            {" "}
            <Button variant="secondary" onClick={props.hideDeleteModal}>
              Close
            </Button>
            <Button
              className="ms-4"
              variant="danger"
              onClick={() => {
                props.handleDelete(props.deleteId);
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
