import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import styles from "./login.module.css";
import IMAGES from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { login } from "../../../api/api";
import { createUser, loginUser } from "../../../api/user";
import swal from "sweetalert";
const { Logo } = IMAGES;
export default function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleLogin = async () => {
    const loginpayload = {
      email: email,
      password: password,
    };
    const response = await loginUser(loginpayload);

    if (response.remote === "success") {
      const token = localStorage.setItem("token", response.data.token);
      swal("Login Successfully");
      window.location.href = "/dashboard";
    } else {
    }
  };
  return (
    <>
      <div className={styles.main_bg}>
        <div className={styles.login_form}>
          <Form>
            <div className={styles.logo_div}>
              <img src={Logo} />
            </div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Enter email"
                className={styles.form_input}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="password"
                placeholder="Password"
                className={styles.form_input}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <div className="text-center mt-5">
              <Button
                variant="primary"
                className={styles.main_button}
                onClick={handleLogin}
              >
                Login
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
