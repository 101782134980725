import React, { useEffect } from "react";
import styles from "./../crms.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import swal from "sweetalert";
import { deleteIcon, editIcon, viewIcon } from "../../../../utils/svg.file";
import {
  showContactList,
  deleteContactDetail,
} from "../../../../api/adminPanel/contactUs";
import { useState } from "react";
import DeleteModal from "../../../component/modal/deleteModal";
export default function ManageContactUs() {
  const navigate = useNavigate();
  const [contactList, setContactList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [showValue, setShowValue] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = async (id) => {
    const response = await deleteContactDetail(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
      handleShowContactList()
    } else {
    }
  };
  const handleShowContactList = async (id) => {
    const response = await showContactList(id);
    if (response.remote === "success") {

      setContactList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleShowContactList();
  }, []);
  const columns = [
    { field: "sno", headerName: "SNo.", width: 100, },
    // { field: "id", headerName: "ID.", width: 100,  },
    { field: "category", headerName: "Category", width: 200 },
    { field: "location", headerName: "Location", width: 200 },
    { field: "address", headerName: "Address", width: 200 },
    { field: "firstMob", headerName: "First Mob No.", width: 200 },

    { field: "secondMob", headerName: "Second Mob No.", width: 200 },

    { field: "thirdMob", headerName: "Third Mob No.", width: 200 },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            {/* <span className={styles.view_icon_btn}>{viewIcon}</span> */}
            <span
              className={styles.edit_icon_btn}
              onClick={() =>
                navigate(`/edit-contact-us/${cellValues.row.id}`)
              }
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];

  let rows = [];

  contactList.map((item, index) => {
    rows.push({
      sno: index+1,
      id: item.id,
      category:
        item.category === "1"
          ? "Headquarters"
          : item.category === "2"
          ? "Hotel & Resorts"
          : item.category === "3"
          ? " Tamarind Restaurants"
          : null,
      location: item.location,
      address: item.address,
      firstMob: item.firstmobile,
      secondMob: item.secondmobile,
      thirdMob: item.thirdmobile,
    });
  });

  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Contact Us</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-contact-us")}
              >
                Add Contact us
              </Button>
            </div>
            <div className="show_entries d-flex align-items-center">
                Show
                <select className="form-control ms-1 me-1 w-auto"  onChange={(e)=>setShowValue(e.target.value)}>
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>


                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>

      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
