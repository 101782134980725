import React, { useEffect, useState } from "react";
import styles from "./../retreats.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddGettingDetails,
  deleteImage,
  FindGettingList,
  findRetreatroom,
  showRetreatDetailName,
  ShowRetreatroomListForRoom,
  UpdateGettingDetails,
  UpdateRetreatRoom,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";

import { addGallerySliderImage } from "../../../../api/adminPanel/restaurantApi";
import ReactQuill from "react-quill";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Celebration_URL, Offers_URL } from "../../../../serverUrl";
import { UploadIcon } from "../../../../utils/svg.file";
export default function EditGettingHere() {
  const navigate = useNavigate();
  const [longDescription, setlongDescription] = useState("");
  const { id } = useParams();
  const [firstTitle, setFirstTitle] = useState("");
  const [firstDescription, setFirstDescription] = useState("");
  const [secondTitle, setSecondTitle] = useState("");
  const [secondDescription, setSecondDescription] = useState("");
  const [thirdTitle, setThirdTitle] = useState("");
  const [thirdDescription, setThirdDescription] = useState("");
  const [fourthTitle, setFourthTitle] = useState("");
  const [fourthDescription, setFourthDescription] = useState("");
  const [retreatId, setRetreatId] = useState("");
  const [retreatList, setRetreatList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [imgHome, setImgBanner] = useState("");
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const onChange = ({ fileList }) => {
    setFileList(fileList);
    setImgBanner(fileList[0].originFileObj);
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const handleRetreatListforRoom = async () => {
    const response = await ShowRetreatroomListForRoom();
    if (response.remote === "success") {
      setRetreatList(response.data.data);
    } else {
    }
  };

  const handleAddGettingDetails = async () => {
    const gettingData = {
      id: id,
      retreatId: retreatId,
      imgHome: imgHome,
      firstTitle: firstTitle,
      firstDescription: firstDescription,
      secondTitle: secondTitle,
      secondDescription: secondDescription,
      thirdTitle: thirdTitle,
      thirdDescription: thirdDescription,
      fourthTitle: fourthTitle,
      fourthDescription: fourthDescription,
    };
    const response = await UpdateGettingDetails(gettingData);

    if (response.remote === "success") {
      swal("Added Successfully");
      navigate("/manage-getting-here");
    } else {
    }
  };
  const handleGettingDetails = async (id) => {
    const response = await FindGettingList(id);
    if (response.remote === "success") {
      setRetreatId(response.data.data.retreat);
      setImgBanner(response.data.data.banner);
      setFirstTitle(response.data.data.first_title);
      setSecondTitle(response.data.data.second_title);
      setThirdTitle(response.data.data.third_title);
      setFourthTitle(response.data.data.fourth_title);
      setFirstDescription(response.data.data.first_content);
      setSecondDescription(response.data.data.second_content);
      setThirdDescription(response.data.data.third_content);
      setFourthDescription(response.data.data.fourth_content);
    } else {
    }
  };

  useEffect(() => {
    handleRetreatListforRoom();
  }, []);
  useEffect(() => {
    handleGettingDetails(id);
  }, [id]);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Getting Here</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <label className="fw-bold">
                    Banner Image{" "}
                    <small className="text-success">
                      (Max. Size 1219 X 500 px)
                    </small>
                  </label>

                  <ImgCrop grid aspect={1219 / 500}>
                    <Upload
                      action={Base_URL}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                    >
                      {fileList.length >= 1 ? (
                        <>
                        <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      ) : (
                        <>
                        <img
                            src={`${Celebration_URL}${imgHome}`}
                            alt=""
                            className={styles.preview_img}
                          />
                          
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>{" "}
                <Col md={12}>
                  <label className="fw-bold">Select Retreats</label>
                  <Form.Group className="">
                    <Form.Select
                      className={styles.form_input}
                      value={retreatId}
                      onChange={(e) => setRetreatId(e.target.value)}
                    >
                      <option selected>Choose Retreats </option>

                      {retreatList.map((item, index) => {
                        return (
                          <>
                            <option key={index} value={item.value}>
                              {item.label}, {item.other}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <hr></hr>
                <Col md={12}>
                  <label className="fw-bold">First Tab</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setFirstTitle(e.target.value)}
                    value={firstTitle}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <label className="fw-bold">First Description</label>
                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={setFirstDescription}
                    value={firstDescription}
                  />
                </Col>
                <hr></hr>
                <Col md={12}>
                  <label className="fw-bold">Second Tab</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setSecondTitle(e.target.value)}
                    value={secondTitle}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <label className="fw-bold">Second Description</label>
                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={setSecondDescription}
                    value={secondDescription}
                  />
                </Col>
                <hr></hr>
                <Col md={12}>
                  <label className="fw-bold">Third Tab</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setThirdTitle(e.target.value)}
                    value={thirdTitle}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <label className="fw-bold">Third Description</label>
                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={setThirdDescription}
                    value={thirdDescription}
                  />
                </Col>
                <hr></hr>
                <Col md={12}>
                  <label className="fw-bold">Fourth Tab</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setFourthTitle(e.target.value)}
                    value={fourthTitle}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <label className="fw-bold">Fourth Description</label>
                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={setFourthDescription}
                    value={fourthDescription}
                  />
                </Col>
                <hr></hr>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleAddGettingDetails}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
