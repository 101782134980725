import React, { useState, useEffect } from "react";
import styles from "./newsLetter.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../component/table";
import { allNewsLetter } from "../../../api/user";
import moment from "moment/moment";

export default function ManageNewsLetter() {
  const navigate = useNavigate();
  const [newsLetterList, setNewsLetterList] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState('')
  const [showValue, setShowValue] = useState("");
  const handleShowNewsLetter = async () => {
    const response = await allNewsLetter();

    if (response.remote === "success") {
      setNewsLetterList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleShowNewsLetter();
  }, []);

  const columns = [
    { field: "id", headerName: "Date", width: 250 },
    { field: "name", headerName: "Name", width: 250 },
    { field: "email", headerName: "Email", width: 600 },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     name: "XYZ",
  //     email: "Gwalior",
  //   },
  // ];
  const handleSearch = (data) => {
    if (data) {
      let searchData = newsLetterList.filter((entry) =>
        entry.name?.toLowerCase().includes(data.toLowerCase()) || entry.email?.includes(data)
      );
      setSearchedList(searchData);
    }
  };

  const filteredList = () => {
    if(searchedData) {
      return searchedList
    } else {
      return newsLetterList
    }
  }
  let rows = [];
  let newstate = "";
  filteredList().map((item) => {
    const date = moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')
    rows.push({
      id: date,
      name: item.name,
      email: item.email,
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage NewsLetter</h3>
              </div>
            </div>
            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select className="form-control ms-1 me-1" onChange={(e)=>setShowValue(e.target.value)}>
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>


                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchedData(e.target.value);
                }}
              />
            </div>
            <Table rows={rows} columns={columns} showValue={showValue}/>
          </div>
        </div>
      </div>
    </>
  );
}
