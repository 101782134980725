import React, { useEffect, useState } from "react";
import styles from "./../retreats.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon, viewIcon } from "../../../../utils/svg.file";
import {
  deleteRestaurantdetails,
  deleteRetreatroom,
  ShowRestaurantDetails,
  ShowRetreatroomList,
  ShowRetreatroomListForRoom,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import DeleteModal from "../../../component/modal/deleteModal";

export default function ManageRoom() {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [restaurantList, setRestaurantList] = useState([]);
  const [retreatList, setRetreatList] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const [showValue, setShowValue] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleRetreatList = async () => {
    const response = await ShowRetreatroomList();
    if (response.remote === "success") {
      setRestaurantList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleRetreatList();
  }, []);
  const handleRetreatListforRoom = async () => {
    const response = await ShowRetreatroomListForRoom();
    if (response.remote === "success") {
      setRetreatList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleRetreatListforRoom();
  }, []);
  const handleDelete = async (id) => {
    const response = await deleteRetreatroom(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
      handleRetreatList();
    } else {
    }
  };
  const columns = [
    { field: "sn", headerName: "SNo.", width: 100 },
    { field: "title", headerName: "Title", height: 300, width: 300 },
    { field: "retreat", headerName: "Retreat", width: 200 },
    { field: "location", headerName: "Location", width: 200 },
    {
      field: "shortdescription",
      headerName: "Description",
      height: 300,
      width: 400,
    },
    // { field: "roomSize", headerName: "Room Size", height: 300, width: 150 },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-room/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];

  const handleSearch = (data) => {
    if (data) {
      let searchData = restaurantList.filter(
        (entry) =>
          entry.title?.toLowerCase().includes(data.toLowerCase()) ||
          entry.retreatLocation?.toLowerCase().includes(data.toLowerCase()) ||
          entry.retreatName?.toLowerCase().includes(data.toLowerCase()) 
      );
      setSearchedList(searchData);
    }
  };

  const filteredList = () => {
    if (searchedData) {
      return searchedList;
    } else {
      return restaurantList;
    }
  };

  let rows = [];

  // eslint-disable-next-line array-callback-return
  filteredList()?.map((item, index) => {
    rows.push({
      sn: index + 1,
      id: item.id,
      title: item.title,
      shortdescription: item.shortdescription,
      retreat: item.retreatName,
      location: item.retreatLocation,
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Room</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-room")}
              >
                Add Room
              </Button>
            </div>

            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select
                  className="form-control ms-1 me-1"
                  onChange={(e) => setShowValue(e.target.value)}
                >
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>

                  <option value="100"> 100</option>
                </select>
                Entries
                <select
                  style={{ width: "300px" }}
                  className="form-control ms-4 me-1"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearchedData(e.target.value);
                  }}
                >
                    <option selected>Select Retreat</option>
                  {retreatList?.map((item)=>(
                    <>
                    <option value={item.label}>{item.label}, {item.other}</option>
                  </>
                  ))}
                </select>
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchedData(e.target.value);
                }}
              />
            </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
