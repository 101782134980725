import React, { useEffect, useState } from "react";
import styles from "./../restaurant.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { imageListClasses } from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Featured_URL, Offers_URL } from "../../../../serverUrl";
import { crossRed } from "../../../../utils/svg.file";
import {
  addCelebrationImage,
  addGiftImage,
  AddRestaurantName,
  deleteImage,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
export default function AddRestaurant() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const handelAddRestaurant = async () => {
    
      const restaurantDataData = {
        location: state,
        name: name,
      };
      const response = await AddRestaurantName(restaurantDataData);
  
      if (response.remote === "success") {
        swal("restaurant Successfully Added");
        setName('')
        setState('')
      } else {
      }
  };
 useEffect(() => {
    
  }, []);
  return (
    <>
      <div className="multi-images">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Restaurant</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Select State</label>
                  <Form.Select
                    className={styles.form_input}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option selected>Select State</option>

                    <option value="1">Malasia</option>
                    <option value="2">Singapore</option>
                  </Form.Select>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Restaurant Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Restaurant"
                    className={styles.form_input}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>

                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handelAddRestaurant}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
