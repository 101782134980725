import React, { useEffect, useState } from "react";
import styles from "./about.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import { UploadIcon } from "../../../../utils/svg.file";
import swal from "sweetalert";
import { addAbout, findAboutContent, updateAbout } from "../../../../api/adminPanel/accoladesApi";
export default function EditAboutUs() {
  let { id } = useParams();

  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [bannerPic, setBannerPic] = useState("");
  const [corePrincipalPic, setCorePrincipalPic] = useState("");
  const [stateMindPic, setStateMindPic] = useState("");
  const [sensePeopleSecond, setSensePeopleSecond] = useState("");
  const [sensePeopleFirst, setSensePeopleFirst] = useState("");
  const [extendingExperiencePic, setExtendingExperiencePic] = useState("");
  const [aboutPrivacyFirstPic, setAboutPrivacyFirstPic] = useState("");
  const [aboutPrivacySecondPic, setAboutPrivacySecondPic] = useState("");
  const [environmentallyConsciousPic, setEnvironmentallyConsciousPic] =
  useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [communityPic, setCommunityPic] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");
  const [principleDescription, setPrincipleDescription] = useState("");
  const [mindDescription, setMindDescription] = useState("");
  const [peopleDescription, setPeopleDescription] = useState("");
  const [experienceDescription, setExperienceDescription] = useState("");
  const [privacyDescription, setPrivacyDescription] = useState("");
  const [discoveryDescription, setDiscoveryDescription] = useState("");
  const [ecoDescription, setEcoDescription] = useState("");
  const [environmentallyDescription, setEnvironmentallyDescription] = useState("");
  const [communityDescription, setCommunityDescription] = useState("");
  
  const onChangeBanner = async ({ fileList }) => {
    // setFileList(fileList);
    setBannerPic(fileList[0].originFileObj);
  };
  const onChangeCorePrincipal = async ({ fileList }) => {
    // setFileList(fileList);
    setCorePrincipalPic(fileList[0].originFileObj);
  };
  const onChangeStateMind = async ({ fileList }) => {
    // setFileList(fileList);
    setStateMindPic(fileList[0].originFileObj);
  };
  const onChangeSensePeopleFirst = async ({ fileList }) => {
    // setFileList(fileList);
    setSensePeopleFirst(fileList[0].originFileObj);
  };
  const onChangeSensePeopleSecond = async ({ fileList }) => {
    // setFileList(fileList);
    setSensePeopleSecond(fileList[0].originFileObj);
  };


  const onChangeExtendingExperiencePic = async ({ fileList }) => {
    // setFileList(fileList);
    setExtendingExperiencePic(fileList[0].originFileObj);
  };
  const onChangeAboutPrivacyFirst = async ({ fileList }) => {
    // setFileList(fileList);
    setAboutPrivacyFirstPic(fileList[0].originFileObj);
  };
  const onChangeAboutPrivacySecond = async ({ fileList }) => {
    // setFileList(fileList);
    setAboutPrivacySecondPic(fileList[0].originFileObj);
  };

  const onChangeEnvironmentallyConscious = async ({ fileList }) => {
    // setFileList(fileList);
    setEnvironmentallyConsciousPic(fileList[0].originFileObj);
  };

  const onChangecommunityPic = async ({ fileList }) => {
    // setFileList(fileList);
    setCommunityPic(fileList[0].originFileObj);
  };
  const firstUpload = {
    action: { Base_URL },
    listType: "picture-card",
    defaultFileList: [...fileList],
    maxCount: "1",
  };

  const handleSubmitAbout = async () => {
    const aboutData = {
      id:id,
      bannerPic: bannerPic,
      corePrincipalPic: corePrincipalPic,
      stateMindPic: stateMindPic,
      sensePeopleSecond: sensePeopleSecond,
      sensePeopleFirst: sensePeopleFirst,
      extendingExperiencePic: extendingExperiencePic,
      aboutPrivacyFirstPic: aboutPrivacyFirstPic,
      aboutPrivacySecondPic: aboutPrivacySecondPic,
      environmentallyConsciousPic: environmentallyConsciousPic,
      communityPic: communityPic,
      bannerDescription: bannerDescription,
      principleDescription: principleDescription,
      mindDescription: mindDescription,
      peopleDescription: peopleDescription,
      experienceDescription: experienceDescription,
      privacyDescription: privacyDescription,
      discoveryDescription: discoveryDescription,
      ecoDescription: ecoDescription,
      environmentallyDescription: environmentallyDescription,
      communityDescription: communityDescription,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    };
    const response = await updateAbout(aboutData);

    if (response.remote === "success") {
      swal("About Update succesfully Added!");
      navigate("/manage-about-us");
    } else {
    }
  };
  const handleAboutList = async (id) => {
    const response = await findAboutContent(id);
    if (response.remote === "success") {
      setBannerPic(response.data.data.banner)
  setCorePrincipalPic(response.data.data.principal)
  setStateMindPic(response.data.data.mind)
  setSensePeopleSecond(response.data.data.people_sec)
  setSensePeopleFirst(response.data.data.people)
  setExtendingExperiencePic(response.data.data.experience)
  setAboutPrivacyFirstPic(response.data.data.privacy)
  setAboutPrivacySecondPic(response.data.data.privacy_sec)
  setEnvironmentallyConsciousPic(response.data.data.environmentally)
  setCommunityPic(response.data.data.community)
  setBannerDescription(response.data.data.about_text)
  setPrincipleDescription(response.data.data.principal_text)
  setMindDescription(response.data.data.mind_text)
  setPeopleDescription(response.data.data.place_text)
  setExperienceDescription(response.data.data.experience_text)
  setPrivacyDescription(response.data.data.privacy_text)
  setDiscoveryDescription(response.data.data.discovery_text)
  setEcoDescription(response.data.data.eco_text)
  setEnvironmentallyDescription(response.data.data.environmentally_text)
  setCommunityDescription(response.data.data.community_text)
  setMetaDescription(response.data.data.meta_description)
  setMetaTitle(response.data.data.meta_title)
    } else {
    }
  };
  useEffect(() => {
    handleAboutList(id)

  }, [id]);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add About Us</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold mb-2">Description</label>
                  <Form.Group className="">
                    <textarea
                      rows="4"
                      type="text"
                      placeholder="Description"
                      className={styles.form_input}
                      value={bannerDescription}
                      onChange={(e) => setBannerDescription(e.target.value)}

                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Banner Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={1219 / 757}>
                    <Upload {...firstUpload} onChange={onChangeBanner}>
                      
                        <img src={`${Offers_URL}${bannerPic}`} alt="" />

                    </Upload>
                  </ImgCrop>
                </Col>
                <hr></hr>
                <label class="fw-bold mb-3">Core Principles</label>

                <Col md={6}>
                  <label className="fw-bold mb-2">Description</label>
                  <textarea
                    rows="4"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={principleDescription}
                    onChange={(e) => setPrincipleDescription(e.target.value)}

                  />
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 536 X 671 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={536 / 671}>
                    <Upload {...firstUpload} onChange={onChangeCorePrincipal}>
                        <img src={`${Offers_URL}${corePrincipalPic}`} alt="" />

                    </Upload>
                  </ImgCrop>
                </Col>

                <hr></hr>
                <label class="fw-bold mb-3">State Of Mind</label>

                <Col md={6}>
                  <label className="fw-bold mb-2">Description</label>
                  <textarea
                    rows="4"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={mindDescription}
                    onChange={(e) => setMindDescription(e.target.value)}

                  />
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Preview Image
                    <small className="text-success small-msg">
                      (Max. Size 536 X 671 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={536 / 671}>
                    <Upload {...firstUpload} onChange={onChangeStateMind}>
                    
                        <img src={`${Offers_URL}${stateMindPic}`} alt="" />

                    </Upload>
                  </ImgCrop>
                </Col>

                <hr></hr>
                <label class="fw-bold mb-3">Sense Of People</label>
                <Col md={6}>
                  <label className="fw-bold mb-2">Description</label>
                  <textarea
                    rows="4"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={peopleDescription}
                    onChange={(e) => setPeopleDescription(e.target.value)}

                  />
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    First Image
                    <small className="text-success small-msg">
                      (Max. Size 659 X 397 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={659 / 397}>
                    <Upload
                      {...firstUpload}
                      onChange={onChangeSensePeopleFirst}
                    >
                      
                        <img src={`${Offers_URL}${sensePeopleFirst}`} alt="" />

                    
                    </Upload>
                  </ImgCrop>
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Second Image
                    <small className="text-success small-msg">
                      (Max. Size 351X 212 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={351 / 212}>
                    <Upload
                      {...firstUpload}
                      onChange={onChangeSensePeopleSecond}
                    >
                     
                        <img src={`${Offers_URL}${sensePeopleSecond}`} alt="" />

                    </Upload>
                  </ImgCrop>
                </Col>

                <hr></hr>
                <label class="fw-bold mb-3">Extending The Experience</label>
                <Col md={6}>
                  <label className="fw-bold mb-2">Description</label>
                  <textarea
                    rows="4"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={experienceDescription}
                    onChange={(e) => setExperienceDescription(e.target.value)}

                  />
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Preview Image
                    <small className="text-success small-msg">
                      (Max. Size 536 X 671 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={536 / 671}>
                    <Upload
                      {...firstUpload}
                      onChange={onChangeExtendingExperiencePic}
                    >
                     
                        <img src={`${Offers_URL}${extendingExperiencePic}`} alt="" />

                    </Upload>
                  </ImgCrop>
                </Col>

                <hr></hr>
                <label class="fw-bold mb-3">About Privacy</label>
                <Col md={6}>
                  <label className="fw-bold mb-2">Description</label>
                  <textarea
                    rows="4"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={privacyDescription}
                    onChange={(e) => setPrivacyDescription(e.target.value)}

                  />
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    First Image
                    <small className="text-success small-msg">
                      (Max. Size 659 X 397 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={659 / 397}>
                    <Upload
                      {...firstUpload}
                      onChange={onChangeAboutPrivacyFirst}
                    >
                    
                        <img src={`${Offers_URL}${aboutPrivacyFirstPic}`} alt="" />


                    </Upload>
                  </ImgCrop>
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Second Image
                    <small className="text-success small-msg">
                      (Max. Size 351 X 212 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={351 / 212}>
                    <Upload
                      {...firstUpload}
                      onChange={onChangeAboutPrivacySecond}
                    >

                        <img src={`${Offers_URL}${aboutPrivacySecondPic}`} alt="" />

                    </Upload>
                  </ImgCrop>
                </Col>

                <hr></hr>
                <label class="fw-bold mb-3">Eco-Luxe</label>
                <Col md={12}>
                  <label className="fw-bold mb-2">Description</label>
                  <textarea
                    rows="4"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={ecoDescription}
                    onChange={(e) => setEcoDescription(e.target.value)}

                  />
                </Col>
                <hr></hr>
                <label class="fw-bold mb-3">About Discovery</label>
                <Col md={12}>
                  <label className="fw-bold mb-2">Description</label>
                  <textarea
                    rows="4"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={discoveryDescription}
                    onChange={(e) => setDiscoveryDescription(e.target.value)}

                  />
                </Col>

                <hr></hr>
                <label class="fw-bold mb-3">Environmentally Conscious</label>

                <Col md={6}>
                  <label className="fw-bold mb-2">Description</label>
                  <textarea
                    rows="4"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={environmentallyDescription}
                    onChange={(e) => setEnvironmentallyDescription(e.target.value)}
                    
                  />
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 536 X 671 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={536 / 671}>
                    <Upload
                      {...firstUpload}
                      onChange={onChangeEnvironmentallyConscious}
                    >
               
                        <img src={`${Offers_URL}${environmentallyConsciousPic}`} alt="" />

                    </Upload>
                  </ImgCrop>
                </Col>

                <hr></hr>
                <label class="fw-bold mb-3">Supporting The Community</label>

                <Col md={6}>
                  <label className="fw-bold mb-2">Description</label>
                  <textarea
                    rows="4"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={communityDescription}
                    onChange={(e) => setCommunityDescription(e.target.value)}

                  />
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 536 X 671 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={536 / 671}>
                    <Upload {...firstUpload} onChange={onChangecommunityPic}>
                  
                        <img src={`${Offers_URL}${communityPic}`} alt="" />

               
                    </Upload>
                  </ImgCrop>
                </Col>
                <label className="fw-bold">Enter Meta</label>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      className={styles.form_input}
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      className={styles.form_input}
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>
                <Col md={12} className="text-center  mt-3">
                  <Button className={styles.main_button} onClick={()=>handleSubmitAbout()}>Submit</Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
