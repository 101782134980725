import React, { useState } from "react";
import styles from "./giftCard.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { Divider } from "@mui/material";
import {
  UploadIcon,
  addInputIcon,
  crossRed,
  deleteIcon,
} from "../../../utils/svg.file";
import swal from "sweetalert";
import { addAccolades } from "../../../api/adminPanel/accoladesApi";
import {
  addGiftCard,
  addGiftImage,
  deleteImage,
} from "../../../api/adminPanel/giftCardApi";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Featured_URL } from "../../../serverUrl";
import ReactQuill from "react-quill";
export default function AddGiftCard() {
  const navigate = useNavigate();
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [sliderImg, setSliderImg] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [subheading, setSubheading] = useState("");
  const [fileList, setFileList] = useState([]);
  const [locationField, setLocationField] = useState([
    {
      location: "",
    },
  ]);
  const [categoryField, setCategoryField] = useState([
    {
      category: "",
    },
  ]);
  const [valueField, setValueField] = useState([
    {
      value: "",
    },
  ]);
  const handleFormChangeLocation = (index, event) => {
    let data = [...locationField];
    data[index][event.target.name] = event.target.value;
    setLocationField(data);
  };
  const handleFormChangeCategory = (index, event) => {
    let data = [...categoryField];
    data[index][event.target.name] = event.target.value;
    setCategoryField(data);
  };
  const handleFormChangeValue = (index, event) => {
    let data = [...valueField];
    data[index][event.target.name] = event.target.value;
    setValueField(data);
  };

  const addLocationFields = () => {
    let newlocationfield = {
      location: "",
    };
    setLocationField([...locationField, newlocationfield]);
  };
  const addCategoryFields = () => {
    let newcategoryfield = {
      category: "",
    };
    setCategoryField([...categoryField, newcategoryfield]);
  };
  const addValueFields = () => {
    let newvaluefield = {
      value: "",
    };

    setValueField([...valueField, newvaluefield]);
  };

  const removeLocationFields = (index) => {
    let data = [...locationField];
    data.splice(index, 1);
    setLocationField(data);
  };
  const removeCategoryFields = (index) => {
    let data = [...categoryField];
    data.splice(index, 1);
    setCategoryField(data);
  };
  const removeValueFields = (index) => {
    let data = [...valueField];
    data.splice(index, 1);
    setValueField(data);
  };

  // const uploadImages = (e) => {
  //   setFiles([...e.target.files]);
  // };

  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  const onChangeImages = async ({ fileList }) => {
    setFileList(fileList);
    setSliderImg(fileList[0].originFileObj);

    const addGiftImagePayload = {
      image: fileList[0].originFileObj,
    };
    const response = await addGiftImage(addGiftImagePayload);

    if (response.remote === "success") {
      swal("Image Successfully Added");

      setImageArray([...imageArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };
  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const handleSubmitGiftCard = async () => {
    const giftCardPayload = {
      heading: heading,
      subheading: subheading,
      discription: description,
      image: imageArray,
      categoryField: categoryField,
      locationField: locationField,
      valueField: valueField,
    };
    const response = await addGiftCard(giftCardPayload);

    if (response.remote === "success") {
      swal("Gift Card succesfully Added!");
      navigate("/manage-gift-card");
      setImageArray([]);
      setDescription("");
      setLocationField([]);
      setHeading("");
      setCategoryField([]);
      setValueField([]);
    } else {
      swal("Something Went Wrong!");
    }
  };
  const handelDeleteImgae = async (image) => {
    const newImageArray = imageArray.filter(
      (imageArray) => imageArray !== image
    );
    setImageArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };
  // const onRemove
  return (
    <>
      <div className="multi-images ">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Gift Card</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <div className="contact_form">
                <Row>
                  <Col md={12}>
                    <Row gutter={[24, 0]}>
                      <Col md={6}>
                        <label className="fw-bold mb-2">
                          Slider Image{" "}
                          <small className="text-success">
                            (Max Image Size should be 1016 X 634px. )
                          </small>
                        </label>
                        <Form.Group className="d-flex">
                          <ImgCrop grid aspect={1016 / 634}>
                            <Upload
                              maxCount={2}
                              action={Base_URL}
                              listType="picture-card"
                              fileList={fileList}
                              onChange={onChangeImages}
                              onPreview={onPreview}
                              accept=".png,.jpg,.jpeg"
                              
                            >
                              <>
                                <div className="file_upload_div">
                                  <span>{UploadIcon}</span>
                                  <p>Click Here to Upload</p>
                                </div>
                              </>
                            </Upload>
                          </ImgCrop>
                          <ul className={styles.multi_image_preview}>
                            {imageArray?.map((item, index) => {
                              return (
                                <>
                                  <li className={styles.upload_list_image}>
                                    <img
                                      src={`${Featured_URL}${item}`}
                                      alt=""
                                    />
                                    <div className={styles.action_btn}>
                                      <span
                                        className={styles.delete_icon_btn}
                                        onClick={() => handelDeleteImgae(item)}
                                      >
                                        {crossRed}
                                      </span>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <label className="fw-bold">Heading</label>
                        <Form.Control
                          type="text"
                          placeholder="Heading"
                          className={styles.form_input}
                          value={heading}
                          onChange={(e) => setHeading(e.target.value)}
                        />
                      </Col>
                      <Col md={12}>
                        <label className="fw-bold">Sub Heading</label>
                        <Form.Control
                          type="text"
                          placeholder="Sub Heading"
                          onChange={(e) => setSubheading(e.target.value)}
                          className={styles.form_input}
                        />
                      </Col>
                      <Col md={12}>
                        <label className="fw-bold">Description</label>
                        <ReactQuill
                          rows={10}
                          type="text"
                          placeholder="Description"
                          className={styles.form_input}
                          onChange={setDescription}
                        />
                      </Col>
                    </Row>
                    <label className="mb-2 fw-bold">Category</label>
                    {categoryField.map((item, index) => {
                      return (
                        <>
                          <Row
                            className="justify-content-between detail-input"
                            key={index}
                          >
                            <Col md={10}>
                              <Form.Control
                                type="text"
                                name="category"
                                placeholder="Category"
                                className={styles.form_input}
                                defaultValue={item.category}
                                onChange={(event) =>
                                  handleFormChangeCategory(index, event)
                                }
                              />
                            </Col>{" "}
                            <Col md={2} className="text-end">
                              {/* {contactField.length < 2 ? (
                                <> */}{" "}
                              <span
                                className={styles.edit_icon_btn}
                                onClick={addCategoryFields}
                              >
                                {addInputIcon}
                              </span>
                              {/* </>
                              ) : null} */}
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() => removeCategoryFields(index)}
                              >
                                {deleteIcon}
                              </span>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                    <hr />
                  </Col>
                  <Col md={12}>
                    <label className="mb-2 fw-bold">Location</label>
                    {locationField.map((item, index) => {
                      return (
                        <>
                          <Row
                            className="justify-content-between detail-input"
                            key={index}
                          >
                            <Col md={10}>
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Location"
                                className={styles.form_input}
                                defaultValue={item.location}
                                onChange={(event) =>
                                  handleFormChangeLocation(index, event)
                                }
                              />
                            </Col>{" "}
                            <Col md={2} className="text-end">
                              {/* {contactField.length < 2 ? (
                               <> */}{" "}
                              <span
                                className={styles.edit_icon_btn}
                                onClick={addLocationFields}
                              >
                                {addInputIcon}
                              </span>
                              {/* </>
                             ) : null} */}
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() => removeLocationFields(index)}
                              >
                                {deleteIcon}
                              </span>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </Col>
                  <hr />
                  <Col md={12}>
                    <label className="mb-2 fw-bold">Values</label>
                    {valueField.map((item, index) => {
                      return (
                        <>
                          <Row
                            className="justify-content-between detail-input"
                            key={index}
                          >
                            <Col md={10}>
                              <Form.Control
                                type="text"
                                name="text"
                                placeholder="Values"
                                className={styles.form_input}
                                defaultValue={item.text}
                                onChange={(event) =>
                                  handleFormChangeValue(index, event)
                                }
                              />
                            </Col>{" "}
                            <Col md={2} className="text-end">
                              {/* {contactField.length < 2 ? (
                               <> */}{" "}
                              <span
                                className={styles.edit_icon_btn}
                                onClick={addValueFields}
                              >
                                {addInputIcon}
                              </span>
                              {/* </>
                             ) : null} */}
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() => removeValueFields(index)}
                              >
                                {deleteIcon}
                              </span>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="text-center  mt-5">
                    <Button
                      className={styles.main_button}
                      onClick={() => handleSubmitGiftCard()}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
