import React, { useEffect, useState } from "react";
import styles from "./job.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { addJobDetail, showCategory } from "../../../api/user";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function AddJob() {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [designation, setDesignation] = useState("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [state, setState] = useState("1");
  const [description, setDescription] = useState("");

  const handleShowCategory = async () => {
    const response = await showCategory();

    if (response.remote === "success") {
      setCategoryList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleShowCategory();
  }, []);
  const submitJob = async () => {
    const jobDetailPayload = {
      state: state,
      designation: designation,
      category: category,
      location: location,
      discription: description,
    };
    const response = await addJobDetail(jobDetailPayload);

    if (response.remote === "success") {
      navigate("/manage-job");
      setCategory("");
    } else {
    }
  };

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Job</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Designation</label>

                  <Form.Control
                    type="text"
                    placeholder="Designation"
                    className={styles.form_input}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Category</label>

                  <Form.Group className="">
                    <Form.Select
                      className={styles.form_input}
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option selected>Select Category </option>
                      {categoryList.map((item, index) => {
                        return (
                          <>
                            {" "}
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Location</label>

                  <Form.Control
                    type="text"
                    placeholder="Location"
                    className={styles.form_input}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Country</label>

                  <Form.Select
                    className={styles.form_input}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option selected>Select Country</option>

                    <option value="1">Malaysia</option>
                    <option value="2">Singapore</option>
                  </Form.Select>
                </Col>
                <Col md={12}>
                  <label className="fw-bold"> Description</label>

                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button className={styles.main_button} onClick={submitJob}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
