import axiosInstance from "../api";
const token = localStorage.getItem("token");
export const addTravelName = async (travelNameData) => {
    const data = new FormData();
    data.append("name", travelNameData.name);
    const response = await axiosInstance.request({
      url: "addtravelCategory",
      method: "POST",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response;
  };
export const showTravelName = async () => {
   
    const response = await axiosInstance.request({
      url: "showtravelCategory",
      method: "GET",
      
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response;
  };
export const deleteTravelName = async (id) => {
   
    const response = await axiosInstance.request({
      url: `deletetravelCategory/${id}`,
      method: "DELETE",
    
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response;
  };
export const findTravelName = async (id) => {
   
    const response = await axiosInstance.request({
      url: `FindtravelCategory/${id}`,
      method: "GET",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response;
  };
export const updateTravelName = async (updateTraveldata) => {
    const data = new FormData();
    data.append("id", updateTraveldata.id);
    data.append("name", updateTraveldata.name);
    const response = await axiosInstance.request({
      url: 'updatetravelCategory',
      method: "POST",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response;
  };
export const addTravelPackageDetail = async (addTravelPcakagedata) => {
  const data = new FormData();
  data.append("cardImage", addTravelPcakagedata.cardImage);
  data.append("mapImage", addTravelPcakagedata.mapImage);
  data.append("longdescription", addTravelPcakagedata.longdescription);
  data.append("banner", JSON.stringify(addTravelPcakagedata.banner));
  data.append("highlights", JSON.stringify(addTravelPcakagedata.highlights));
  data.append("itnery", JSON.stringify(addTravelPcakagedata.itnery));
  data.append("description", "New");
  data.append("title", addTravelPcakagedata.title);
  data.append("shortDescription", addTravelPcakagedata.shortDescription);
  data.append("price", addTravelPcakagedata.price);
    data.append("tour", JSON.stringify(addTravelPcakagedata.tour));
    data.append("terms", JSON.stringify(addTravelPcakagedata.terms));
    data.append("category", addTravelPcakagedata.category);
    
    data.append("meta_title", addTravelPcakagedata.metaTitle);
    data.append("meta_description", addTravelPcakagedata.metaDescription);
    const response = await axiosInstance.request({
      url: 'addtravelPackage',
      method: "POST",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response;
  };

export const updateTravelPackageDetail = async (updateTravelPcakagedata) => {
  
  const data = new FormData();
  data.append("id", updateTravelPcakagedata.id);
  data.append("cardImage", updateTravelPcakagedata.cardImage);
  data.append("longdescription", updateTravelPcakagedata.longdescription);
  data.append("mapImage", updateTravelPcakagedata.mapImage);
  data.append("banner", JSON.stringify(updateTravelPcakagedata.banner));
  data.append("highlights", JSON.stringify(updateTravelPcakagedata.highlights));
  data.append("itnery", JSON.stringify(updateTravelPcakagedata.itnery));
  data.append("description", "New");
  data.append("title", updateTravelPcakagedata.title);
  data.append("shortDescription", updateTravelPcakagedata.shortDescription);
  data.append("price", updateTravelPcakagedata.price);
  data.append("tour", JSON.stringify(updateTravelPcakagedata.tour));
  data.append("terms", JSON.stringify(updateTravelPcakagedata.terms));
  data.append("category", updateTravelPcakagedata.category);
  
  data.append("meta_title", updateTravelPcakagedata.metaTitle);
  data.append("meta_description", updateTravelPcakagedata.metaDescription);
  const response = await axiosInstance.request({
    url: "updatetravelPackage",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
  export const showTravelPackage = async () => {
   
    const response = await axiosInstance.request({
      url: "showtravelPackage",
      method: "GET",
      
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response;
  };
  export const deleteTravelPackage = async (id) => {
   
    const response = await axiosInstance.request({
      url: `deletetravelPackage/${id}`,
      method: "DELETE",
    
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response;
  };

  export const findTravelDetails = async (id) => {
   
    const response = await axiosInstance.request({
      url: `Findtravelpackage/${id}`,
      method: "GET",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  
    return response;
  };

  export const addTicketDetail = async (addTicketdata) => {
  
    const data = new FormData();
    data.append("title", addTicketdata.title);
    data.append("offer", addTicketdata.offer);
    data.append("image", addTicketdata.image);
    
    data.append("ticket", JSON.stringify(addTicketdata.ticket));
    
      const response = await axiosInstance.request({
        url: 'addManageticket',
        method: "POST",
        data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
    
      return response;
    };
    export const updateTicketDetail = async (addTicketdata) => {
  
      const data = new FormData();
      data.append("id", addTicketdata.id);
      data.append("title", addTicketdata.title);
      data.append("offer", addTicketdata.offer);
      data.append("image", addTicketdata.image);
      
      data.append("ticket", JSON.stringify(addTicketdata.ticket));
      
        const response = await axiosInstance.request({
          url: 'updateManageticket',
          method: "POST",
          data,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
      
        return response;
      };