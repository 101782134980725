import React from "react";
import styles from "./dashboard.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.job_list_page}>
        <div className="m-3 px-3">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between  ">
              <div className={styles.page_title}>
                <h3>Dashboard</h3>
              </div>
            </div>{" "}
            <div className={styles.welcome_admin}>
              <h1>Welcome to Admin</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
