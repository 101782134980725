import React, { useEffect, useState } from "react";
import styles from "./../experience.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon } from "../../../../utils/svg.file";

import { Featured_URL, Offers_URL } from "../../../../serverUrl";
import DeleteModal from "../../../component/modal/deleteModal";
import swal from "sweetalert";
import {
  ShowExperienceList,
  ShowRestaurantListForExp,
  ShowRetreatroomListForRoom,
  deleteDeleteExperience,
  deleteRetreatList,
  showRestaurantBanner,
  showRetreatBanner,
} from "../../../../api/adminPanel/giftCardApi";

export default function ManageExperience() {
  const navigate = useNavigate();
  const [bannerList, setbannerList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [showValue, setShowValue] = useState("");

  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const columns = [
    { field: "sn", headerName: "SNo.", width: 100 },
    // { field: "id", headerName: "SNo.", width: 100 },
    {
      field: "image",
      height: 150,
      width: 300,
      renderCell: (cellValues) => {
        return (
          <>
            <img
              src={`${Offers_URL}${cellValues.row.image}`}
              className={styles.table_img}
            />
          </>
        );
      },
    },
    { field: "title", headerName: "Title", width: 300 },
    { field: "category", headerName: "Category", width: 300 },
    { field: "retreat", headerName: "Retreat/Restaurant", width: 300 },

    { field: "shortDescription", headerName: "Short Description", width: 300 },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-experience/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const [retreatList, setRetreatList] = useState([]);
  const [restaurantList, setRestaurantList] = useState([]);
  const handleRetreatListforRoom = async () => {
    const response = await ShowRetreatroomListForRoom();
    if (response.remote === "success") {
      setRetreatList(response.data.data);
    } else {
    }
  };
  const handleRestaurantListforRoom = async () => {
    const response = await ShowRestaurantListForExp();
    if (response.remote === "success") {
      setRestaurantList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleRetreatListforRoom();
    handleRestaurantListforRoom();
  }, []);

  const handelExperience = async () => {
    const response = await ShowExperienceList();
    if (response.remote === "success") {
      setbannerList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handelExperience();
  }, []);


  const handleSearch = (data) => {
    if (data) {
      let searchData = bannerList.filter(
        (entry) =>
          entry.title?.toLowerCase().includes(data.toLowerCase()) ||
          entry.retreat?.toLowerCase().includes(data.toLowerCase())
          // // entry.category?.toLowerCase().includes(data.toLowerCase()) ||
          // entry.subcategory?.toLowerCase().includes(data.toLowerCase())
      );
      setSearchedList(searchData);
    }
  };
  const filteredList = () => {
    if (searchedData) {
      return searchedList;
    } else {
      return bannerList;
    }
  };
  let rows = [];

  filteredList().map((item, index) => {
    let type = "";
    if (Number(item.category) === 1) {
      type = "SAMADHIAN";
    } else if (Number(item.category) === 2) {
      type = "CULTURE";
    } else if (Number(item.category) === 3) {
      type = "CELEBRATIONS";
    }else if (Number(item.category) === 4) {
      type = "WELLNESS";
    }
    rows.push({
      sn: index + 1,
      id: item.id,
      image: item.cardImage,
      title: item.title,
      category: type,
      shortDescription: item.shortdescription,
      retreat: item.retreat,
    });
  });
  const handleDelete = async (id) => {
    const response = await deleteDeleteExperience(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
      handelExperience();
    } else {
    }
  };
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Stories</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-experience")}
              >
                Add Experience
              </Button>
            </div>
            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                <div className="d-flex">
                  Show
                  <select
                    className="form-control ms-1 me-1"
                    style={{ width: "50px" }}
                  >
                    <option value="50"> 50</option>
                    <option value="10"> 10</option>
                    <option value="25"> 25</option>

                    <option value="100"> 100</option>
                  </select>
                  Entries
                </div>
                <select
                  className="form-control ms-3 me-1"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearchedData(e.target.value);
                  }}
                >
                  <option selected>Select Restaurant</option>
                  {restaurantList?.map((item) => (
                    <>
                      <option value={item.label}>
                        {item.label}, {item.other}
                      </option>
                    </>
                  ))}
                </select>
                <select
                  className="form-control ms-3 me-1"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearchedData(e.target.value);
                  }}
                >
                  <option selected>Select Retreat</option>
                  {retreatList?.map((item) => (
                    <>
                      <option value={item.label}>
                        {item.label}, {item.other}
                      </option>
                    </>
                  ))}
                </select>
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchedData(e.target.value);
                }}
              />
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
