import React, { useEffect, useState } from "react";
import styles from "../job.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import swal from "sweetalert";
import { showJobApplierDetail } from "../../../../api/adminPanel/jobapi";
import { CV_URL } from "../../../../serverUrl";

export default function ViewJobApplierDetail() {
  const [applierDetail, setApplierDetail] = useState([]);

  const stateList = [
    { id: 1, name: "Malaysia" },
    { id: 2, name: "Singapore" },
  ];
  let { id } = useParams();
  const navigate = useNavigate();
  const handleGetJobApplierDetail = async (id) => {
    const response = await showJobApplierDetail(id);
  
    if (response.remote === "success") {
      
      setApplierDetail(response.data.data);
      //   if (state === "2") {
      //     setState("Singapore");
      //   } else {
      //     setState("Malaysia");
      //   }
    } else {
    }
  };

  useEffect(() => {
    handleGetJobApplierDetail(id);
  }, []);
  // const onButtonClick = () => {
  //   // using Java Script method to get PDF file
  //   fetch("SamplePDF.pdf").then((response) => {
  //     response.blob().then((blob) => {
  //       // Creating new object of PDF file
  //       const fileURL = window.URL.createObjectURL(blob);
  //       // Setting various property values
  //       let alink = document.createElement("a");
  //       alink.href = fileURL;
  //       alink.download = "SamplePDF.pdf";
  //       alink.click();
  //     });
  //   });
  // };
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>View Job Applier Detail</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">First Name</label>

                  <p>{applierDetail.first_name}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Last Name</label>

                  <p>{applierDetail.last_name}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Email</label>

                  <p>{applierDetail.email}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Phone</label>

                  <p>{applierDetail.phone}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Address 1</label>

                  <p>{applierDetail.address_1}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Address 2</label>

                  <p>{applierDetail.address_2}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Country</label>

                  <p>{applierDetail.country}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Postal Code</label>

                  <p>{applierDetail.postcode}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Job Id</label>

                  <p>{applierDetail.job_id}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Job Title</label>

                  <p>{applierDetail.jobname}</p>
                </Col>

                <Col md={6}>
                  <label className="fw-bold">Linked In</label>

                  <p>{applierDetail.linked_in}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Facebook</label>

                  <p>{applierDetail.facebook}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Google</label>

                  <p>{applierDetail.google}</p>
                </Col>
                <Col md={12}>
                  <label className="fw-bold">
                    How did you hear about Samadhi
                  </label>

                  <p>{applierDetail.samadhi}</p>
                </Col>
                <Col md={12}>
                  <div className="text-center">
                    <a
                      className={styles.main_button}
                      href={`${CV_URL}${applierDetail.cv}`}
                      target="_blank"
                      download
                    >
                      Download
                    </a>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
