import React, { useState } from "react";
import styles from "./../celebrationEvent.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import swal from "sweetalert";
import { AddCelebrationText } from "../../../../api/adminPanel/celebrationAPI";
export default function AddCelebrationLandingPageContent() {
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const onChange = async () => {
 
    const addGiftImagePayload = {
      text: description,
    };
    const response = await AddCelebrationText(addGiftImagePayload);

    if (response.remote === "success") {
      swal("Data Successfully Added");

    
    } else {
    }
  };
  return (
    <>
      <div className="multi-images">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Landing Page Content</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <label className="fw-bold">Description</label>
                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={description}
                    onChange={setDescription}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col md={12} className="text-center mt-5">
                  <Button className={styles.main_button} onClick={onChange}>Submit</Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
