import React, { useEffect, useState } from "react";
import styles from "./../restaurant.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon } from "../../../../utils/svg.file";

import { Featured_URL, Offers_URL } from "../../../../serverUrl";
import DeleteModal from "../../../component/modal/deleteModal";
import swal from "sweetalert";
import { showRestaurantBanner } from "../../../../api/adminPanel/giftCardApi";

export default function ManageRestaurantLandingPageBanner() {
  const navigate = useNavigate();
  const [bannerList, setbannerList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handelRestaurantBanner = async () => {
    const response = await showRestaurantBanner();
    if (response.remote === "success") {
      setbannerList(response.data.data);
    } else {
    }
  };

  // const handleDelete = async (id) => {
  //   const response = await deleteFeaturedStory(id);

  //   if (response.remote === "success") {
  //     hideDeleteModal();
  //     swal("Deleted Successfully!");
  //   } else {
  //   }
  // };
  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    {
      field: "image",
      height: 150,
      width: 300,
      renderCell: (cellValues) => {
        return (
          <>
            <img
              src={`${Offers_URL}${cellValues.row.image}`}
              className={styles.table_img}
            />
          </>
        );
      },
    },
    { field: "shortDescription", headerName: "Short Description", width: 400 },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(
                  `/edit-restaurant-landing-page-banner/${cellValues.row.id}`
                );
              }}
            >
              {editIcon}
            </span>
            {/* <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handelRestaurantBanner();
  }, []);

  // let rows = [
  //   { id: 1, image: "dsd", shortDescription: "fdsdfggs" },
  //   { id: 2, image: "dsds", shortDescription: "fdsdfggs" },
  // ];
  let rows = [];

  bannerList?.map((item, index) => {
    rows.push({
      id: item.id,
      image: item.image,
      shortDescription: item.description,
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Landing Page Banner</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() =>
                  navigate("/add-restaurant-landing-page-banner")
                }
              >
                Add Banner
              </Button>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
      {/* <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      /> */}
    </>
  );
}
