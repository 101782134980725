import React, { useEffect } from "react";
import styles from "./giftCard.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../component/table";
import swal from "sweetalert";
import { deleteIcon, editIcon, viewIcon } from "../../../utils/svg.file";
import {
  showContactList,
  deleteContactDetail,
} from "../../../api/adminPanel/contactUs";
import { useState } from "react";
import DeleteModal from "../../component/modal/deleteModal";
import {
  deleteAccolades,
  showAccoladesList,
} from "../../../api/adminPanel/accoladesApi";
import {
  deleteGiftCard,
  showGiftApplierList,
  showGiftList,
} from "../../../api/adminPanel/giftCardApi";
export default function GiftApplierList() {
  const navigate = useNavigate();
  const [giftCardApplierList, setGiftCardApplierList] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const [showValue, setShowValue] = useState("");
  const handleShowGiftApplierList = async (id) => {
    const response = await showGiftApplierList(id);
    if (response.remote === "success") {
      setGiftCardApplierList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleShowGiftApplierList();
  }, []);
  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    { field: "giftId", headerName: "Gift Id", width: 100 },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "lastName", headerName: "Last Name", width: 200 },
    { field: "location", headerName: "Location", width: 200 },
    { field: "quantity", headerName: "Quantity", width: 100 },
    { field: "type", headerName: "Type", width: 100 },
    { field: "value", headerName: "Value", width: 100 },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              onClick={() =>
                navigate(
                  `/view-gift-card-applier-detail/${cellValues.row.id}`
                )
              }
            >
              {viewIcon}
            </span>
          </>
        );
      },
    },
  ];
  const handleSearch = (data) => {
    if (data) {
      let searchData = giftCardApplierList.filter(
        (entry) =>
          entry.location?.toLowerCase().includes(data.toLowerCase()) ||
          entry.f_name?.toLowerCase().includes(data.toLowerCase()) ||
          entry.l_name?.toLowerCase().includes(data.toLowerCase()) ||
          entry.type?.toLowerCase().includes(data.toLowerCase())
      );
      setSearchedList(searchData);
    }
  };

  const filteredList = () => {
    if (searchedData) {
      return searchedList;
    } else {
      return giftCardApplierList;
    }
  };
  let rows = [];

  filteredList().map((item, index) => {
    rows.push({
      id: item.id,
      giftId: item.giftId,
      firstName: item.f_name,
      lastName: item.l_name,
      value: item.value,
      type: item.type,
      location: item.location,
      quantity: item.quantity,
    });
  });

  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Gift Card Applier List</h3>
              </div>
            </div>
            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select
                  className="form-control ms-1 me-1"
                  onChange={(e) => setShowValue(e.target.value)}
                >
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>


                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchedData(e.target.value);
                }}
              />
            </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>
    </>
  );
}
