import React, { useEffect, useState } from "react";
import styles from "./../celebrationEvent.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { imageListClasses } from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Featured_URL, Offers_URL } from "../../../../serverUrl";
import { UploadIcon, crossRed } from "../../../../utils/svg.file";
import {
  addCelebrationImage,
  addGiftImage,
  deleteImage,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
export default function AddCelebrationLandingPageBanner() {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [state, setState] = useState("1");
  const [imageList, setImageList] = useState([]);
  const [imgHome, setImgBanner] = useState("");
  const [fileList, setFileList] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  //   const handleShowCategory = async () => {
  //     const response = await showCategory();

  //     if (response.remote === "success") {
  //       setCategoryList(response.data.data);
  //     } else {
  //     }
  //   };
  //   useEffect(() => {
  //     handleShowCategory();
  //   }, []);
  //   const submitJob = async () => {
  //     const jobDetailPayload = {
  //       state: state,
  //       designation: designation,
  //       category: category,
  //       location: location,
  //       discription: description,
  //     };
  //     const response = await addJobDetail(jobDetailPayload);

  //     if (response.remote === "success") {
  //       navigate("/manage-job");
  //       setCategory("");
  //     } else {
  //     }
  //   };
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const onChange = async ({ fileList }) => {
    // setFileList(fileList);
    // setImgBanner(fileList[0].originFileObj);
    const addGiftImagePayload = {
      image: fileList[0].originFileObj,
    };
    const response = await addCelebrationImage(addGiftImagePayload);

    if (response.remote === "success") {
      swal("Image Successfully Added");

      setImageArray([...imageArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const handelDeleteImgae = async (image) => {
    const newImageArray = imageArray.filter(
      (imageArray) => imageArray !== image
    );
    setImageArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };
  return (
    <>
      <div className="multi-images">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Landing Page Banner</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <label className="fw-bold">
                    Banner Image{" "}
                    <small className="text-success ">
                      (Max. Size 1219 X 757px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={1219 / 757}>
                    <Upload
                      action={Base_URL}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                    >
                      <>
                        <div className="file_upload_div">
                          <span>{UploadIcon}</span>
                          <p>Click Here to Upload</p>
                        </div>
                      </>
                    </Upload>
                  </ImgCrop>
                  <ul className={styles.multi_image_preview}>
                    {imageArray?.map((item, index) => {
                      
                      return (
                        <>
                          <li className={styles.upload_list_image}>
                            <img src={`${Offers_URL}${item}`} alt="" />
                            <div className={styles.action_btn}>
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() => handelDeleteImgae(item)}
                              >
                                {crossRed}
                              </span>
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
