import React, { useEffect, useState } from "react";
import styles from "./../job.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon, viewIcon } from "../../../../utils/svg.file";
import { Jobdescription } from "../../../../api/user";

export default function ManageJobDescription() {
  const navigate = useNavigate();
  const [JobDecriptionList,setJobDecriptionList]= useState('')
  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    { field: "description", headerName: "Description", width: 700 },
    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-job-description/${cellValues.row.id}`);
                
              }}
            >
              {editIcon}
            </span>
          </>
        );
      },
    },
  ];
  const handleJobdescription = async (id) => {
    const response = await Jobdescription(id);
    if (response.remote === "success") {

      setJobDecriptionList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleJobdescription();
  }, []);

  const rows = [
    {
      id: Number(JobDecriptionList?.id),
      description: JobDecriptionList?.discription,
      action: (
        <>
          <Button>View</Button>
        </>
      ),
    },
  ];
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Job Page Description</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-job-description")}
              >
                Add Job Description
              </Button>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
    </>
  );
}
