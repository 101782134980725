import React, { useEffect, useState } from "react";
import styles from "./home.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../component/table";
import { deleteIcon, editIcon } from "../../../utils/svg.file";
import {
  deleteTravelPackage,
  showTravelPackage,
} from "../../../api/adminPanel/travelApi";
import swal from "sweetalert";
import DeleteModal from "../../component/modal/deleteModal";
import { showHomeContent } from "../../../api/adminPanel/featuredStory";

export default function ManageHome() {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [travelPackageData, setTravelPackageData] = useState([]);
  const [deleteId, setDeletedId] = useState("");
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const [showValue, setShowValue] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handlePackageList = async () => {
    const response = await showHomeContent();
    if (response.remote === "success") {
      setTravelPackageData(response.data.data);
    } else {
    }
  };

  const handleDelete = async (id) => {
    const response = await deleteTravelPackage(id);

    if (response.remote === "success") {
      hideDeleteModal();
      handlePackageList();
      swal("Deleted Successfully!");
    } else {
    }
  };
  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    {
      field: "description",
      headerName: "Description",
      height: 300,
      width: 250,
    },
    { field: "titleOne", headerName: "Stay Title", height: 300, width: 150 },
    { field: "titleTwo", headerName: "Dining Title ", height: 300, width: 150 },

    {
      field: "titleThree",
      headerName: "Travel Title ",
      height: 300,
      width: 150,
    },
    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-homepage/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            {/* <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handlePackageList();
  }, []);
  const rows = [
    {
      id: Number(travelPackageData?.id),
      description:travelPackageData?.discription,
      titleOne: travelPackageData?.staytitle,
      titleTwo: travelPackageData?.traveltitle,
      titleThree: travelPackageData?.diningtitle,
      // action: "",
    },
  ];
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Homepage</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-homepage")}
              >
                Add
              </Button>
            </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
