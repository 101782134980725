import React, { useEffect, useState } from "react";
import styles from "./../retreats.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon, viewIcon } from "../../../../utils/svg.file";
import {
  deleteGettingHere,
  deleteRestaurantdetails,
  deleteRetreatroom,
  ShowGettingList,
  ShowRestaurantDetails,
  ShowRetreatroomList,
  ShowRetreatroomListForRoom,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import DeleteModal from "../../../component/modal/deleteModal";

export default function ManageGettingHere() {
  const navigate = useNavigate();
  const [restaurantList, setRestaurantList] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [gettingList, setGettingList] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const [showValue, setShowValue] = useState("");

  const columns = [
    { field: "sn", headerName: "SNo.", width: 100 },
    { field: "ftitle", headerName: "First Title", height: 700, width: 200 },
    { field: "stitle", headerName: "Second Title", height: 700, width: 200 },
    { field: "ttitle", headerName: "Third Title", height: 700, width: 200 },
    { field: "fotitle", headerName: "Fourth Title", height: 700, width: 200 },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-getting-here/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  // const rows = [{ id: "1", title: "new" }];
  // const handleSearch = (data) => {
  //   if (data) {
  //     let searchData = restaurantList.filter(
  //       (entry) =>
  //         entry.title?.toLowerCase().includes(data.toLowerCase()) ||
  //         entry.retreatLocation?.toLowerCase().includes(data.toLowerCase()) ||
  //         entry.retreatName?.toLowerCase().includes(data.toLowerCase())
  //     );
  //     setSearchedList(searchData);
  //   }
  // };

  // const filteredList = () => {
  //   if (searchedData) {
  //     return searchedList;
  //   } else {
  //     return restaurantList;
  //   }
  // };
  const handleDelete = async (id) => {
    const response = await deleteGettingHere(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
       handleGettingHereList();
    } else {
    }
  };
  const handleGettingHereList = async () => {
    const response = await ShowGettingList();
    if (response.remote === "success") {
      setGettingList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleGettingHereList();
  }, []);
  let rows = [];

  // eslint-disable-next-line array-callback-return
  gettingList?.map((item, index) => {
    rows.push({
      sn: index + 1,
      id: item.id,
      ftitle: item.first_title,
      stitle: item.second_title,
      ttitle: item.third_title,
      fotitle: item.fourth_title,
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Getting Here</h3>
              </div>
              <Button
                onClick={() => navigate("/add-getting-here")}
                className={styles.main_button}
              >
                Add
              </Button>
            </div>

            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select
                  className="form-control ms-1 me-1"
                  onChange={(e) => setShowValue(e.target.value)}
                >
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>

                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              {/* <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchedData(e.target.value);
                }}
              /> */}
            </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
