import React, { useEffect, useState } from "react";
import styles from "./../celebrationEvent.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import {
  addCelebrationBannerImage,
  deleteImage,
  showCelebartionList,
  showRetreatListArray,
} from "../../../../api/adminPanel/giftCardApi";
import { showRetreatList } from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import { UploadIcon, crossRed } from "../../../../utils/svg.file";
import { AddCelebrationType, AddEventBlock } from "../../../../api/adminPanel/celebrationAPI";
import { BlockTwoTone } from "@mui/icons-material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { showRestaurantList } from "../../../../api/adminPanel/exclusiveOfferApi";
import ReactQuill from "react-quill";
export default function AddBlock() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");

  const [blockOneImg, setBlockOneImg] = useState("");
  const [location, setLocation] = useState("1");
  const [description, setDescription] = useState("");
  const [firstDescription, setFirstDescription] = useState("");
  const [offersValue, setOffersValue] = useState("");

  const [retreatList, setRetreatList] = useState([]);

  const [fileList, setFileList] = useState([]);

  const handleRetreatList = async () => {
    const response = await showCelebartionList();

    if (response.remote === "success") {
      setRetreatList(response.data.data);
    } else {
    }
  };

  const twoOnChange = async ({ fileList }) => {
    // setFileList(fileList);
    setBlockOneImg(fileList[0].originFileObj);
  };

  const handleEventAndBlock = async () => {
    const addEventBlock = {
      cardimage: blockOneImg,
      description: description,
      location: location,
      title: title,
    };
    const response = await AddCelebrationType(addEventBlock);

    if (response.remote === "success") {
      swal("Celebration Type Successfully Added");
      navigate("/manage-celebration-block");
    } else {
    }
  };

  useEffect(() => {
    handleRetreatList();
  }, []);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Celebration Block</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  {" "}
                  <label className="fw-bold">Select Celebration Events</label>
                  <Form.Select
                    className={styles.form_input}
                    onChange={(e) => setLocation(e.target.value)}
                  >
                    <option selected>Select Celebration</option>

                    {retreatList?.map((item, index) => (
                      <option value={item.id}>{item.title}</option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Title</label>

                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">
                    Block Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 818 X 818 px)
                    </small>
                  </label>
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={818 / 818}>
                      <Upload
                        maxCount={1}
                        onChange={twoOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Description</label>

                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={description}
                    onChange={setDescription}
                  />
                </Col>
              </Row>

              <Row gutter={[24, 0]}>
                <Col md={12} className="text-center mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => handleEventAndBlock()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
