import React, { useEffect, useState } from "react";
import styles from "./../crms.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditAccolades from "./editAccolades";
import { viewAccolades } from "../../../../api/adminPanel/accoladesApi";

export default function ViewAccolades() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [detail, setDetails] = useState([]);
  const handleAccoladesDetail = async (id) => {
    const response = await viewAccolades(id);
    if (response.remote === "success") {
      setCategory(
        response.data.data.category === "1"
          ? "Restaurants"
          : response.data.data.category === "2"
          ? "Hotel & Resorts & Villa"
          : null
      );
      setLocation(response.data.data.location);
      setDetails(JSON.parse(response.data.data.text));

    } else {
    }
  };
  useEffect(() => {
    handleAccoladesDetail(id);
  }, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>View Accolades</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <div className="">
                    <span className="">{category}</span>
                    <div className="accolades-list-div mt-2">
                      <h4> {location}</h4>
                      <ul>
                        {detail.map((item, index) => {
                          return (
                            <>
                              <li key={index}>{item.text} </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
