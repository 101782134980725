import React, { useEffect, useState } from "react";
import styles from "./../cruise.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import swal from "sweetalert";
import {
  UploadIcon,
  addInputIcon,
  crossRed,
  deleteIcon,
} from "../../../../utils/svg.file";
import { addGallerySliderImage } from "../../../../api/adminPanel/restaurantApi";
import {
  AddCruiseEnquiryDetails,
  deleteImage,
  showCruiseList,
} from "../../../../api/adminPanel/giftCardApi";
export default function AddCruiseEnquirySection() {
  const navigate = useNavigate();
  const [mapImage, setMapImage] = useState("");
  const [imageBannerArray, setImageBannerArray] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [bannerList, setbannerList] = useState([]);
  const [inclusion, setInclusion] = useState("");
  const [stayLonger, setStayLonger] = useState("");
  const [itenary, setItenary] = useState("");
  const [selectedCruise, setSelectedCruise] = useState("");
  const [firstTiItle, setFirstTiItle] = useState("");
  const [secondTiItle, setSecondTiItle] = useState("");
  const [packageImage, setPackageImage] = useState("");
  const [packageTitle, setPackageTitle] = useState("");

  const [dayField, setDayField] = useState([
    {
      dayDescription: "",
      dayCount: "",
      dayTime: "",
    },
  ]);
  const handleDayChange = (index, event) => {
    let data = [...dayField];
    data[index][event.target.name] = event.target.value;
    setDayField(data);
  };
  const addDay = () => {
    let newDayField = {
      dayDescription: "",
      dayCount: "",
      dayTime: "",
    };

    setDayField([...dayField, newDayField]);
  };
  const removeDayField = (index) => {
    let data = [...dayField];
    data.splice(index, 1);
    setDayField(data);
  };
  const [faqField, setFaqField] = useState([
    {
      question: "",
      answer: "",
    },
  ]);
  const handleFaqChange = (index, event) => {
    let data = [...faqField];
    data[index][event.target.name] = event.target.value;
    setFaqField(data);
  };
  const addFaq = () => {
    let newFaqField = {
      question: "",
      answer: "",
    };

    setFaqField([...faqField, newFaqField]);
  };
  const removeFaqField = (index) => {
    let data = [...faqField];
    data.splice(index, 1);
    setFaqField(data);
  };

  const onChange = async ({ fileList }) => {
    setMapImage(fileList[0].originFileObj);
  };

  const onPackageChange = async ({ fileList }) => {
    setPackageImage(fileList[0].originFileObj);
  };
  const BannerOnChange = async ({ fileList }) => {
    setFileList(fileList);
    // setImageBannerArray(fileList[0].originFileObj);
    const addGalleryPayload = {
      galleryImage: fileList[0].originFileObj,
    };
    const response = await addGallerySliderImage(addGalleryPayload);

    if (response.remote === "success") {
      swal("Image Successfully Added");
      setImageBannerArray([...imageBannerArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };

  const handelRetreatBanner = async () => {
    const response = await showCruiseList();
    if (response.remote === "success") {
      setbannerList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handelRetreatBanner();
  }, []);

  const handleCruiseEnquiryDetails = async () => {
    const CruiseDetails = {
      cruiseId: selectedCruise,
      mapImage: mapImage,
      itenary: itenary,
      bannerImage: imageBannerArray,
      day: dayField,
      inclusion: inclusion,
      stayLonger: stayLonger,
      faq: faqField,
      firstTitle: firstTiItle,
      secondTitle: secondTiItle,
      title: packageTitle,
      packageImage: packageImage,
    };
    const response = await AddCruiseEnquiryDetails(CruiseDetails);

    if (response.remote === "success") {
      swal("Data Added Successfully");
      navigate("/manage-cruise-enquiry-section");
    } else {
    }
  };
  const handelDeleteImgae = async (image) => {
    const newImageArray = imageBannerArray.filter(
      (imageGalleryArray) => imageGalleryArray !== image
    );
    setImageBannerArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Cruises Package Section</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <label className="fw-bold mt-3">Select Package</label>

                  <Form.Select
                    rows={2}
                    type="text"
                    className={styles.form_input}
                    onChange={(e) => setSelectedCruise(e.target.value)}
                  >
                    {" "}
                    <option selected>Select Package</option>
                    {bannerList?.map((item) => (
                      <option value={item.id}>{item.title}</option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={12}>
                  <label className="fw-bold   ">Package Title</label>

                  <Form.Control
                    type="text"
                    className={styles.form_input}
                    onChange={(e) => setPackageTitle(e.target.value)}
                  ></Form.Control>
                </Col>
                <Col md={3}>
                  {" "}
                  <label className="fw-bold mb-2">
                    Package Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 955 X 950 px)
                    </small>
                  </label>
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={955 / 950}>
                      <Upload
                        maxCount={1}
                        onChange={onPackageChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={3}>
                  {" "}
                  <label className="fw-bold mb-2">
                    Map Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1152 X 648 px)
                    </small>
                  </label>
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={1152 / 648}>
                      <Upload
                        maxCount={1}
                        onChange={onChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={6} className="multi-images">
                  <label className="fw-bold mb-2">
                    Banner Slider Images{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <div className="d-flex align-items-center">
                    <ImgCrop
                      grid
                      aspect={1219 / 757}
                      styles={{ width: "120px" }}
                    >
                      <Upload
                        maxCount={1}
                        onChange={BannerOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                    <ul
                      className={`${styles.multi_image_preview} flex-wrap d-flex`}
                    >
                      {imageBannerArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => handelDeleteImgae(item)}
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Itenary</label>
                  <ReactQuill
                    onChange={setItenary}
                    className={styles.form_input}
                  />
                </Col>
                <Col md={12}>
                  {dayField.map((item, index) => {
                    return (
                      <>
                        <div className="">
                          <Row gutter={[24, 0]} key={index}>
                            <Col md={3}>
                              <label className="fw-bold mt-3">Day Count</label>

                              <Form.Select
                                rows={2}
                                type="text"
                                name="dayCount"
                                className={styles.form_input}
                                defaultValue={item.dayCount}
                                onChange={(event) =>
                                  handleDayChange(index, event)
                                }
                              >
                                {" "}
                                <option selected>Select Day</option>
                                <option value="Day One">Day One</option>
                                <option value="Day Two">Day Two</option>
                                <option value="Day Three">Day Three</option>
                                <option value="Day Four">Day Four</option>
                                <option value="Day Five">Day Five</option>
                                <option value="Day Six">Day Six</option>
                                <option value="Day Seven">Day Seven</option>
                              </Form.Select>
                            </Col>
                            <Col md={3}>
                              <label className="fw-bold mt-3">Time</label>

                              <Form.Control
                                rows={2}
                                type="text"
                                name="dayTime"
                                placeholder="09:00   AM"
                                className={styles.form_input}
                                defaultValue={item.dayTime}
                                onChange={(event) =>
                                  handleDayChange(index, event)
                                }
                              />
                            </Col>
                            <Col md={4}>
                              <label className="fw-bold mt-3">
                                Day Description
                              </label>

                              <Form.Control
                                rows={2}
                                type="text"
                                name="dayDescription"
                                placeholder="Day Description"
                                className={styles.form_input}
                                defaultValue={item.dayDescription}
                                onChange={(event) =>
                                  handleDayChange(index, event)
                                }
                              />
                            </Col>
                            <Col md={2} className="text-end mt-3">
                              <span
                                className={styles.edit_icon_btn}
                                onClick={() => addDay(index)}
                              >
                                {addInputIcon}
                              </span>
                              {dayField.length > 1 ? (
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => removeDayField(index)}
                                >
                                  {deleteIcon}
                                </span>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Description First</label>
                  <ReactQuill
                    onChange={setInclusion}
                    className={styles.form_input}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Description First</label>
                  <ReactQuill
                    onChange={setStayLonger}
                    className={styles.form_input}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Description First Title</label>
                  <Form.Control
                    onChange={(e) => setFirstTiItle(e.target.value)}
                    className={styles.form_input}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Description Second Title</label>
                  <Form.Control
                    onChange={(e) => setSecondTiItle(e.target.value)}
                    className={styles.form_input}
                  />
                </Col>
                <label className="fw-bold mt-3">FAQ Section</label>
                <Col md={12}>
                  {faqField.map((item, index) => {
                    return (
                      <>
                        <div className="">
                          <Row gutter={[24, 0]} key={index}>
                            <Col md={10}>
                              <label className="fw-bold mt-3">Question</label>

                              <textarea
                                rows={2}
                                type="text"
                                name="question"
                                placeholder="Question"
                                className={styles.form_input}
                                defaultValue={item.question}
                                onChange={(event) =>
                                  handleFaqChange(index, event)
                                }
                              ></textarea>
                            </Col>

                            <Col md={2} className="text-end mt-3">
                              <span
                                className={styles.edit_icon_btn}
                                onClick={() => addFaq(index)}
                              >
                                {addInputIcon}
                              </span>
                              {faqField.length > 1 ? (
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => removeFaqField(index)}
                                >
                                  {deleteIcon}
                                </span>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          <Row gutter={[24, 0]} key={index}>
                            <Col md={10}>
                              <label className="fw-bold">Answer</label>
                              <textarea
                                row={2}
                                type="text"
                                name="answer"
                                placeholder="Answer"
                                className={styles.form_input}
                                defaultValue={item.answer}
                                onChange={(event) =>
                                  handleFaqChange(index, event)
                                }
                              ></textarea>
                            </Col>
                          </Row>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleCruiseEnquiryDetails}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
