import React, { useEffect, useState } from "react";
import styles from "./../cruise.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { imageListClasses } from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import {
  addCelebrationBannerImage,
  addCelebrationData,
  AddCruiseDetails,
  AddRestaurantDetails,
  deleteImage,
  showRestaurantName,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import {
  UploadIcon,
  addInputIcon,
  crossRed,
  deleteIcon,
} from "../../../../utils/svg.file";
import {
  SendToMobile,
  SettingsRemoteOutlined,
  UploadOutlined,
} from "@mui/icons-material";
import Base from "antd/es/typography/Base";
import { addGallerySliderImage } from "../../../../api/adminPanel/restaurantApi";
export default function AddCruisePackageAndDetail() {
  const navigate = useNavigate();

  const [fileList, setFileList] = useState([]);
  const [titledata, setTitledata] = useState("");
  const [descriptiontitledata, setDescriptionTitledata] = useState("");
  const [categorydata, setCategorydata] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [imageBannerArray, setImageBannerArray] = useState([]);

  const onChange = async ({ fileList }) => {
    setCardImage(fileList[0].originFileObj);
  };
  const BannerOnChange = async ({ fileList }) => {
    const addGalleryPayload = {
      galleryImage: fileList[0].originFileObj,
    };
    const response = await addGallerySliderImage(addGalleryPayload);

    if (response.remote === "success") {
      swal("Image Successfully Added");
      setImageBannerArray([...imageBannerArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };

  const handleRestaurantDetails = async () => {
    const restaurantData = {
      title: titledata,
      cardImage: cardImage,
      descriptionTitle: descriptiontitledata,
      bannerImage: imageBannerArray,
      short_description: shortdescription,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    };
    const response = await AddCruiseDetails(restaurantData);

    if (response.remote === "success") {
      swal("Data Added Successfully");
      navigate("/manage-cruise-package-and-detail");
    } else {
    }
  };

  const handelDeleteImgae = async (image) => {
    const newImageArray = imageBannerArray.filter(
      (imageGalleryArray) => imageGalleryArray !== image
    );
    setImageBannerArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Activity & Banner</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={3}>
                  {" "}
                  <label className="fw-bold">
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 818 X 814 px)
                    </small>
                  </label>
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={818 / 814}>
                      <Upload
                        maxCount={1}
                        onChange={onChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={8} className="multi-images">
                  <label className="fw-bold">
                    Banner Slider Images{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <div className="d-flex align-items-center">
                    <ImgCrop
                      grid
                      aspect={818 / 814}
                      styles={{ width: "120px" }}
                    >
                      <Upload
                        maxCount={1}
                        onChange={BannerOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                    <ul className={`${styles.multi_image_preview} flex-wrap`}>
                      {imageBannerArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => handelDeleteImgae(item)}
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setTitledata(e.target.value)}
                  />
                  <label className="fw-bold">Short Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setShortDescription(e.target.value)}
                  />
                </Col>

                <Col md={6}></Col>
                <Col md={12}>
                  <label className="fw-bold">Detailed Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Detailed Description"
                    className={styles.form_input}
                    onChange={(e) => setDescriptionTitledata(e.target.value)}
                  />
                </Col>
                <label className="fw-bold">Enter Meta</label>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      className={styles.form_input}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      className={styles.form_input}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleRestaurantDetails}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
