import React, { useEffect, useState } from "react";
import styles from "./../retreats.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { imageListClasses } from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import { UploadIcon, crossRed } from "../../../../utils/svg.file";
import {
  addCelebrationImage,
  addGiftImage,
  AddRestaurantBanner,
  AddRetreatBanner,
  deleteImage,
  findRetreatBanner,
  updateRetreatBanner,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
export default function EditRetreatsLandingPageBanner() {
  const navigate = useNavigate();
  const {id} =useParams();
  const [description, setDescription] = useState("");
  const [imgHome, setImgBanner] = useState("");
  const [title, setTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const onChange = ({ fileList }) => {
    setFileList(fileList);
    setImgBanner(fileList[0].originFileObj);
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const handelRetreatBanner = async () => {
    const retreatData = {
      image: imgHome,
      description: description,
      id: id,
      title: title,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    };

    const response = await updateRetreatBanner(retreatData);

    if (response.remote === "success") {
      setDescription("");
      swal("Data Added Successfully!");
      navigate("/manage-retreats-landing-page-banner");
    } else {
      swal("Something Went Wrong!");
    }
  };
  const handelRetreatBannerDetails = async (id) => {
    

    const response = await findRetreatBanner(id);

    if (response.remote === "success") {
      
      setDescription(response.data.data.description)
      setTitle(response.data.data.title)
      setImgBanner(response.data.data.image)
      setMetaTitle(response.data.data.meta_title);
      setMetaDescription(response.data.data.meta_description);
      
    } else {
      swal("Something Went Wrong!");
    }
  };

  useEffect(() => {
    handelRetreatBannerDetails(id)
    
  }, [id])
  
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Landing Page Banner</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={4}>
                  {" "}
                  <label className="fw-bold">
                    Banner Images{" "}
                    <small className="text-success">
                      (Max Size 1219 X 757 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={3 / 2} quality={0.9}>
                    <Upload
                      action={Base_URL}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                      className="banner_single"
                    >
                      {fileList.length < 1 && (
                        <>
                          <img src={`${Offers_URL}${imgHome}`} alt="" />
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>{" "}
                <Col md={8}>
                  <label className="fw-bold">Title</label>

                  <Form.Control
                    type="text"
                    placeholder="title"
                    className={styles.form_input}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />

                  <label className="fw-bold">Short Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
                <label className="fw-bold">Enter Meta</label>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      className={styles.form_input}
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      className={styles.form_input}
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>

                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handelRetreatBanner}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
