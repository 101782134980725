import React, { useEffect, useState } from "react";
import styles from "./adminSetting.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  showAdminSettingData,
  submitAdminSetting,
} from "../../../api/adminPanel/adminSetting";
import swal from "sweetalert";
export default function ManageAdminSetting() {
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [youtube, setYoutube] = useState("");
  const [host, setHost] = useState("");
  const [mailer, setMailer] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [port, setPort] = useState("");
  const [copywrite, setCopywrite] = useState("");
  const navigate = useNavigate();
  const handleGetAdminSettingData = async () => {
    const response = await showAdminSettingData();

    if (response.remote === "success") {
      setInstagram(response.data.data[0].value);
      setFacebook(response.data.data[1].value);
      setYoutube(response.data.data[2].value);
      setCopywrite(response.data.data[3].value);
      setHost(response.data.data[4].value);
      setMailer(response.data.data[5].value);
      setUsername(response.data.data[6].value);
      setPassword(response.data.data[7].value);
      setPort(response.data.data[8].value);

    } else {
    }
  };

  const handleUpdateAdminSetting = async () => {
    const updateAdminSettingPayload = {
      instagram: instagram,
      facebook: facebook,
      youtube: youtube,
      host: host,
      mailer: mailer,
      username: username,
      password: password,
      port: port,
      copywrite: copywrite,
    };
    const response = await submitAdminSetting(updateAdminSettingPayload);

    if (response.remote === "success") {
      swal("Admin Setting Successfully Update!");
    } else {
    }
  };
  useEffect(() => {
    handleGetAdminSettingData();
  }, []);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Admin Setting</h3>
              </div>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Instagram Link"
                    className={styles.form_input}
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Facebook Link"
                    className={styles.form_input}
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Youtube Link"
                    className={styles.form_input}
                    value={youtube}
                    onChange={(e) => setYoutube(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Copywrite Year"
                    className={styles.form_input}
                    value={copywrite}
                    onChange={(e) => setCopywrite(e.target.value)}
                  />
                </Col>

                <Col md={6}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Mailer"
                    className={styles.form_input}
                    value={mailer}
                    onChange={(e) => setMailer(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Host Name"
                    className={styles.form_input}
                    value={host}
                    onChange={(e) => setHost(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Port"
                    className={styles.form_input}
                    value={port}
                    onChange={(e) => setPort(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Username"
                    className={styles.form_input}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Password"
                    className={styles.form_input}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Col>
                {/* <Col md={6}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Mail Encryption"
                    className={styles.form_input}
                  />
                </Col> */}

                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => {
                      handleUpdateAdminSetting();
                    }}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
