import React, { useState } from "react";
import styles from "./../job.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { addjobdescription } from "../../../../api/user";
import ReactQuill from "react-quill";
export default function AddDescription() {
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("")
  // const handleJobDiscription =() =>{
  //   const addCategoryPayload = {
  //     description: description,
  //   };
  //   const response = await addjobdescription(addCategoryPayload)

  //   if (response.remote === "success") {

  //     // navigate("/manage-job");
  //     navigate("/manage-job-list")
  //     // showCategory()
  //     setCategory("");
  //   } else {

  //   }
  // }

  const handleJobDiscription = async () => {
    const addCategoryPayload = {
      discription: description,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    };
    const response = await addjobdescription(addCategoryPayload);

    if (response.remote === "success") {
      navigate("/manage-job-description");
    } else {
    }
  };
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Job page Description</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                {/* <Col md={12}>
                  {" "}
                  <Form.Control
                    type="file"
                    placeholder="Upload Banner"
                    className={styles.form_input}
                  />
                </Col> */}

                <Col md={12}>
                  {" "}
                  <ReactQuill
                    // theme="snow"

                    placeholder="Description"
                    className={styles.form_input}
                    onChange={setDescription}
                  />
                </Col>
                <label className="fw-bold">Enter Meta</label>
      <Col md={12}>
        {" "}
        <Form.Control
          type="text"
          placeholder="Meta Title"
          className={styles.form_input}
          onChange={(e) => setMetaTitle(e.target.value)}
        />
      </Col>
      <Col md={12}>
        {" "}
        <Form.Control
          type="text"
          placeholder="Meta Description"
          className={styles.form_input}
          onChange={(e) => setMetaDescription(e.target.value)}
        />
      </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => handleJobDiscription()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
