import React, { useEffect, useState } from "react";
import styles from "./../experience.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { imageListClasses } from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Featured_URL, Offers_URL } from "../../../../serverUrl";
import { crossRed } from "../../../../utils/svg.file";
import {
  addCelebrationImage,
  addGiftImage,
  deleteImage,
  findExperienceCategory,
  findRetreatCategory,
  updateExperienceCategory,
  updateretreatCategory,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
export default function EditExperienceCategory() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  // const [state, setState] = useState("");
  const handleExperienceCategory = async () => {
    const response = await findExperienceCategory(id);
    if (response.remote === "success") {
      setName(response.data.data.title);
    } else {
    }
  };

  const handelupdateExperienceCategory = async () => {
    const updateRetreatdata = {
      id: id,
      name: name,
    };
    const response = await updateExperienceCategory(updateRetreatdata);
    if (response.remote === "success") {
      swal("Data Addedd Successfully");
      navigate("/manage-experience-category");
    } else {
      swal("Something Went Wrong");
    }
  };
  useEffect(() => {
    handleExperienceCategory(id);
  }, [id]);

  return (
    <>
      <div className="multi-images">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Stories Category </h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Category Name</label>
                  <Form.Control
                    type="text"
                    className={styles.form_input}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>

                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handelupdateExperienceCategory}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
