import React, { useState } from "react";
import styles from "./../crms.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { Divider } from "@mui/material";
import { addInputIcon, deleteIcon } from "../../../../utils/svg.file";
import swal from "sweetalert";
import { addAccolades } from "../../../../api/adminPanel/accoladesApi";
export default function AddAccolades() {
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [contactField, setContactField] = useState([
    {
      text: "",
      order: "",
    },
  ]);
  const handleFormChange = (index, event) => {
    let data = [...contactField];
    data[index][event.target.name] = event.target.value;
    setContactField(data);
  };

  const addFields = () => {
    let newfield = {
      text: "",
      order: "",
    };

    setContactField([...contactField, newfield]);
  };
  const removeFields = (index) => {
    let data = [...contactField];
    data.splice(index, 1);
    setContactField(data);
  };

  const handleSubmitAccolades = async () => {
    const accoladesPayload = {
      location: location,
      category: category,
      contactField: contactField,
    };
    const response = await addAccolades(accoladesPayload);

    if (response.remote === "success") {
      swal("Accolades succesfully Added!");
      navigate("/manage-accolades");
    } else {
    }
  };
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Accolades</h3>
              </div>
              <Button onClick={() => navigate(-1)} className={styles.main_button}>Back</Button>
            </div>
            <Form>
              <div className="contact_form">
                <Row>
                  <Col md={12}>
                    <Row gutter={[24, 0]}>
                      <Col md={6}>
                        {" "}
                        <Form.Group className="">
                          <Form.Select
                            name="category"
                            className={styles.form_input}
                            defaultValue={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option selected>Select Category</option>

                            <option value="2">Hotel & Resorts & Villa</option>
                            <option value="1">Restaurants</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        {" "}
                        <Form.Control
                          type="text"
                          name="location"
                          placeholder="Location"
                          className={styles.form_input}
                          defaultValue={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                      </Col>
                    </Row>
                    {contactField.map((item, index) => {
                      return (
                        <>
                          <Row
                            className="justify-content-between detail-input"
                            key={index}
                          >
                             <Col md={8}>
                              <textarea
                                type="text"
                                name="text"
                                placeholder="text"
                                className={styles.form_input}
                                defaultValue={item.text}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              />
                              
                            </Col>{" "}
                            <Col md={2} >
                            <Form.Control
                                type="number"
                                name="order"
                                placeholder="Order No."
                                className={styles.form_input}
                                defaultValue={item.order}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              />
                            </Col>
                            <Col md={2} className="text-end">
                              {/* {contactField.length < 2 ? (
                                <> */}{" "}
                              <span
                                className={styles.edit_icon_btn}
                                onClick={addFields}
                              >
                                {addInputIcon}
                              </span>
                              {/* </>
                              ) : null} */}
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() => removeFields(index)}
                              >
                                {deleteIcon}
                              </span>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="text-center  mt-5">
                    <Button
                      className={styles.main_button}
                      onClick={() => handleSubmitAccolades()}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
