import axiosInstance from "../api";
const token = localStorage.getItem("token");

export const addGallerySliderImage = async (giftImgData) => {
  const data = new FormData();
  data.append("galleryImage", giftImgData.galleryImage);
  const response = await axiosInstance.request({
    url: "addrestaurantGallery",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
