import React, { useEffect, useState } from "react";
import styles from "./../exclusiveOffer.module.css";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon } from "../../../../utils/svg.file";
import {
  deleteExclusiveCategory,
  showExclusiveCategoryList,
} from "../../../../api/adminPanel/exclusiveOfferApi";
import swal from "sweetalert";
import DeleteModal from "../../../component/modal/deleteModal";
import { Box } from "@mui/material";

export default function ManageExclusiveOfferCategory() {
  const navigate = useNavigate();
  const [deleteId, setDeletedId] = useState("");
  const [exclusiveOfferList, setExclusiveOfferList] = useState([]);
  const [retreatList, setRetreatList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [showListModal, setShowListModal] = useState("");

  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleShowListModal = (id) => {
    setShowListModal(true);
    setShowDeleteModal(false);
  };
  const hideListModal = () => {
    setShowListModal(false);
  };
  const handleDelete = async (id) => {
    const response = await deleteExclusiveCategory(id);

    if (response.remote === "success") {
      if(response.data.errors){
        setRetreatList(response.data.data)
        handleShowListModal()
      }else{

        hideDeleteModal();
        swal("Deleted Successfully!");
        handleGetExclusiveCategoryList();
      }
    } else {
    }
  };
  const handleGetExclusiveCategoryList = async (id) => {
    const response = await showExclusiveCategoryList(id);
    if (response.remote === "success") {
      setExclusiveOfferList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleGetExclusiveCategoryList();
  }, []);

  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },

    { field: "name", headerName: "Category Name", width: 350 },
    { field: "state", headerName: "Country", width: 300 },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(
                  `/edit-exclusive-offer-category/${cellValues.row.id}`
                );
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];

  // let rows = [
  //   {
  //     id: 1,
  //     name: "fdsdfggs",
  //     state: "malaysia",
  //   },
  // ];

  let rows = [];

  exclusiveOfferList?.map((item, index) => {
    let location;
    if (Number(item.other) === 1) {
      location = "Malasiya";
    } else {
      location = "Singapore";
    }
    rows.push({
      id: item.id,
      name: item.title,
      state: location,
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Offers Category</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-exclusive-offer-category")}
              >
                Add Category
              </Button>
            </div>
            <div className="table-search d-flex justify-content-between mb-2">
              <div className="show_entries d-flex align-items-center">
                Show
                <select className="form-control ms-1 me-1">
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>

                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
      <Modal show={showDeleteModal} onHide={hideDeleteModal}>
        <Modal.Body className="text-center">
          <h4> Are You Sure You want to Deletes ?</h4>
          <div className="w-100 mt-3">
            {" "}
            <Button variant="secondary" onClick={hideDeleteModal}>
              Close
            </Button>
            <Button
              className="ms-4"
              variant="danger"
              onClick={() => {
                handleDelete(deleteId);
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* -==-=-=--=-=-=-=-List show modal-=-=-=-=-=- */}
      <Modal show={showListModal} onHide={hideListModal}>
      <Modal.Body className="text-center">
  <Box>
    <h4>This category has been added bleow offers move  given offers in other categories then you''ll be able to delete category.</h4>
    <Box sx={{ textAlign: "left" }} className="modal_list">
      <ul className={styles.border_list}> 
        {retreatList?.map((item) => ( 
          <li className={styles.list_item}> 
            {item.title} - {item.country}
          </li>
        ))}
      </ul>
    </Box>
    <div className="w-100 mt-3 text-center">
      <Button variant="secondary" onClick={hideListModal}>
        Close
      </Button>
    </div>
  </Box>
</Modal.Body>

      </Modal>
    </>
  );
}
