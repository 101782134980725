import React, { useState } from "react";
import styles from "./../job.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { addCategory, showCategory } from "../../../../api/user";
import { useEffect } from "react";
import {
  editCategory,
  updateCategory,
} from "../../../../api/adminPanel/jobapi";
import swal from "sweetalert";
export default function EditCategory(props) {
  let { id } = useParams();
  const [category, setCategory] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const handleGetCategoryData = async (id) => {
    const response = await editCategory(id);
    
    if (response.remote === "success") {
      setCategory(response.data.data.name);
    } else {
    }
  };

  const handleUpdateCategory = async (id) => {
    const updateCategoryPayload = {
      category: category,
    };
    const response = await updateCategory(updateCategoryPayload, id);

    if (response.remote === "success") {
      swal("Job Category Successfully Update!");
      navigate("/manage-category");
    } else {
    }
  };
  useEffect(() => {
    handleGetCategoryData(id);
  }, []);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Job Category</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Category"
                    className={styles.form_input}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  />
                </Col>

                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => {
                      handleUpdateCategory(id);
                    }}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
