import React, { useEffect, useState } from "react";
import styles from "./ticket.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Base_URL } from "../../../serverUrl";

// import { Upload } from "";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { UploadIcon, addInputIcon, deleteIcon } from "../../../utils/svg.file";
import { Box } from "@mui/material";
import { showExclusiveOfferList } from "../../../api/adminPanel/exclusiveOfferApi";
import swal from "sweetalert";
import { addTicketDetail } from "../../../api/adminPanel/travelApi";

export default function AddTicket() {
  const [file, setFile] = useState("");
  const [imgSmall, setImgSmall] = useState("");
  const [titledata, setTitleData] = useState("");
  const [offerData, setOfferData] = useState("");
  const [imageBanner, setImageBanner] = useState("");
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
  // const handleChange = (value) => {
  // };

  const navigate = useNavigate();
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const [fileList, setFileList] = useState([]);
  const [fileLists, setFileLists] = useState([]);

  const onChange = ({ fileList }) => {
    setFileList(fileList);
    setImgSmall(fileList[0].originFileObj);
  };
  const TicketImgOnChange = async ({ fileList }) => {
    setFileLists(fileList);
    setImageBanner(fileList[0].originFileObj);
  };

  const onPreviewSmall = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const onPreviewLarger = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const [ticketArray, setTicketArray] = useState([
    {
      // ticketOffer: "",
      ticketTitle: "",
      // ticketImg: "",
      ticketAmount: "",
      ticketQuantity: "",
      ticketDescription: "",
    },
  ]);
  const handleTicketChange = (index, event) => {
    let data = [...ticketArray];
    data[index][event.target.name] = event.target.value;
    setTicketArray(data);
  };
  const addTicket = (index) => {
    let newTicketArray = {
      // ticketOffer: "",
      ticketTitle: "",
      // ticketImg: "",
      ticketAmount: "",
      ticketQuantity: "",
      ticketDescription: "",
    };

    setTicketArray([...ticketArray, newTicketArray]);
  };
  const removeTicket = (index) => {
    let data = [...ticketArray];
    data.splice(index, 1);
    setTicketArray(data);
  };
  const handleSubmit = async () => {
    const addTicketdata = {
      ticket: ticketArray,
      image: imgSmall,
      title: titledata,
      offer: offerData,
    };
    const response = await addTicketDetail(addTicketdata);

    if (response.remote === "success") {
      if (response.data.errors) {
        swal(response.data.errors);
      } else {
        swal("Data Added Successfully");
        navigate("/manage-ticket");
      }
    } else {
    }
  };
  const [exclusiveOfferList, setExclusiveOfferList] = useState([]);
  const handleGetExclusiveOfferList = async () => {
    const response = await showExclusiveOfferList();
    if (response.remote === "success") {
      setExclusiveOfferList(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleGetExclusiveOfferList();
  }, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Ticket</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <label className="fw-bold mb-3">Manage Page Detail</label>
              <Row>
                <Col md={6}>
                  {" "}
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setTitleData(e.target.value)}
                  />{" "}
                  <label className="fw-bold">Select Offers</label>
                  <Form.Select
                    className={styles.form_input}
                    aria-label="Default select example"
                    onChange={(e) => setOfferData(e.target.value)}
                  >
                    <option selected disabled>
                      Select Offers
                    </option>
                    {exclusiveOfferList?.map((item, index) => (
                      <option value={item.id}>
                        {item.title},{item.country}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 565 X 335 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={565 / 335}>
                    <Upload
                      action={Base_URL}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      // onPreview={onPreviewSmall}
                    >
                      {fileList.length < 1 && (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>
              </Row>
              {/* <hr></hr> */}
              <label className="fw-bold mt-3">Manage Ticket Section</label>
              {ticketArray.map((item, index) => {
                return (
                  <>
                    <hr></hr>
                    <Row>
                      <Col md={10}>
                        <Row gutter={[24, 0]}>
                          <Col md={7}>
                            <label className="fw-bold">Title</label>
                            <Form.Control
                              type="text"
                              name="ticketTitle"
                              defaultValue={item.ticketTitle}
                              placeholder="Title"
                              className={styles.form_input}
                              onChange={(event) =>
                                handleTicketChange(index, event)
                              }
                            />

                            <div className="d-flex w-100 justify-content-between">
                              <Box>
                                <label className="fw-bold">Amount</label>
                                <Form.Control
                                  type="number"
                                  placeholder="Amount"
                                  name="ticketAmount"
                                  defaultValue={item.ticketAmount}
                                  className={styles.form_input}
                                  style={{ width: "90%" }}
                                  onChange={(event) =>
                                    handleTicketChange(index, event)
                                  }
                                />
                              </Box>
                              <Box>
                                <label className="fw-bold">Quantity</label>
                                <Form.Control
                                  type="number"
                                  name="ticketQuantity"
                                  defaultValue={item.ticketQuantity}
                                  placeholder="Quantity"
                                  className={styles.form_input}
                                  onChange={(event) =>
                                    handleTicketChange(index, event)
                                  }
                                />
                              </Box>
                            </div>
                          </Col>

                           {/* <Col md={5}>
                            <div className="d-flex">
                              <div className="">
                                <label className="fw-bold">
                                  Preview Image{" "}
                                  <small className="text-success small-msg">
                                    (Max. Size 100 X 66 px)
                                  </small>
                                </label>
                                <ImgCrop grid aspect={100 / 66}>
                                  <Upload
                                    action={Base_URL}
                                    listType="picture-card"
                                    fileList={fileLists}
                                    name="ticketImg"
                                    defaultValue={item.ticketImg}
                                    onChange={TicketImgOnChange}
                                    // onPreview={onPreviewLarger}
                                  >
                                    {fileLists.length < 1 && (
                                      <>
                                        <div className="file_upload_div">
                                          <span>{UploadIcon}</span>
                                          <p>Click Here to Upload</p>
                                        </div>
                                      </>
                                    )}
                                  </Upload>
                                </ImgCrop>
                              </div>
                            </div>
                          </Col>  */}
                          <Col md={12}>
                            {" "}
                            <label className="fw-bold">Description</label>
                            <textarea
                              rows="3"
                              type="text"
                              name="ticketDescription"
                              defaultValue={item.ticketDescription}
                              placeholder="Description"
                              className={styles.form_input}
                              onChange={(event) =>
                                handleTicketChange(index, event)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md={2} className="text-end">
                        {" "}
                        <span
                          className={styles.edit_icon_btn}
                          onClick={() => addTicket(index)}
                        >
                          {addInputIcon}
                        </span>
                        {ticketArray.length > 1 ? (
                          <span
                            className={styles.delete_icon_btn}
                            onClick={() => removeTicket(index)}
                          >
                            {deleteIcon}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>{" "}
                    {/* <hr></hr> */}
                  </>
                );
              })}

              <Row>
                <Col md={12} className="text-center  mt-5">
                  <Button className={styles.main_button} onClick={handleSubmit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      {/* <CropperModal
        file={file}
        showCropModal={showCropModal}
        hideCropModal={hideCropModal}
        handleShowCropModal={handleShowCropModal}
      /> */}
    </>
  );
}
