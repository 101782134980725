import React, { useEffect, useState } from "react";
import styles from "./../travel.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { deleteTravelName, findTravelName, updateTravelName } from "../../../../api/adminPanel/travelApi";
import swal from "sweetalert";
export default function EditTravelCategory() {
  let {id} = useParams();
  const navigate = useNavigate();
  const [names, setName] = useState("");
  const [state, setState] = useState("");
  const handleTarvelCategory = async () => {
    const response = await findTravelName(id);
    if (response.remote === "success") {
      setName(response.data.data.name);
    } else {
    }
  };
  const handeltravelCategory = async () => {
    const updateTraveldata = {
      id : id,
      name: names
    };
    const response = await updateTravelName(updateTraveldata);
    if (response.remote === "success") {
      swal('Data Addedd Successfully')
      navigate('/manage-travel-category')
    } else {
    }
  };
  useEffect(() => {
    handleTarvelCategory()
  }, [id]);
  return (
    <>
      <div className="multi-images">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Update Travel Category</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Travel Category Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Restaurant"
                    className={styles.form_input}
                    value={names}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>

                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handeltravelCategory}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
