import React from "react";
import { DataGrid } from "@mui/x-data-grid";
export default function Table(props) {
return (
  <>
    <div
      style={{ width: "100%" }}
      className={` ${
        props.showValue === "10"
          ? "table_height_1300"
          : props.showValue === "25"
          ? "table_height_2000"
          : props.showValue === "50"
          ? "table_height_3000"
          : props.rows.length > 5
          ? "table_height_800"
          : "table_height_400"
      }`}
    >
      <DataGrid
        rows={props.rows}
        columns={props.columns}
        pageSize={props.showValue ? Number(props.showValue) : 50}
        rowsPerPageOptions={[props.showValue ? Number(props.showValue) : 50]}
        // getEstimatedRowHeight={() => 100}
        // getRowHeight={() => "auto"}
      />
    </div>
  </>
);
}
