import React, { useEffect, useState } from "react";
import styles from "./../travel.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { addTravelName } from "../../../../api/adminPanel/travelApi";
export default function AddTravelCategory() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const handeltravelCategory = async () => {
    const travelData = {
      name: name
    };
    const response = await addTravelName(travelData);
    if (response.remote === "success") {
      swal('Data Addedd Successfully')
      navigate('/manage-travel-category')
    } else {
    }
  };
  useEffect(() => {

  }, []);
  return (
    <>
      <div className="multi-images">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Travel Category</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Travel Category Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    className={styles.form_input}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>

                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handeltravelCategory}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
