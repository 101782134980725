import React, { useEffect, useState } from "react";
import styles from "./ticket.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../component/table";
import { deleteIcon, editIcon } from "../../../utils/svg.file";
import {
  deleteFeaturedStory,
  showFeaturedStoryList,
} from "../../../api/adminPanel/featuredStory";
import { Featured_URL, Offers_URL } from "../../../serverUrl";
import DeleteModal from "../../component/modal/deleteModal";
import swal from "sweetalert";
import {
  deleteExclusiveOffer,
  deleteManageTicket,
  listManageTicket,
  showExclusiveOfferList,
} from "../../../api/adminPanel/exclusiveOfferApi";
import IMAGES from "../../assets/images";

export default function ManageTicket() {
  const navigate = useNavigate();
  const [manageList, setManageList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [showValue, setShowValue] = useState("");
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleManageList = async () => {
    const response = await listManageTicket();
    if (response.remote === "success") {
      setManageList(response.data.data);
    } else {
    }
  };

  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    { field: "title", headerName: "Title", height: 200, width: 200 },
    { field: "offer", headerName: "Offer", height: 200, width: 200 },
    // { field: "amount", headerName: "Amount", height: 200, width: 200 },

    // { field: "quantity", headerName: "Quantity", height: 200, width: 200 },

    // {
    //   headerName: "Banner",
    //   field: "image",
    //   height: 300,
    //   width: 500,
    //   renderCell: (cellValues) => {
    //     return (
    //       <>
    //         <img
    //           src={`${Offers_URL}${cellValues.row.image}`}
    //           className={styles.table_img}
    //         />
    //       </>
    //     );
    //   },
    // },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-ticket/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleManageList();
  }, []);

  const handleDelete = async (id) => {
    const response = await deleteManageTicket(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
      handleManageList();
      // handleGetExclusiveOfferList();
    } else {
    }
  };
  // let rows = [
  //   {
  //     id: "1",
  //     title: "title One",
  //     offer: "tamarind hill",
  //   },
  // ];
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Ticket</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-ticket")}
              >
                Add Ticket
              </Button>
            </div>
            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select
                  className="form-control ms-1 me-1"
                  onChange={(e) => setShowValue(e.target.value)}
                >
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>

                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
              />
            </div>
            <Table rows={manageList} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>

      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
