import React, { useState } from "react";
import styles from "./../crms.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon, viewIcon } from "../../../../utils/svg.file";
import { showPrivacyNoticeData } from "../../../../api/adminPanel/privacyNoticeApi";
import { useEffect } from "react";

export default function ManagePrivacyNotice() {
  const navigate = useNavigate();
  const [privacyPage, setPrivacyPage] = useState([]);
  const handleShowPrivacyNotice = async () => {
    const response = await showPrivacyNoticeData();

    if (response.remote === "success") {
      setPrivacyPage(response.data.data[0]);
    } else {
    }
  };

  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    // { field: "description", headerName: "Description", width: 700 },
    {
      field: "description",
      width: 700,
      renderCell: (cellValues) => {
        return (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: cellValues.row.description,
              }}
            />
          </>
        );
      },
    },
    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.view_icon_btn}
              onClick={() => {
                navigate(`/view-privacy-notice/${cellValues.row.id}`);
              }}
            >
              {viewIcon}
            </span>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-privacy-notice/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            {/* <span className={styles.delete_icon_btn}>{deleteIcon}</span> */}
          </>
        );
      },
    },
  ];

  const rows = [
    {
      id: Number(privacyPage?.id),
      description: privacyPage.text,
    },
  ];

  useEffect(() => {
    handleShowPrivacyNotice();
  }, []);
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Privacy Notice</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-privacy-notice")}
              >
                Add Privacy Notice
              </Button>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
    </>
  );
}
