import React, { useState } from "react";
import styles from "./sidemenu.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UploadIcon } from "../../../utils/svg.file";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL } from "../../../serverUrl";
import { addFeaturedStory, addsidebar } from "../../../api/adminPanel/featuredStory";
import swal from "sweetalert";
export default function AddSideMenu() {
  const [imgHome, setImgBanner] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList }) => {
    setFileList(fileList);
    setImgBanner(fileList[0].originFileObj);
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const handleFeatureStory = async () => {
    const addFeaturedStoryPayload = {
      discription: description,
      heading: title,
      image: imgHome,
    };
    const response = await addsidebar(addFeaturedStoryPayload);

    if (response.remote === "success") {
      swal("SideBar Successfully Added");
      navigate("/manage-sidemenu");
      setDescription("");
      setTitle("");
      setImgBanner("");
    } else {
    }
  };

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Side Menu </h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <label className="fw-bold">Select Type</label>
                  <Form.Group className="">
                    <Form.Select className={styles.form_input} onChange={(e) => setTitle(e.target.value)}>
                      <option selected>Choose Retreats </option>
                      <option value='retreat'>Retreats</option>
                      <option value='restaurant'>Restaurant</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <label className="fw-bold">Description</label>

                  <textarea
                    rows="5"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Preview Image</label>

                  <ImgCrop grid aspect={466 / 290}>
                    <Upload
                      action={Base_URL}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                    >
                      {fileList.length < 1 && (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => handleFeatureStory()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
