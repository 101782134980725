import React, { useEffect, useState } from "react";
import styles from "../ticket.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Base_URL } from "../../../../serverUrl";

// import { Upload } from "";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import {
  UploadIcon,
  addInputIcon,
  deleteIcon,
} from "../../../../utils/svg.file";
import { Box } from "@mui/material";

export default function AddTicketBanner() {
  const [file, setFile] = useState("");
  const [imgSmall, setImgSmall] = useState("");
  const [imageBanner, setImageBanner] = useState("");
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
  // const handleChange = (value) => {
  // };

  const navigate = useNavigate();
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList }) => {
    setFileList(fileList);
    setImgSmall(fileList[0].originFileObj);
  };
  const TicketImgOnChange = async ({ fileList }) => {
    // setFileList(fileList);
    setImageBanner(fileList[0].originFileObj);
  };

  const onPreviewSmall = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const [ticketArray, setTicketArray] = useState([
    {
      ticketOffer: "",
      ticketTitle: "",
      ticketImg: "",
      ticketAmount: "",
      ticketQuantity: "",
      ticketDescription: "",
    },
  ]);
  const handleTicketChange = (index, event) => {
    let data = [...ticketArray];
    data[index][event.target.name] = event.target.value;
    setTicketArray(data);
  };
  const addTicket = (index) => {
    let newTicketArray = {
      ticketOffer: "",
      ticketTitle: "",
      ticketImg: "",
      ticketAmount: "",
      ticketQuantity: "",
      ticketDescription: "",
    };

    setTicketArray([...ticketArray, newTicketArray]);
  };
  const removeTicket = (index) => {
    let data = [...ticketArray];
    data.splice(index, 1);
    setTicketArray(data);
  };

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Ticket Page Banner</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row>
                <Col md={6}>
                  <label className="fw-bold">
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 565 X 335 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={565 / 335}>
                    <Upload
                      action={Base_URL}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreviewSmall}
                    >
                      {fileList.length < 1 && (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>
                <Col md={12}>
                  {" "}
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12} className="text-center  mt-5">
                  <Button className={styles.main_button}>Submit</Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      {/* <CropperModal
        file={file}
        showCropModal={showCropModal}
        hideCropModal={hideCropModal}
        handleShowCropModal={handleShowCropModal}
      /> */}
    </>
  );
}
