import React, { useEffect, useState } from "react";
import styles from "./about.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import swal from "sweetalert";
import DeleteModal from "../../../component/modal/deleteModal";
import { editIcon } from "../../../../utils/svg.file";
import {
  deleteTravelPackage,
  showTravelPackage,
} from "../../../../api/adminPanel/travelApi";
import IMAGES from "../../../../assets/images";
import { showAboutContent } from "../../../../api/adminPanel/accoladesApi";
import { Offers_URL } from "../../../../serverUrl";

const { Logo, whiteLogo } = IMAGES;

export default function ManageAboutUs() {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [aboutData, setAboutData] = useState([]);
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    {
      field: "description",
      headerName: "Description",
      height: 300,
      width: 550,
    },
    {
      field: "banner",
      headerName: "Banner",
      renderCell: (cellValues) => {
        return (
          <>
            {" "}
            <img src={`${Offers_URL}${cellValues.row.banner}`} alt="" />
          </>
        );
      },
      height: 300,
      width: 150,
    },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-about-us/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            {/* <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span> */}
          </>
        );
      },
    },
  ];
  const handleAboutList = async () => {
    const response = await showAboutContent();
    if (response.remote === "success") {
      setAboutData(response.data.data);
    } else {
    }
  };
  
  const rows = [
    {
      id: Number(aboutData?.id),
      description:aboutData?.about_text,
      banner:aboutData?.banner,
    },
  ];
  useEffect(() => {
    handleAboutList()
  }, []);

  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage About Us</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-about-us")}
              >
                Add
              </Button>
            </div>
            <Table rows={rows} columns={columns} />
          </div>
        </div>
      </div>
    </>
  );
}
