import React, { useEffect, useState } from "react";
import styles from "./exclusiveOffer.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../component/table";
import { deleteIcon, editIcon } from "../../../utils/svg.file";
import {
  deleteFeaturedStory,
  showFeaturedStoryList,
} from "../../../api/adminPanel/featuredStory";
import { Featured_URL, Offers_URL } from "../../../serverUrl";
import DeleteModal from "../../component/modal/deleteModal";
import swal from "sweetalert";
import {
  deleteExclusiveOffer,
  showExclusiveOfferList,
  updateExclusiveStatuss,
} from "../../../api/adminPanel/exclusiveOfferApi";

export default function ManageExclusiveOffer() {
  const navigate = useNavigate();
  const [exclusiveOfferList, setExclusiveOfferList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [showValue, setShowValue] = useState("");
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleStatus = async (id,status) => {
    const updateExclusiveStatus = {
      id: id,
      status: status,
    };
    const response = await updateExclusiveStatuss(updateExclusiveStatus);

    if (response.remote === "success") {
      
      swal("Offers Status Successfully Update");
      handleGetExclusiveOfferList();
      }
 
  };
  const handleGetExclusiveOfferList = async (id) => {
    const response = await showExclusiveOfferList(id);
    if (response.remote === "success") {
      setExclusiveOfferList(response.data.data);
    } else {
    }
  };

  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    { field: "title", headerName: "Title", height: 200, width: 200 },

    { field: "country", headerName: "Country", height: 300, width: 150 },
    // { field: "category", headerName: "Category", height: 300, width: 150 },

    {
      field: "subCategory",
      headerName: "Sub Category",
      height: 300,
      width: 300,
    },

    {
      field: "package_duration",
      headerName: "Package Duration",
      height: 300,
      width: 150,
    },

    {
      field: "image",
      height: 300,
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <img
              src={`${Offers_URL}${cellValues.row.image}`}
              className={styles.table_img}
            />
          </>
        );
      },
    },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-exclusive-offer/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
    {
      field: "Status",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
          {cellValues.row.status ? 

            <Button variant="contained"  className={`px-2  ${styles.main_button}`} onClick={()=>handleStatus(cellValues.row.id,0)}>Enable</Button>
          :
            <Button variant="contained"   className={`px-2  ${styles.main_button}`} onClick={()=>handleStatus(cellValues.row.id,1)}>Disable</Button>
          }
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetExclusiveOfferList();
  }, []);
  const handleSearch = (data) => {
    if (data) {
      let searchData = exclusiveOfferList.filter(
        (entry) =>
          entry.title?.toLowerCase().includes(data.toLowerCase()) ||
          entry.country?.toLowerCase().includes(data.toLowerCase()) ||
          // entry.category?.toLowerCase().includes(data.toLowerCase()) ||
          entry.subcategory?.toLowerCase().includes(data.toLowerCase())
      );
      setSearchedList(searchData);
    }
  };

  const filteredList = () => {
    if (searchedData) {
      return searchedList;
    } else {
      return exclusiveOfferList;
    }
  };
  let rows = [];

  filteredList().map((item, index) => {
    rows.push({
      id: item.id,
      country: item.country,
      // category: item.category,
      subCategory: item.subcategory,
      image: item.image,
      title: item.title,
      package_duration: item.package_duration,
      status:item.status,
    });
  });

  const handleDelete = async (id) => {
    const response = await deleteExclusiveOffer(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
      handleGetExclusiveOfferList();
    } else {
    }
  };
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Offers</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-exclusive-offer")}
              >
                Add Offers
              </Button>
            </div>
            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select
                  className="form-control ms-1 me-1"
                  onChange={(e) => setShowValue(e.target.value)}
                >
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>

                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchedData(e.target.value);
                }}
              />
            </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>

      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
