import React, { useEffect, useState } from "react";
import styles from "./featuredstory.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../component/table";
import { deleteIcon, editIcon } from "../../../utils/svg.file";
import {
  deleteFeaturedStory,
  showFeaturedStoryList,
} from "../../../api/adminPanel/featuredStory";
import { Featured_URL } from "../../../serverUrl";
import DeleteModal from "../../component/modal/deleteModal";
import swal from "sweetalert";

export default function ManageFeatureStory() {
  const navigate = useNavigate();
  const [featuredList, setFeaturedList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState('')
  const [showValue, setShowValue] = useState("");
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleFeaturedList = async (id) => {
    const response = await showFeaturedStoryList(id);
    if (response.remote === "success") {
      setFeaturedList(response.data.data);
    } else {
    }
  };

  const handleDelete = async (id) => {
    const response = await deleteFeaturedStory(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
    } else {
    }
  };
  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    { field: "heading", headerName: "Heading", height: 300, width: 300 },

    {
      field: "description",
      headerName: "Description",
      height: 300,
      width: 400,
    },
    {
      field: "image",
      height: 300,
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <img
              src={`${Featured_URL}${cellValues.row.image}`}
              className={styles.table_img}
            />
          </>
        );
      },
    },

    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                navigate(`/edit-featured-story/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleFeaturedList();
  }, []);
  const handleSearch = (data) => {
    if (data) {
      let searchData = featuredList.filter((entry) =>
        entry.heading?.includes(data)
      );
      setSearchedList(searchData);
    }
  };

  const filteredList = () => {
    if(searchedData) {
      return searchedList
    } else {
      return featuredList
    }
  }
  let rows = [];

  filteredList().map((item, index) => {
    rows.push({
      id: item.id,
      heading: item.heading,
      description: item.discription,
      image: item.image,
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Featured Story</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-featured-story")}
              >
                Add Featured Story
              </Button>
            </div>
            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select className="form-control ms-1 me-1"  onChange={(e)=>setShowValue(e.target.value)}>
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>


                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchedData(e.target.value);
                }}
              />
            </div>
            <Table rows={rows} columns={columns} showValue={showValue} />
          </div>
        </div>
      </div>

      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
