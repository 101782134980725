import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "../../admin.module.css";
export default function Header() {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.header_section}>
        <ul className="header_menu">
          <li>
            <Dropdown className={styles.header_dropdown}>
              <Dropdown.Toggle id="dropdown-basic">Admin</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#">My Profile</Dropdown.Item>
                <Dropdown.Item href="#">Setting</Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) => {
                    localStorage.clear();
                    navigate("/");
                  }}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </>
  );
}
