import React from "react";
import styles from "./../exclusiveOffer.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import swal from "sweetalert";
import {
  addExclusivecategory,
  FindExclusiveCategoryList,
  updateExclusivecategory,
} from "../../../../api/adminPanel/exclusiveOfferApi";
import { useEffect } from "react";
export default function EditExclusiveOfferCategory() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");

  const handleExclusiveCategory = async () => {
    const addExclusiveOfferPayload = {
      id: id,
      title: title,
      location: location,
    };
    const response = await updateExclusivecategory(addExclusiveOfferPayload);

    if (response.remote === "success") {
      swal("Exclusive Category Successfully Added");
      navigate("/manage-exclusive-offer-category");
    } else {
    }
  };
  const handleFindExclusiveCategory = async (id) => {
    const response = await FindExclusiveCategoryList(id);

    if (response.remote === "success") {
      setTitle(response.data.data.title);
      setLocation(response.data.data.other);
    } else {
    }
  };
  useEffect(() => {
    handleFindExclusiveCategory(id);
  }, [id]);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Offers Category </h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Select Country</label>
                  <Form.Select
                    className={styles.form_input}
                    onChange={(e) => setLocation(e.target.value)}
                    value={location}
                  >
                    <option selected>Select Country</option>

                    <option value="1">Malasia</option>
                    <option value="2">Singapore</option>
                  </Form.Select>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Category Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    className={styles.form_input}
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />
                </Col>

                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleExclusiveCategory}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
