import axiosInstance from "./../api";
const token = localStorage.getItem("token");

export const addContactDetail = async (contactDetailData) => {
  const data = new FormData();
  data.append("other", JSON.stringify(contactDetailData.contactField));

  const response = await axiosInstance.request({
    url: "add_contact",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showContactList = async () => {
  const response = await axiosInstance.request({
    url: "all_contact",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const editShowContact = async (id) => {
  const response = await axiosInstance.request({
    url: `contact_id/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updateContactDetail = async (contactDetailData, id) => {
  const data = new FormData();
  data.append("designation", contactDetailData.category);
  data.append("location", contactDetailData.location);
  data.append("f_status", contactDetailData.f_status);
  data.append("s_status", contactDetailData.s_status);
  data.append("t_status", contactDetailData.t_status);

  data.append("address", contactDetailData.address);

  data.append("firstmobile", contactDetailData.firstMob);

  data.append("secondmobile", contactDetailData.secondMob);
  data.append("thirdmobile", contactDetailData.thirdMob);
  data.append("email", contactDetailData.email);
  data.append("order", contactDetailData.orderNumber);

  data.append("id", id);

  const response = await axiosInstance.request({
    url: "update_contact",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteContactDetail = async (id) => {
  const response = await axiosInstance.request({
    url: `delete_contact/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};