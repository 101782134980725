import React,{useState} from "react";
import styles from "./../job.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { addCategory, showCategory } from "../../../../api/user";
import swal from "sweetalert";
export default function AddCategory() {
  const [category, setCategory] = useState("");
  const navigate = useNavigate();

  const handleAddCategory = async () => {
    const addCategoryPayload = {
      category: category,
    };
    const response = await addCategory(addCategoryPayload);

    if (response.remote === "success") {
      swal("Job Category Added Successfully!");
      navigate("/manage-category");
      showCategory();
      setCategory("");
    } else {
    }
  };
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Job Category</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Category"
                    className={styles.form_input}
                    onChange={(e) => setCategory(e.target.value)}
                  />
                </Col>

                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleAddCategory}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
