import React, { useEffect, useState } from "react";
import styles from "./../experience.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  imageListClasses,
} from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import {
  addCelebrationBannerImage,
  addCelebrationData,
  AddExperienceDetails,
  AddRestaurantDetails,
  deleteImage,
  showExperienceList,
  showRestaurantName,
  showRetreatList,
  showRetreatListArray,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import { UploadIcon, crossRed } from "../../../../utils/svg.file";
import { addGallerySliderImage } from "../../../../api/adminPanel/restaurantApi";
import { showRestaurantList } from "../../../../api/adminPanel/exclusiveOfferApi";
import { Select } from "antd";

export default function AddExperience() {
  const navigate = useNavigate();
  const [titledata, setTitledata] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [bannerSingleImage, setBannerSingleImage] = useState("");
  const [imageBannerArray, setImageBannerArray] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [offersValue, setOffersValue] = useState("");
  const [detailDescription, setDetailDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [category, setCategory] = useState("1");
  const [offerType, setOfferType] = useState("restaurants");
  const [retreatList, setRetreatList] = useState([]);
  const [retraurantList, setRetraurantList] = useState([]);
  const [finalList, setFinalList] = useState([]);
  const [celebrationSelected, setCelebrationSelected] = React.useState([]);
  const [retreatName, setRetreatName] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const onChange = async ({ fileList }) => {
    // setFileListCard(fileListCard);
  

    setCardImage(fileList[0].originFileObj);
  };
  const onBannerSingleChange = async ({ fileList }) => {
    // setFileListCard(fileListCard);

    setBannerSingleImage(fileList[0].originFileObj);
  };
  const BannerOnChange = async ({ fileList }) => {
    setFileList(fileList);
    const addGalleryPayload = {
      galleryImage: fileList[0].originFileObj,
    };
    const response = await addGallerySliderImage(addGalleryPayload);

    if (response.remote === "success") {
      swal("Image Successfully Added");
      setImageBannerArray([...imageBannerArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };
  const handleRetraurantList = async () => {
    const response = await showRestaurantList();

    if (response.remote === "success") {
      setRetraurantList(response.data.data);
      setFinalList(response.data.data);
    } else {
    }
  };
  const handleRetreatList = async () => {
    const response = await showRetreatListArray();

    if (response.remote === "success") {
      setRetreatList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleRetraurantList();
    handleRetreatList();
  }, []);
  const handlechangeValue = (value) => {
    setOfferType(value);
    if (value === "retreats") {
      setFinalList(retreatList);
    } else {
      setFinalList(retraurantList);
    }
  };

  const handleExperienceDetails = async () => {
    if (!category) {
      return swal("Please Select Category");
    }
    const restaurantData = {
      cardImage: cardImage,
      banner: bannerSingleImage,
      slider: imageBannerArray,
      shortdescription: shortDescription,
      category: category,
      description: detailDescription,
      title: titledata,
      locationId: offersValue,
      locationtype: offerType,
      listData: listData,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    };
    const response = await AddExperienceDetails(restaurantData);

    if (response.remote === "success") {
      swal("Data Added Successfully");
      navigate("/manage-experience");
    } else {
    }
  };

  const handleExperienceList = async () => {
    const response = await showExperienceList();
    if (response.remote === "success") {
      setRetreatName(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleExperienceList();
  }, []);
  const handelDeleteImgae = async (image) => {
    const newImageArray = imageBannerArray.filter(
      (imageArray) => imageArray !== image
    );
    setImageBannerArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };

  const pagesList = [
    {
      value: "1",
      label: "Retreats",
    },
    {
      value: "2",
      label: "Restaurants",
    },
    {
      value: "4",
      label: "Cruises",
    },
    {
      value: "5",

      label: "Travel",
    },
    {
      value: "6",

      label: "Stories",
    },
  ];
  const [listData, setListData] = React.useState([]);

  // const handleChange2 = (vl, label) => {
  //   setPagesList(label);
  // };

  const [setPagesList] = useState([]);
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
  // const options = [];
  // for (let i = 10; i < 36; i++) {
  //   options.push({
  //     value: i.toString(36) + i,
  //     label: i.toString(36) + i,
  //   });
  // }
  const handleChange = (value,label) => {
    setListData(value)
  };
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Stories</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={3}>
                  {" "}
                  <label className="fw-bold">
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 818 X 814 px)
                    </small>
                  </label>
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={818 / 814}>
                      <Upload
                        maxCount={1}
                        onChange={onChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={8} className="multi-images">
                  <label className="fw-bold">
                    Banner Slider Images{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <div className="d-flex align-items-center">
                    <ImgCrop
                      grid
                      aspect={818 / 814}
                      styles={{ width: "120px" }}
                    >
                      <Upload
                        maxCount={1}
                        onChange={BannerOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                    <ul className={styles.multi_image_preview}>
                      {imageBannerArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => handelDeleteImgae(item)}
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>{" "}
                <Col md={6}>
                  <label className="fw-bold">Select Category</label>

                  <Form.Select
                    rows={2}
                    type="text"
                    className={styles.form_input}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    {" "}
                    <option selected>Select Category</option>
                    {retreatName?.map((item)=>(
                      <option value={item.title}>{item.title}</option>
                    ))}
                    {/* <option value="1">SAMADHIAN</option>
                    <option value="2">CULTURE</option>
                    <option value="3">CELEBRATION</option>
                    <option value="4">WELLNESS</option> */}
                  </Form.Select>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setTitledata(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Short Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setShortDescription(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Detailed Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Detailed Description"
                    className={styles.form_input}
                    onChange={(e) => setDetailDescription(e.target.value)}
                  />
                </Col>
                {/* <Col md={12}>
                  <label className="fw-bold ">Select Celebrations</label>
                  <span>
                    <Select
                      multiple
                      className={`p-0 ${styles.form_input}`}
                      value={celebrationSelected}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {celebrationsList.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={celebrationSelected.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </span>
                </Col> */}
                <Col md={6}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="restaurants"
                      control={<Radio />}
                      label="Restaurant"
                      onChange={(e) => handlechangeValue(e.target.value)}
                    />
                    <FormControlLabel
                      value="retreats"
                      control={<Radio />}
                      label="Retreats"
                      onChange={(e) => handlechangeValue(e.target.value)}
                    />
                  </RadioGroup>
                  <label className="fw-bold">
                    Select Restaurant / Retreats
                  </label>
                  <Form.Select
                    className={styles.form_input}
                    onChange={(e) => setOffersValue(e.target.value)}
                  >
                    <option selected>Select Restaurant / Retreats</option>

                    {finalList?.map((item, index) => {
                      return (
                        <>
                          <option value={item.value}>
                            {item.label}, {item.other}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col md={6}>
                  {" "}
                  <label className="fw-bold">
                    Big Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 788 X 398 px)
                    </small>
                  </label>
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={788 / 398}>
                      <Upload
                        maxCount={1}
                        onChange={onBannerSingleChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Select Pages</label>

                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Tags Mode"
                    onChange={handleChange}
                    options={pagesList}
                  />
                </Col>
                <label className="fw-bold">Enter Meta</label>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      className={styles.form_input}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      className={styles.form_input}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleExperienceDetails}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
