import React, { useEffect } from "react";
import styles from "../celebrationEvent.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { useState } from "react";
import { showEnquiryCelebrationList } from "../../../../api/adminPanel/celebrationAPI";
import { InsertEmoticon } from "@mui/icons-material";

export default function CelebrationEnquiryList() {
  const navigate = useNavigate();
  const [CelebrationList, setCelebrationList] = useState([]);
  const columns = [
    { field: "id", headerName: "SNo.", width: 70 },
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", width: 160 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phoneNumber", headerName: "Phone Number", width: 160 },
    // { field: "pax", headerName: "Pax", width: 150 },
    // { field: "indoor", headerName: "Indoor", width: 80 },
    // { field: "outdoor", headerName: "Outdoor", width: 80 },
    // { field: "reception", headerName: "Reception", width: 80 },
    { field: "eventType", headerName: "Type Of Event", width: 160 },
    // { field: "venue", headerName: "Venue", width: 150 },
    { field: "eventDate", headerName: "Date of Event", width: 250 },
    { field: "description", headerName: "Description", width: 250 },
    // {
    //   field: "Action",
    //   renderCell: (cellValues) => {
    //     return (
    //       <Button
    //         className={styles.small_button}

    //       >
    //         View
    //       </Button>
    //     );
    //   },
    // },
  ];

 
  const handleCelebrationList = async () => {
    const response = await showEnquiryCelebrationList();
    if (response.remote === "success") {
      setCelebrationList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleCelebrationList()
    
  }, [])
  let rows = [];

  CelebrationList?.map((item, index) => {
    rows.push({
      id: index+1,
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      phoneNumber: item.phone,
      pax: item.resName,
      eventType: item.eventType,
      venue:item.venue,
      eventDate: item.eventDate,
      description: item.description,
      indoor: item.indoor,
      outdoor: item.outdoor,
      reception: item.reception,
    });
  });
  return (
    <>
      <div className={styles.job_list_page}>
        <div className={styles.card_body}>
          <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
            <div className={styles.page_title}>
              <h3>Celebration Enquiry List</h3>
            </div>
          </div>
          <Table rows={rows} columns={columns} />
        </div>
      </div>
    </>
  );
}
