import axiosInstance from "./../api";
const token = localStorage.getItem("token");

export const AddEventBlock = async (eventBlockData) => {
  const data = new FormData();
  data.append("cardimage", eventBlockData.cardimage);
  data.append("first_image", eventBlockData.first_image);

  data.append("second_image", eventBlockData.second_image);

  data.append("location", eventBlockData.location);

  data.append("title", eventBlockData.title);

  data.append("image", JSON.stringify(eventBlockData.image));

  data.append("description", eventBlockData.description);

  data.append("first_title", eventBlockData.first_title);

  data.append("first_description", eventBlockData.first_description);

  data.append("second_title", eventBlockData.second_title);

  data.append("second_description", eventBlockData.second_description);
  data.append("offerType", eventBlockData.offerType);
  data.append("locationId", eventBlockData.locationId);
  data.append("meta_title", eventBlockData.metaTitle);
  data.append("meta_description", eventBlockData.metaDescription);
  const response = await axiosInstance.request({
    url: "addcelebrationblocks",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const UpdateEventBlockData = async (addEventBlock) => {
  const data = new FormData();

  data.append("id", addEventBlock.id);
  data.append("cardimage", addEventBlock.cardimage);
  data.append("first_image", addEventBlock.first_image);

  data.append("second_image", addEventBlock.second_image);

  data.append("location", addEventBlock.location);

  data.append("title", addEventBlock.title);

  data.append("image", JSON.stringify(addEventBlock.image));

  data.append("description", addEventBlock.description);

  data.append("first_title", addEventBlock.first_title);

  data.append("first_description", addEventBlock.first_description);

  data.append("second_title", addEventBlock.second_title);

  data.append("second_description", addEventBlock.second_description);
  data.append("offerType", addEventBlock.offerType);
  data.append("locationId", addEventBlock.locationId);
  data.append("meta_title", addEventBlock.metaTitle);
  data.append("meta_description", addEventBlock.metaDescription);
  const response = await axiosInstance.request({
    url: "updatecelebrationblocks",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showEnquiryCelebrationList = async () => {
  const response = await axiosInstance.request({
    url: "applyCelebrationlist",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showCelebrationList = async () => {
  const response = await axiosInstance.request({
    url: "showcelebrationblocks",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showFindCelebrationList = async (id) => {
  const response = await axiosInstance.request({
    url: `showcelebrationblocksid/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteCelebrationList = async (id) => {
  const response = await axiosInstance.request({
    url: `deletecelebrationblocks/${id}`,
    method: "DELETE",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showFeaturedStoryList = async () => {
  const response = await axiosInstance.request({
    url: "showfeaturestory",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const deleteFeaturedStory = async (id) => {
  const response = await axiosInstance.request({
    url: `deletefeaturestory/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const editFeaturedStoryApi = async (id) => {
  const response = await axiosInstance.request({
    url: `showfeaturestoryid/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updateFeaturedStory = async (featuredDetailData, id) => {
  const data = new FormData();
  data.append("heading", featuredDetailData.heading);
  data.append("discription", featuredDetailData.discription);
  data.append("image", featuredDetailData.image);

  data.append("id", id);

  const response = await axiosInstance.request({
    url: "updatefeatureStory",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};



export const AddCelebrationType = async (eventBlockData) => {
  const data = new FormData();
  data.append("image", eventBlockData.cardimage);
  data.append("celebration", eventBlockData.location);
  data.append("title", eventBlockData.title);
  data.append("discription", eventBlockData.description);

  const response = await axiosInstance.request({
    url: "add_celebration_type",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const UpdateCelebrationType = async (eventBlockData) => {
  const data = new FormData();
  data.append("id", eventBlockData.id);
  data.append("image", eventBlockData.cardimage);
  data.append("celebartion", eventBlockData.location);
  data.append("title", eventBlockData.title);
  data.append("discription", eventBlockData.description);

  const response = await axiosInstance.request({
    url: "update_celebration_type",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showCelebrationType = async () => {
  const response = await axiosInstance.request({
    url: "list_celebration_type",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deleteCelebrationType = async (id) => {
  const response = await axiosInstance.request({
    url: `delete_celebration_type/${id}`,
    method: "DELETE",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const AddCelebrationText = async (eventBlockData) => {
  const data = new FormData();
  data.append("text", eventBlockData.text);


  const response = await axiosInstance.request({
    url: "add_celebration_description",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showCelebrationText = async () => {
  const response = await axiosInstance.request({
    url: "list_celebration_description",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};