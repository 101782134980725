import React, { useEffect, useState } from "react";
import styles from "./../job.module.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from "../../../component/table";
import { deleteIcon, editIcon, viewIcon } from "../../../../utils/svg.file";
import { showCategory } from "../../../../api/user";
import {
  deleteCategory,
  editCategory,
} from "../../../../api/adminPanel/jobapi";
import swal from "sweetalert";
import DeleteModal from "../../../component/modal/deleteModal";

export default function CategoryList() {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteId, setDeletedId] = useState("");
  const [showValue, setShowValue] = useState("");
  const [searchedList, setSearchedList] = useState([]);
  const [searchedData, setSearchedData] = useState('')
  const handleShowDeleteModal = (id) => {
    setDeletedId(id);
    setShowDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const columns = [
    { field: "id", headerName: "SNo.", width: 100 },
    { field: "CategoryName", headerName: "Category", width: 700 },
    {
      field: "Action",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            {/* <span className={styles.view_icon_btn}>{viewIcon}</span> */}
            <span
              className={styles.edit_icon_btn}
              onClick={() => {
                // handleEditCategory(cellValues.row.id);
                navigate(`/edit-job-category/${cellValues.row.id}`);
              }}
            >
              {editIcon}
            </span>
            <span
              className={styles.delete_icon_btn}
              onClick={() => {
                handleShowDeleteModal(cellValues.row.id);
              }}
            >
              {deleteIcon}
            </span>
          </>
        );
      },
    },
  ];
  const handleSearch = (data) => {
    if (data) {
      let searchData = categoryList.filter((entry) =>
        entry.name?.includes(data)
      );
      setSearchedList(searchData);
    }
  };

  const filteredList = () => {
    if(searchedData) {
      return searchedList
    } else {
      return categoryList
    }
  }
  let rows = [];

  filteredList().map((item, index) => {
    rows.push({
      id: item.id,
      CategoryName: item.name,
    });
  });

  const handleShowCategory = async () => {
    const response = await showCategory();

    if (response.remote === "success") {
      setCategoryList(response.data.data);
    } else {
    }
  };

  const handleDelete = async (id) => {
    const response = await deleteCategory(id);

    if (response.remote === "success") {
      hideDeleteModal();
      swal("Deleted Successfully!");
    } else {
    }
  };
  useEffect(() => {
    handleShowCategory();
  }, []);

  
  return (
    <>
      <div className={styles.job_list_page}>
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Manage Category List</h3>
              </div>
              <Button
                className={styles.main_button}
                onClick={() => navigate("/add-job-category")}
              >
                Add Category
              </Button>
            </div>{" "}
            <div className="table-search d-flex justify-content-between">
              <div className="show_entries d-flex align-items-center">
                Show
                <select className="form-control ms-1 me-1" onChange={(e)=>setShowValue(e.target.value)}>
                  <option value="50"> 50</option>
                  <option value="10"> 10</option>
                  <option value="25"> 25</option>


                  <option value="100"> 100</option>
                </select>
                Entries
              </div>
              <input
                type="search"
                placeholder="Search.."
                className="form-control mb-3 mt-1"
                style={{ width: "250px" }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchedData(e.target.value);
                }}
              />
            </div>
            <Table rows={rows} columns={columns} showValue={showValue}/>
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDelete}
        hideDeleteModal={hideDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        deleteId={deleteId}
      />
    </>
  );
}
