import React, { useEffect, useState } from "react";
import styles from "./../crms.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  editPrivacyNotice,
  updatePrivacyNotice,
} from "../../../../api/adminPanel/privacyNoticeApi";
import swal from "sweetalert";

export default function EditPrivacyNotice() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [description, setDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const handleGetPrivacyNoticeDetail = async (id) => {
    const response = await editPrivacyNotice(id);
    if (response.remote === "success") {
      setDescription(response.data.data.text);
      setMetaDescription(response.data.data.meta_description);
      setMetaTitle(response.data.data.meta_title);

    } else {
    }
  };

  const handleUpdatePrivacyNotice = async (id) => {
    const updatePrivacyPayload = {
      discription: description,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
    };
    const response = await updatePrivacyNotice(updatePrivacyPayload, id);

    if (response.remote === "success") {
      swal("Privacy Notice Successfully Update!");
      navigate("/manage-privacy-notice");
    } else {
    }
  };

  useEffect(() => {
    handleGetPrivacyNoticeDetail(id);
  }, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Privacy Notice</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  {" "}
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                </Col>
                <label className="fw-bold">Enter Meta</label>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      className={styles.form_input}
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      className={styles.form_input}
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => handleUpdatePrivacyNotice(id)}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
