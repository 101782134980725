import React, { useEffect, useState } from "react";
import styles from "./featuredstory.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UploadIcon, deleteIcon, editIcon } from "../../../utils/svg.file";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL } from "../../../serverUrl";
import CropperModal from "../../component/modal/cropperModal";
import { addFeaturedStory } from "../../../api/adminPanel/featuredStory";
import swal from "sweetalert";
export default function AddFeaturedStory() {
  const [file, setFile] = useState("");
  const [imgHome, setImgBanner] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const [fileList, setFileList] = useState([
    // {
    //   uid: "-1",
    //   url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    // },
  ]);

  const onChange = ({ fileList }) => {
    setFileList(fileList);
    setImgBanner(fileList[0].originFileObj);
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const handleFeatureStory = async () => {
    const addFeaturedStoryPayload = {
      discription: description,
      heading: title,
      image: imgHome,
    };
    const response = await addFeaturedStory(addFeaturedStoryPayload);

    if (response.remote === "success") {
      swal("Featured Story Successfully Added");
      navigate("/manage-featured-story");
      setDescription("");
      setTitle("");
      setImgBanner("");
    } else {
    }
  };

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Featured Story</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Col>

                <Col md={6}>
                  <textarea
                    rows="5"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  {/* <input
                    type="file"
                    placeholder="image"
                    className={`${styles.form_input} p-1`}
                    // value={file}
                    onChange={fileupload}
                  />
                  {file ? (
                    <div className="preview">
                      <img
                        src={fileList.url}
                        alt=""
                        className={styles.preview_img}
                      />
                    </div>
                  ) : (
                    ""
                  )} */}
                  <ImgCrop grid aspect={882 / 445}>
                    <Upload
                      action={Base_URL}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                    >
                      {fileList.length < 1 && (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => handleFeatureStory()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      {/* <CropperModal
        file={file}
        showCropModal={showCropModal}
        hideCropModal={hideCropModal}
        handleShowCropModal={handleShowCropModal}
      /> */}
    </>
  );
}
