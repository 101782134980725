import React, { useEffect, useState } from "react";
import styles from "./job.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { addJobDetail, showCategory } from "../../../api/user";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { editJobDetail, updateJobDetail } from "../../../api/adminPanel/jobapi";
import swal from "sweetalert";

export default function ViewJob() {
  const [categoryList, setCategoryList] = useState([]);
  const [designation, setDesignation] = useState("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [state, setState] = useState("1");
  const [description, setDescription] = useState("");
  const stateList = [
    { id: 1, name: "Malaysia" },
    { id: 2, name: "Singapore" },
  ];
  let { id } = useParams();
  const navigate = useNavigate();
  const handleGetJobDetail = async (id) => {
    const response = await editJobDetail(id);
    if (response.remote === "success") {
      setCategory(response.data.data.category);
      setDescription(response.data.data.discription);
      setDesignation(response.data.data.designation);
      setLocation(response.data.data.location);
      //   setState(response.data.data.state);
      if (state === "2") {
        setState("Singapore");
      } else {
        setState("Malaysia");
      }
    } else {
    }
  };

  useEffect(() => {
    handleGetJobDetail(id);
  }, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>View Job Detail</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Designation</label>

                  <p>{designation}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Category</label>

                  <p>{category}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Location</label>

                  <p>{location}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">State</label>

                  <p>{state}</p>
                </Col>
                <Col md={12}>
                  {" "}
                  <label className="fw-bold">Description</label>
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
