import React, { useEffect, useState } from "react";
import styles from "./job.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { addJobDetail, showCategory } from "../../../api/user";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { editJobDetail, updateJobDetail } from "../../../api/adminPanel/jobapi";
import swal from "sweetalert";

export default function EditJob() {
  const [categoryList, setCategoryList] = useState([]);
  const [designation, setDesignation] = useState("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [state, setState] = useState("1");
  const [description, setDescription] = useState("");
  const stateList = [
    { id: 1, name: "Malaysia" },
    { id: 2, name: "Singapore" },
  ];
  let { id } = useParams();
  const navigate = useNavigate();
  const handleGetJobDetail = async (id) => {
    const response = await editJobDetail(id);

    if (response.remote === "success") {
      setCategory(response.data.data.category);
      setDescription(response.data.data.discription);
      setDesignation(response.data.data.designation);
      setLocation(response.data.data.location);
      setState(response.data.data.state);
      //   if (state === "2") {
      //     setState("Singapore");
      //   } else {
      //     setState("Malaysia");
      //   }
    } else {
    }
  };

  const handleShowCategory = async () => {
    const response = await showCategory();

    if (response.remote === "success") {
      setCategoryList(response.data.data);
    } else {
    }
  };

  const handleUpdateJobDetail = async (id) => {
    const updateJobDetailPayload = {
      location: location,
      description: description,
      designation: designation,
      state: state,
      category: category,
    };
    const response = await updateJobDetail(updateJobDetailPayload, id);

    if (response.remote === "success") {
      swal("Job Successfully Update!");
      navigate("/manage-job");
    } else {
    }
  };

  useEffect(() => {
    handleGetJobDetail(id);
    handleShowCategory();
  }, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Job</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Designation</label>

                  <Form.Control
                    type="text"
                    placeholder="Designation"
                    className={styles.form_input}
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Category</label>

                  <Form.Group className="">
                    <Form.Select
                      className={styles.form_input}
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      {categoryList.map((item, index) => {
                        return (
                          <>
                            {" "}
                            <option
                              //   selected={category === item.name ? true : false}
                              key={index}
                              value={item.name}
                            >
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Location</label>

                  <Form.Control
                    type="text"
                    placeholder="Location"
                    className={styles.form_input}
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Country</label>

                  <Form.Select
                    value={state}
                    className={styles.form_input}
                    onChange={(e) => setState(e.target.value)}
                  >
                    {stateList.map((item, index) => {
                      return (
                        <>
                          {" "}
                          <option
                            selected={state === item.id ? true : false}
                            key={index}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Description</label>

                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => handleUpdateJobDetail(id)}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
