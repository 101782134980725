import React, { useEffect, useState } from "react";
import styles from "../ticket.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import Table from "../../../component/table";
import { editMangegePaymentData } from "../../../../api/adminPanel/exclusiveOfferApi";

export default function OrderDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [paymentFullData, setPaymentFullData] = useState([]);
  const [quantityData, setQuantityData] = useState([]);

  const handlefindPaymentTicket = async () => {
    const response = await editMangegePaymentData(id);
  
    if (response.remote === "success") {
      setPaymentFullData(response.data.data[0])
      setQuantityData(JSON.parse(response.data.data[0].quatity))
    } else {
    }
  };
  let rows = [];
  
  quantityData?.map((item, index) => {
     rows.push({
      id: index+1,
      ticket: item.checked.name,
      quantity: item.quanitty.quantity,

      unit: item.checked.amount,

      total: item.quanitty.quantity*item.checked.amount,
      });
  });
  useEffect(() => {
    handlefindPaymentTicket()
  }, [id])
  
  return (
    <>
      <div className="order-detail-page">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Payment History Detail</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row>
                <Col md={6}>
                  <label className="fw-bold"> Name</label>
                  <p>{paymentFullData.name}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold"> Date</label>
                  <p>{paymentFullData.date}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Email</label>
                  <p>{paymentFullData.email}</p>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Mobile</label>
                  <p>{paymentFullData.mobile}</p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <table className="w-100 table table-bordered mt-3">
                    <tr>
                      <th>Id</th>
                      <th>Ticket</th>
                      <th>Quantity</th>
                      <th>Unit/Price</th>
                      <th>Total</th>
                    </tr>
                    <tbody>
                      {rows.map((row) => (
                        <tr key={row.id}>
                          <td>{row.id}</td>
                          <td>{row.ticket}</td>
                          <td>{row.quantity}</td>
                          <td>{row.unit}</td>
                          <td>{row.total}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={3} className="text-end"></td>
                        <td className="text-end">
                          {" "}
                          <span className="fw-bold">Total Amount</span> :
                        </td>
                        <td className="">
                          {" "}
                          <span className="fw-bold">{paymentFullData.amount/100}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
